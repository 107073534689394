import cx from "classnames"
import { cloneDeep, sortBy } from "lodash"
import { useEffect, useState } from "react"
import FormField from "src/components/FormField"
import Select, { formatChoices } from "src/components/FormField/Select"
import Loading from "src/components/Loading"
import NoData from "src/components/NoData"
import { getTreeitemRefId } from "src/helpers/tree"
import useTranslation from "src/hooks/useTranslation"
import TreeRowSelectable from "src/macrocomponents/AssignMass/Assign/TreeRowSelectable"
import TreeSelectable from "src/macrocomponents/AssignMass/Assign/TreeSelectable"
import { adminAuditSprint } from "src/services/auditSprint.service"
import { getTreeData as getTreeAuditoriaData } from "src/services/tree.service"
import { getTreeData as getTreeEvaluacionData } from "src/services/treeEvaluation.service"
import styles from "./ProjectTree.module.scss"

export default function ProjectTree({
  projectId,
  divisionId = null,
  projectType,
  projectFilters = {},
  defaultValue = null,
  onSelect = () => {},
  filterRow = null,
  processRow = null,
  multiple = false,
  hiddenBarActions = false,
  maxHeight = null,
  autoSelectChildren = false,
  emptyMessage = (
    <>
      <i className='icon exclamation triangle big ' />
      Seleccione un proyecto primero.
    </>
  ),
  emptyRows = null
}) {
  const [treeData, setTreeData] = useState(null)
  const [selectedIds, setSelectedIds] = useState(defaultValue)

  const { t } = useTranslation()

  const apiFilters = projectType == "auditoria" ? JSON.stringify(projectFilters) : divisionId
  useEffect(() => {
    if (!projectId) {
      setTreeData([])
      return
    }
    const getTreeValues = async () => {
      const getTreeData = projectType == "auditoria" ? getTreeAuditoriaData : getTreeEvaluacionData
      setTreeData(null)
      const {
        data: { tree }
      } = await getTreeData(projectId, projectType == "auditoria" ? projectFilters : divisionId)

      setTreeData((_) => {
        let _treeData = cloneDeep(tree)
        _treeData.forEach((el) => {
          el.position = el.position === null ? 0 : el.position
        })
        _treeData = sortBy(_treeData, "position")

        const isChecked = (element) => {
          if (defaultValue === null) {
            return false
          }
          if (Array.isArray(defaultValue)) {
            return defaultValue.includes(element.refId)
          }
          return element.refId == defaultValue
        }

        const traverse = (_data) => {
          return _data.forEach((_element) => {
            _element.open = true
            _element.refId = getTreeitemRefId(_element)
            _element.checked = isChecked(_element)
            _element.position = _element.position === null ? 0 : _element.position
            _element.items = sortBy(_element.items, "position")
            _element?.items?.length > 0 && traverse(_element.items)
          })
        }
        traverse(_treeData)

        const cleanData = (rows) => {
          const retval = []
          rows.forEach((_row) => {
            let clean = processRow ? processRow({ ..._row }) : { ..._row }
            clean = filterRow ? filterRow(clean) : clean
            if (clean) {
              clean.items = cleanData(clean.items)
              retval.push(clean)
            }
          })

          return retval
        }

        return cleanData(_treeData)
      })
    }
    getTreeValues()
  }, [projectId, apiFilters])

  useEffect(() => {
    onSelect(selectedIds)
  }, [selectedIds])

  const onArrowClick = (_row) => {
    _row.open = !_row.open

    setTreeData([...treeData])
  }

  const onCheck = (_row) => {
    _row.checked = !_row.checked
    setTreeData([...treeData])

    const updateChildren = (children, checked, list) => {
      children?.forEach((child) => {
        if (checked) {
          if (child.checkable ?? true) {
            child.checked = true
            list.add(child.refId)
          }
        } else {
          list.delete(child.refId)
          child.checked = false
        }

        updateChildren(child.items, checked, list)
      })
    }

    if (_row.checked) {
      if (multiple) {
        let ids
        setSelectedIds((prev) => {
          const newData = new Set(prev === null ? [] : prev)
          newData.add(_row.refId)

          if (autoSelectChildren) {
            updateChildren(_row.items, true, newData)
          }

          ids = Array.from(newData)

          return ids
        })
      } else {
        setSelectedIds(_row.refId)
      }
    } else {
      if (multiple) {
        let ids
        setSelectedIds((prev) => {
          const newData = new Set(prev === null ? [] : prev)
          newData.delete(_row.refId)

          if (autoSelectChildren) {
            updateChildren(_row.items, false, newData)
          }

          ids = Array.from(newData)

          return ids.length === 0 ? null : ids
        })
      } else {
        setSelectedIds(null)
      }
    }
  }

  const onSelectAll = (check) => () => {
    const _treeData = cloneDeep(treeData)
    const ids = []
    const traverse = (_data) => {
      return _data.forEach((_element) => {
        if (!(_element.checkable ?? true)) {
          _element?.items?.length > 0 && traverse(_element.items)
          return
        }
        _element.checked = check
        _element?.items?.length > 0 && traverse(_element.items)
        if (check) {
          ids.push(_element.refId)
        }
      })
    }
    traverse(_treeData)

    setTreeData(_treeData)
    setSelectedIds(ids.length === 0 ? null : ids)
  }

  const renderBarActions = () => {
    if (!multiple) {
      return null
    }

    return (
      <div className={cx("ui horizontal list", styles.barActions)}>
        <div className='item'>
          <a onClick={onSelectAll(true)}>{t("main_ui.general.btn_select_all")}</a>
        </div>
        <span className='item'>|</span>
        <div className='item'>
          <a onClick={onSelectAll(false)}>{t("main_ui.general.btn_select_none")}</a>
        </div>
      </div>
    )
  }

  const renderTreeSelectableRow = (row) => {
    return (
      <TreeRowSelectable
        data={row}
        onArrowClick={onArrowClick.bind(this, row)}
        onCheck={onCheck.bind(this, row)}
        open={row.open}
        checked={row.checked}
        checkable={row.checkable ?? true}
        disabledText={row.disabledText || false}
        multiple={multiple}
        rightComponent={false}
      />
    )
  }

  const renderContent = () => {
    const Content = TreeSelectable({
      data: treeData,
      arrayKey: "items",
      openKey: "open",
      emptyText: emptyRows,
      renderRow: (_row, _indexes) => {
        return {
          component: renderTreeSelectableRow(_row),
          processedRow: processRow ? processRow({ ..._row }) : { ..._row }
        }
      }
    })

    return Content === null ? (
      emptyRows ? (
        <div
          className='ui icon orange message'
          style={{ width: "60%", margin: "20px auto", padding: "20px 40px ", justifyContent: "center" }}
        >
          <i className='icon exclamation triangle' style={{ marginRight: 20 }} /> {emptyRows}
        </div>
      ) : null
    ) : (
      <>
        {hiddenBarActions ? <></> : renderBarActions()}
        <div style={{ maxHeight, overflowY: "auto", width: "100%" }}>{Content}</div>
        {hiddenBarActions ? <></> : renderBarActions()}
        <div className='clearfix' />
      </>
    )
  }

  if (!projectId) {
    return (
      <div className='ui basic segment dimmable' style={{ height: "90%" }}>
        <div className='ui active inverted dimmer' style={{ color: "var(--accent-red)" }}>
          {emptyMessage}
        </div>
        <div className='ui placeholder'>
          {[...Array(10).keys()].map((x) => (
            <div key={x} className='line' />
          ))}
        </div>
      </div>
    )
  }

  return treeData === null ? (
    <Loading curtain={false} centered={false} text='Cargando proyecto' />
  ) : treeData.length == 0 ? (
    <NoData text='Proyecto vacío' />
  ) : (
    renderContent()
  )
}

export function ProjectTreeWithSprint(props) {
  const [sprints, setSprints] = useState(props.projectType === "auditoria" ? null : [])
  const [sprintId, setSprintId] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (props.projectType !== "auditoria") {
      return
    }
    if (!props.projectId) {
      return
    }
    adminAuditSprint(props.projectId)
      .GET_COLL_ACTION("basiclist")
      .then(({ data }) => {
        setSprints(data)
      })
  }, [props.projectId])

  const handleChangeSprint = ({ id }) => {
    setSprintId(id)
  }

  if (sprints === null) {
    return (
      <div className='ui segment basic'>
        <div className='ui loader active'></div>
      </div>
    )
  }

  if (sprints.length == 0) {
    return <ProjectTree {...props} />
  }

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <FormField
          label={t("frontend.auditoria.sprint.__name__")}
          positionLabel='left'
          style={{ justifyContent: "center" }}
        >
          <Select
            selectionChange={handleChangeSprint}
            initialOptions={formatChoices(
              sprints.map((x) => {
                return { id: x.id, label: x.nombre }
              }),
              sprintId
            )}
          />
        </FormField>
      </div>

      {sprintId ? (
        <ProjectTree {...props} projectFilters={{ ...(props.projectFilters ?? {}), sprint: sprintId }} />
      ) : (
        <div style={{ minHeight: 50 }} />
      )}
    </div>
  )
}
