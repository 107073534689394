import React from 'react'
import styles from './style.module.scss'

export default function DropdownTitle(props) {
  const {
    value = null,
    ...otherProps
  } = props

  return (
    <div className={styles.dropdownTitle} {...otherProps}>
      {value}
    </div>
  )
}
