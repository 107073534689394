import { getFormattedSignatureData, signatureFormatter } from "../../helpers/signatureFormatter"
import Signature from "../Signature"

export default function SignatureSimplified({
  signature,
  size = "mini",
  labelSize = "mini",
  propsSigningButtons,
  isFormatted = true,
  typeTree = null
}) {
  if (typeTree === "auditTemplate") {
    return null
  }

  if (isFormatted) {
    const completes = signatureFormatter(signature?.completers)
    const reviews = signatureFormatter(signature?.reviewers)

    return (
      <Signature
        propsSigningButtons={propsSigningButtons}
        size={size}
        labelSize={labelSize}
        isCompleted={signature?.completed}
        isReviewed={signature?.reviewed}
        completes={completes}
        reviews={reviews}
      />
    )
  } else {
    const { completes, reviews, isCompleted, isReviewed } = getFormattedSignatureData(signature)
    return (
      <Signature
        propsSigningButtons={propsSigningButtons}
        size={size}
        labelSize={labelSize}
        isCompleted={isCompleted}
        isReviewed={isReviewed}
        completes={completes}
        reviews={reviews}
      />
    )
  }
}
