import cx from "classnames"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import Icon from "src/components/Icon"
import Tooltip from "src/components/Tooltip"
import ErrorBoundary from "src/errors/ErrorBoundary"
import { renderFormBody } from "src/helpers/form_builder"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export const FormModalObjectAction = ({
  object,
  name = "Editar",
  handleClose,
  icon = "pencil",
  iconColor = null,
  modalConfig: { title, fields, onSave, onSuccess, footer = true }
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    handleClose?.()
    setModalOpen(false)
  }

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setModalOpen(true)}>
        <Tooltip content={name}>
          <Icon name={icon} color={iconColor} />
        </Tooltip>
      </div>
      {isModalOpen && (
        <FormModal
          title={title}
          fields={fields}
          initialValues={object}
          onSave={onSave}
          onSuccess={onSuccess}
          onClose={closeModal}
          footer={footer}
        />
      )}
    </>
  )
}

export const FormModal = ({
  title,
  fields,
  initialValues = {},
  onSuccess,
  onSave,
  onClose,
  beforeForm = null,
  afterForm = null,
  footer = true,
  display = "modal",
  className = null,
  formOptions = {},
  readOnly = false,
  style = {}
}) => {
  const [loading, setLoading] = useState(false)
  const { register, errors, setValue, getValues, watch } = useForm({ mode: "onBlur", defaultValues: initialValues })
  const { t } = useTranslation()

  const values = watch()

  const registerFields = (fields) => {
    if (!Array.isArray(fields)) {
      return
    }

    fields.forEach((row) => {
      if (!Array.isArray(row)) {
        row = [row]
      }

      row.forEach((field) => {
        if (React.isValidElement(field)) {
          return
        }

        if (typeof field.fields !== "undefined") {
          registerFields(field.fields)
        } else {
          register(field.name, field.validations || { required: field.required ?? formOptions?.required ?? true })
        }
      })
    })
  }

  useEffect(() => {
    registerFields(fields)
  }, [register])

  const isFieldRequired = (field) => {
    switch (typeof field.required) {
      case "undefined":
        return formOptions?.required ?? true
      case "function": {
        return field.required(values)
      }

      default:
        return field.required
    }
  }

  const getFirstInvalidField = () => {
    let error = null

    const isEmpty = function (v) {
      if (typeof v === "undefined" || v === null) {
        return true
      }
      return `${v}`.trim().length === 0
    }

    const traverseFields = (fields) => {
      if (!Array.isArray(fields)) {
        return null
      }

      fields.some((row) => {
        if (error) {
          return true
        }

        if (!Array.isArray(row)) {
          row = [row]
        }

        return row.some((field) => {
          if (typeof field.fields !== "undefined") {
            traverseFields(field.fields)

            if (error) {
              return true
            }
          }

          if (isFieldRequired(field) && isEmpty(values[field.name])) {
            error = t("main_ui.general.error_required_field", { field: t(field.label) })
            return true
          }
        })
      })
    }

    return error
  }

  if (display === "modal") {
    return (
      <Dialog isOpen isLoading={loading}>
        <Dialog.Header background='#0a858d' color='#e6e6e6' align='center' buttonClose handleClose={onClose}>
          <h4>{title}</h4>
        </Dialog.Header>
        <Dialog.Body theme={"light"}>
          {beforeForm}
          <div style={style} className={cx(styles.form, "ui form", className)}>
            {renderFormBody({ fields, initialValues, values, setValue, errors, t, styles, options: formOptions })}
          </div>
          {afterForm}
        </Dialog.Body>
        {footer === true
          ? !readOnly && (
              <Dialog.Footer theme={"light"}>
                <Button text='btn_cancel' name='warning' type='big' handleClick={onClose} />
                <Button
                  loading={loading}
                  text='btn_save'
                  name='normal'
                  type='big'
                  disabledText={getFirstInvalidField()}
                  handleClick={async (event) => {
                    event.preventDefault()

                    try {
                      setLoading(true)

                      const values = getValues()

                      const r = await onSave(values)

                      onSuccess?.(values, r)

                      if (typeof onClose === "function") {
                        onClose()
                      }
                    } catch (error) {
                      throw new Error(error)
                    } finally {
                      setLoading(false)
                    }
                  }}
                />
              </Dialog.Footer>
            )
          : footer}
      </Dialog>
    )
  } else {
    return (
      <div className={styles.formModal}>
        {title && <h4>{title}</h4>}

        <div style={style} className={cx(styles.form, "ui form", className)}>
          <ErrorBoundary>
            {renderFormBody({
              fields,
              initialValues,
              values,
              setValue,
              errors,
              t,
              styles,
              options: formOptions,
              readOnly
            })}
          </ErrorBoundary>
        </div>

        {footer === true
          ? !readOnly && (
              <div className={styles.footer}>
                {(formOptions?.buttonCancel ?? true) && (
                  <Button text='btn_cancel' name='warning' type='big' handleClick={onClose} />
                )}
                <Button
                  loading={loading}
                  text='btn_save'
                  name='normal'
                  type='big'
                  disabledText={getFirstInvalidField()}
                  handleClick={async (event) => {
                    event.preventDefault()

                    try {
                      setLoading(true)

                      const values = getValues()

                      const r = await onSave(values)

                      onSuccess?.(values, r)

                      if (typeof onClose === "function") {
                        onClose()
                      }
                    } catch (error) {
                      throw new Error(error)
                    } finally {
                      setLoading(false)
                    }
                  }}
                />
              </div>
            )
          : footer}
      </div>
    )
  }
}
