export const Permissions = Object.freeze({
  LISTAR_AUDITORIAS: 'LISTAR_AUDITORIAS',
  ACCESO_DASHBOARD: 'ACCESO_DASHBOARD',
  ACCESO_EVALUACIONES: 'ACCESO_EVALUACIONES',
  ACCESO_MATRIZ_PRIORIZACION: 'ACCESO_MATRIZ_PRIORIZACION',
  ACCESO_AUDITORIAS: 'ACCESO_AUDITORIAS',
  ACCESO_PLANTILLAS: 'ACCESO_PLANTILLAS',
  TAREAS_EVENTOS: 'TAREAS_EVENTOS',
  ACCESO_INFORME_TIEMPO: 'ACCESO_INFORME_TIEMPO',
  ACCESO_ASIGNACION_PERSONAL: 'ACCESO_ASIGNACION_PERSONAL',
  ACCESO_CONFIGURACIONES: 'ACCESO_CONFIGURACIONES',

  CREAR_AUDITORIA_ESPECIAL: 'CREAR_AUDITORIA_ESPECIAL',
  EDITAR_AUDITORIAS: 'EDITAR_AUDITORIAS',
  ARRASTRAR_AUDITORIAS_GANTT: 'ARRASTRAR_AUDITORIAS_GANTT',
  MOVER_ENTRADA_ARBOL: 'MOVER_ENTRADA_ARBOL',
  AGREGAR_HIJO_ENTRADA_ARBOL: 'AGREGAR_HIJO_ENTRADA_ARBOL',
  ELIMINAR_ENTRADA_ARBOL: 'ELIMINAR_ENTRADA_ARBOL',
  RENOMBRAR_ENTRADA_ARBOL: 'RENOMBRAR_ENTRADA_ARBOL',

  CREAR_EVALUACION: 'CREAR_EVALUACION',
  CREAR_EMPRESA: 'CREAR_EMPRESA',
  EDITAR_EVALUACIONES: 'EDITAR_EVALUACIONES',

  Tree: {
    auditPlan: {
      EDITAR_ENTENDIMIENTO: 'EDITAR_ENTENDIMIENTO',
      EDITAR_RIESGO: 'EDITAR_RIESGO',
      EDITAR_CONTROL: 'EDITAR_CONTROL',
      EDITAR_TEST: 'EDITAR_TEST',
      EDITAR_HALLAZGO: 'EDITAR_HALLAZGO',
      EDITAR_HALLAZGOS: 'EDITAR_HALLAZGOS',
      EDITAR_NOTAS: 'EDITAR_NOTAS',
      EDITAR_PASO: 'EDITAR_PASO',
      EDITAR_REQUERIMIENTO: 'EDITAR_REQUERIMIENTO'
    },

    riskEvaluation: {
      EDITAR_ENTENDIMIENTO: 'EDITAR_ENTENDIMIENTO',
      EDITAR_RIESGO: 'EDITAR_RIESGO',
      EDITAR_CONTROL: 'EDITAR_CONTROL',
      EDITAR_TEST: 'EDITAR_TEST',
      EDITAR_HALLAZGO: 'EDITAR_HALLAZGO',
      EDITAR_HALLAZGOS: 'EDITAR_HALLAZGOS',
      EDITAR_NOTAS: 'EDITAR_NOTAS',
      EDITAR_PASO: 'EDITAR_PASO',
      EDITAR_REQUERIMIENTO: 'EDITAR_REQUERIMIENTO'
    }
  },

  Configurations: {
    EDITAR_PROBABILIDADES: 'EDITAR_PROBABILIDADES',
    EDITAR_IMPACTOS: 'EDITAR_IMPACTOS',
    EDITAR_RIESGOS_INHERENTES: 'EDITAR_RIESGOS_INHERENTES',
    EDITAR_RIESGOS_GENERALES: 'EDITAR_RIESGOS_GENERALES',
    EDITAR_RIESGOS_ESPECIFICOS: 'EDITAR_RIESGOS_ESPECIFICOS',
    EDITAR_FACTORES_RIESGO: 'EDITAR_FACTORES_RIESGO',
    EDITAR_OBJETIVOS_ORGANIZACIONALES: 'EDITAR_OBJETIVOS_ORGANIZACIONALES',
    EDITAR_OBJETIVOS_CONTROLES: 'EDITAR_OBJETIVOS_CONTROLES',
    EDITAR_ACTIVIDADES_IMPRODUCTIVAS: 'EDITAR_ACTIVIDADES_IMPRODUCTIVAS',
    EDITAR_CRITERIOS_PRIORIZACION: 'EDITAR_CRITERIOS_PRIORIZACION',
    EDITAR_CRITERIOS_EVALUACION: 'EDITAR_CRITERIOS_EVALUACION',
    EDITAR_VALIDACIONES_CONTROL_MANUAL: 'EDITAR_VALIDACIONES_CONTROL_MANUAL',
    EDITAR_EVALUACIONES_DISENO: 'EDITAR_EVALUACIONES_DISENO',
    EDITAR_TIPO_PROCESOS: 'EDITAR_TIPO_PROCESOS',
    EDITAR_TIPO_HALLAZGOS: 'EDITAR_TIPO_HALLAZGOS',
    EDITAR_MONEDA: 'EDITAR_MONEDA',
    EDITAR_EMPRESAS: 'EDITAR_EMPRESAS',
    EDITAR_UNIDADES_NEGOCIO: 'EDITAR_UNIDADES_NEGOCIO',
    EDITAR_ROLES_USUARIOS: 'EDITAR_ROLES_USUARIOS',
    EDITAR_USUARIOS: 'EDITAR_USUARIOS',
    EDITAR_RESPONSABLES: 'EDITAR_RESPONSABLES'
  }
})
