import { useState } from "react"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import Tooltip from "src/components/Tooltip"
import { getTreeitemConfig } from "src/helpers/tree"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function RenameTreeItemAction({ item, onSubmit }) {
  const [open, setOpen] = useState(false)

  const [value, setValue] = useState(item.name)

  const { t } = useTranslation()
  const handleClick = (ev) => {
    ev.stopPropagation()

    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const inputComponent =
    getTreeitemConfig(item, "renameComponent", "input") == "textarea" ? (
      <textarea
        defaultValue={value}
        onChange={(ev) => setValue(ev.target.value)}
        className={styles.textarea}
        style={{ maxHeight: "40vh" }}
        rows={getTreeitemConfig(item, "renameComponentHeight", 8)}
      />
    ) : (
      <Input initialValue={value} onChange={(value) => setValue(value)} />
    )

  return (
    <>
      <Tooltip containerStyle={{ cursor: "pointer" }} content={t("frontend.auditoria.treeitem.actions.rename.title")}>
        <button type='button' onClick={handleClick} className={styles.buttonAction}>
          <i className={`ui icon edit outline`} />
        </button>
      </Tooltip>
      {open && (
        <Dialog isOpen>
          <Dialog.Header theme='light' buttonClose={true} handleClose={handleClose}>
            <h4>{t("frontend.auditoria.treeitem.actions.rename.title")}</h4>
          </Dialog.Header>
          <Dialog.Body theme='light'>
            <FormField
              label={t("frontend.auditoria.treeitem.actions.rename.description")}
              twoPoints={false}
              bold={false}
              required
              className={`fieldInput fieldInputdefault`}
            >
              {inputComponent}
            </FormField>
          </Dialog.Body>
          <Dialog.Footer theme='light'>
            <Button text='btn_cancel' name='warning' type='big' handleClick={handleClose} />
            <Button
              text='btn_save'
              name='normal'
              type='big'
              handleClick={() => {
                if (value === "") {
                  return
                }

                onSubmit(item, value).then(() => {
                  handleClose()
                })
              }}
            />
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  )
}
