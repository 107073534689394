import React, { forwardRef } from 'react'
import styles from './style.module.scss'

function GanttSeparator(
  {
    style = {},
    special = false,
    color = '',
    className = '',
    label = '',
    children = null,
    renderedRowsAmount = 0,
    rowHeight = 40,
    headerSize = 40,
    ...otherProps
  },
  ref
) {

  const specialClassName = special ? ` ${styles.special}` : ''
  const separatorClassName = `${styles.gantSeparator}${specialClassName}${className !== '' ? ` ${className}` : ''}`

  const labelClassName = `${styles.separatorLabel}`

  if (special && color !== '') {
    style = {
      ...style,
      borderLeftColor: color
    }
  } else if (color !== '') {
    style = {
      ...style,
      backgroundColor: color
    }
  }

  if (renderedRowsAmount > 0) {
    style = {
      ...style,
      height: renderedRowsAmount * rowHeight + headerSize
    }
  }

  return (
    <div style={style} className={separatorClassName} ref={ref} {...otherProps}>
      {label !== '' ? (
        <div className={labelClassName} style={color !== '' ? {
          color
        } : undefined}>
          <span>
            {label}
          </span>
        </div>
      ) : null}

      {children}
    </div>
  )
}

export default forwardRef(GanttSeparator)
