import { cloneDeep } from "lodash"
import { useRouter } from "next/router"
import { useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import useAccessControl from "src/hooks/useAccessControl"
import FormField from "../../../components/FormField"
import Select from "../../../components/FormField/Select"
import ErrorBoundary from "../../../errors/ErrorBoundary"
import useDataUpdater from "../../../hooks/useDataUpdater"
import { logoutCallback } from "../../../services/authValidationMiddleware"
import {
  createFactorRisk,
  createGeneralRisk,
  createObjectiveRisk,
  createSpecificRisk,
  getFactorRisks,
  getGeneralRisks,
  getObjectiveRisks
} from "../../../services/paramlists.service"
import useDispatch from "../../../store"
import * as risksActions from "../../../store/risks/actions"
import styles from "./style.module.scss"

const POPULATE_SPECIFIC_RISK_OPTIONS = "POPULATE_SPECIFIC_RISK_OPTIONS"
const DISABLE_INPUT = "DISABLE_INPUT"
const ENABLE_INPUT = "ENABLE_INPUT"
const SET_VALUE = "SET_VALUE"
const ADD_VALUE = "ADD_VALUE"
const ADD_OPTION_IN_GENERAL_RISK_OPTIONS = "ADD_OPTION_IN_GENERAL_RISK_OPTIONS"
const SET_INITIAL_OPTION_VALUES = "SET_INITIAL_OPTION_VALUES"

const formReducer = (state, action) => {
  switch (action.type) {
    case POPULATE_SPECIFIC_RISK_OPTIONS: {
      const found = action.optSelected.id
        ? state.options.generalRisk.find((el) => el.id === action.optSelected.id)
        : null

      let updatedSpecificRisk = []
      if (found) {
        found.options.forEach((element) => {
          element.selected = false
          if (action.specificSelected.length > 0 && action.specificSelected.find((el) => el === element.id)) {
            element.selected = true
          }
        })
        updatedSpecificRisk = found.options
      }

      const updatedOptions = {
        ...state.options,
        specificRisk: updatedSpecificRisk
      }

      const updatedDisable = {
        ...state.disable,
        specificRisk: false
      }

      return {
        ...state,
        options: updatedOptions,
        disable: updatedDisable,
        values: {
          ...state.values,
          specificRisk: []
        }
      }
    }

    case DISABLE_INPUT: {
      const updatedDisable = {
        ...state.disable,
        [action.key]: true
      }

      return {
        ...state,
        disable: updatedDisable
      }
    }

    case ENABLE_INPUT: {
      const updatedDisable = {
        ...state.disable,
        [action.key]: false
      }

      return {
        ...state,
        disable: updatedDisable
      }
    }

    case SET_VALUE: {
      const updatedValues = {
        ...state.values,
        [action.id]: action.value
      }

      return {
        ...state,
        values: updatedValues
      }
    }

    case ADD_VALUE: {
      const updatedOptions = { ...state.options }
      updatedOptions[action.key].push(action.value)

      return {
        ...state,
        options: updatedOptions
      }
    }

    case ADD_OPTION_IN_GENERAL_RISK_OPTIONS: {
      const copyGeneralRisk = [...state.options["generalRisk"]]
      const index = copyGeneralRisk.findIndex((el) => el.id === state.values["generalRisk"])

      copyGeneralRisk[index] = {
        ...copyGeneralRisk[index],
        options: copyGeneralRisk[index]
          ? [...copyGeneralRisk[index].options, { id: action.newOption.id, label: action.newOption.label }]
          : [{ id: action.newOption.id, label: action.newOption.label }]
      }

      const updatedOptions = {
        ...state.options,
        generalRisk: copyGeneralRisk
      }

      return {
        ...state,
        options: updatedOptions
      }
    }

    case SET_INITIAL_OPTION_VALUES: {
      const updatedOptions = {
        ...state.options,
        ...action.options
      }

      return {
        ...state,
        options: updatedOptions
      }
    }
    default:
      return state
  }
}

export default function RiskSelectsManagement({
  config = {
    mode: "UPDATE",
    riesgo_general: [],
    riesgos_especificos: [],
    factores: [],
    objetivos: [],
    id: null,
    indexes: []
  },
  onChange = () => {},
  auditId,
  typeTree = "auditPlan",
  readOnly = false
}) {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    options: {
      generalRisk: [],
      specificRisk: [],
      riskFactor: [],
      orgObjective: []
    },
    values: {
      generalRisk: null,
      specificRisk: [],
      riskFactor: [],
      orgObjective: []
    },
    disable: {
      generalRisk: false,
      specificRisk: false,
      riskFactor: false,
      orgObjective: false
    }
  })
  const {
    risks: { isLoaded, general, factors, objectives }
  } = useSelector((state) => state)

  const router = useRouter()
  const dispatch = useDispatch()

  const { hasPermission } = useAccessControl()

  const [selectedRiskSpecifics, setSelectedRiskSpecifics] = useState([])

  const { updaters } = useDataUpdater({
    itemId: config.id,
    indexes: config.indexes,
    form: {
      instant: [
        {
          name: "specificRisk",
          isArrayProp: false
        },
        {
          name: "generalRisk",
          isArrayProp: false
        }
      ],
      debounced: []
    },
    auditId,
    typeTree
  })

  const onSelectHandler = (key, val) => {
    let dataToUpdate = {}

    let values

    const updatedValues = {
      ...formState.values
    }

    switch (key) {
      case "generalRisk":
        values = val.id
        updatedValues.specificRisk = []

        if (!val.id) {
          dispatchFormState({
            type: DISABLE_INPUT,
            key: "specificRisk"
          })
        }
        let arrayRiskSpecifics = []
        if (config.mode === "UPDATE") {
          let optionsRiskSpecifics

          formState.options.generalRisk.forEach((el) => {
            if (el.id === val.id) {
              optionsRiskSpecifics = el
            }
          })

          if (optionsRiskSpecifics) {
            optionsRiskSpecifics.options?.forEach((element) => {
              if (selectedRiskSpecifics.find((el) => el === element.id)) {
                arrayRiskSpecifics.push(element.id)
              }
            })
          }
        }

        dataToUpdate = {
          riesgo_general: values,
          riesgos_especificos: arrayRiskSpecifics
        }

        dispatchFormState({
          type: POPULATE_SPECIFIC_RISK_OPTIONS,
          optSelected: { id: val.id },
          specificSelected: arrayRiskSpecifics
        })

        break
      case "specificRisk":
        values = val.map((el) => el.id)
        setSelectedRiskSpecifics(values)
        dataToUpdate = {
          riesgos_especificos: values
        }

        break
      case "riskFactor":
        values = val.map((el) => el.id)
        dataToUpdate = {
          factores: values
        }
        break
      case "orgObjective":
        values = val.map((el) => el.id)
        dataToUpdate = {
          objetivos: values
        }
        break
    }

    if (config.mode === "UPDATE") {
      updaters.instant.generalRisk(dataToUpdate)
    }

    dispatchFormState({
      type: SET_VALUE,
      id: key,
      value: values
    })

    updatedValues[key] = values

    onChange(updatedValues)
  }

  const onAddSucceedHandler = (key, val) => {
    switch (key) {
      case "specificRisk":
        dispatchFormState({
          type: ADD_OPTION_IN_GENERAL_RISK_OPTIONS,
          newOption: { id: val.id, label: val.label }
        })

        const dataFormat = {
          id: val.id,
          nombre: val.label,
          descripcion: null
        }

        dispatch(
          risksActions.addRisk({
            id: formState.values["generalRisk"],
            riskCreated: dataFormat,
            key: "general"
          })
        )

        break
      case "generalRisk":
        dispatchFormState({
          type: ADD_VALUE,
          key,
          value: {
            id: val.id,
            label: val.label,
            options: [],
            selected: true
          }
        })
        dispatch(
          risksActions.addRisk({
            riskCreated: val.data,
            key: "general"
          })
        )
        break
      case "riskFactor":
        dispatch(
          risksActions.addRisk({
            riskCreated: val.data,
            key: "factors"
          })
        )
        break
      case "orgObjective":
        dispatch(
          risksActions.addRisk({
            riskCreated: val.data,
            key: "objectives"
          })
        )
        break
    }
  }

  const mergeGeneralRiskAndSpecificRisk = (options) => {
    let found
    options.generalRisk.forEach((el) => {
      el.selected = false

      if (el.id === config.riesgo_general?.id) {
        el.selected = true
        found = el
        formState.values["generalRisk"] = config.riesgo_general?.id
      }
    })
    let elChecked = []
    if (found) {
      found.options.forEach((element) => {
        element.selected = false
        if (config.riesgos_especificos.find((el) => el.id === element.id)) {
          element.selected = true
          elChecked.push(element.id)
        }
      })
      setSelectedRiskSpecifics(elChecked)
      options.specificRisk = found.options
      dispatchFormState({
        type: ENABLE_INPUT,
        key: "specificRisk"
      })
    } else {
      options.generalRisk[0].selected = true
      options.specificRisk = []

      dispatchFormState({
        type: DISABLE_INPUT,
        key: "specificRisk"
      })
    }
  }

  const mergeRiskFactor = (options) => {
    options.riskFactor.forEach((elem) => {
      elem.selected = false

      if (config.factores?.find((el) => el.id === elem.id)) {
        elem.selected = true
      }
    })
  }

  const mergeOrgObjective = (options) => {
    options.orgObjective.forEach((elem) => {
      elem.selected = false

      if (config.objetivos?.find((el) => el.id === elem.id)) {
        elem.selected = true
      }
    })
  }

  const mergeOptions = (options) => {
    mergeGeneralRiskAndSpecificRisk(options)

    mergeRiskFactor(options)

    mergeOrgObjective(options)
  }

  const fetchBaseData = () => {
    return Promise.all([
      getObjectiveRisks(logoutCallback(dispatch, router)),
      getFactorRisks(logoutCallback(dispatch, router)),
      getGeneralRisks(logoutCallback(dispatch, router))
    ])
  }

  const getFormattedOptions = ({ objectiveRisksData, factorRisksData, generalRisksData }) => {
    let options = {}
    const optionsFormatter = (list) => {
      return cloneDeep(list).map((element) => {
        return {
          id: element.id,
          label: element.nombre
        }
      })
    }

    options.riskFactor = optionsFormatter(factorRisksData)
    options.orgObjective = optionsFormatter(objectiveRisksData)

    options.specificRisk = []
    options.generalRisk = generalRisksData.map((element) => {
      return {
        id: element.id,
        label: element.nombre,
        options: optionsFormatter(element.riesgos_especificos)
      }
    })
    options.generalRisk.unshift({
      id: null,
      label: "-Seleccionar-"
    })

    if (config.mode === "UPDATE") {
      mergeOptions(options)
    } else if (config.mode === "CREATE") {
      options.generalRisk[0].selected = true
      dispatchFormState({
        type: DISABLE_INPUT,
        key: "specificRisk"
      })
    }

    return options
  }

  const setInitialValues = async () => {
    try {
      const [objectiveRisks, factorRisks, generalRisks] = await fetchBaseData()

      dispatch(
        risksActions.populateRisks({
          risks: {
            general: generalRisks.data,
            factors: factorRisks.data,
            objectives: objectiveRisks.data
          }
        })
      )

      const options = getFormattedOptions({
        generalRisksData: generalRisks.data,
        factorRisksData: factorRisks.data,
        objectiveRisksData: objectiveRisks.data
      })

      dispatchFormState({
        type: SET_INITIAL_OPTION_VALUES,
        options
      })
    } catch (error) {
      console.log(error, "error")
    }
  }

  useEffect(() => {
    if (isLoaded) {
      const options = getFormattedOptions({
        generalRisksData: general,
        factorRisksData: factors,
        objectiveRisksData: objectives
      })

      dispatchFormState({
        type: SET_INITIAL_OPTION_VALUES,
        options
      })
    } else {
      setInitialValues()
    }
  }, [])

  return (
    <ErrorBoundary>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "15px 20px"
        }}
      >
        <div className={styles.optionWrapper} style={{ gridTemplateRows: "auto 1fr" }}>
          <h3 className={styles.subTitle} style={{ textAlign: "center" }}>
            Riesgo General
          </h3>
          <FormField outline={readOnly ? null : "thick"}>
            <Select
              canAdd={
                readOnly || !hasPermission("riesgo_general.add")
                  ? false
                  : {
                      text: "Agregar Riesgo General"
                    }
              }
              showOptionSelectedBelow
              onAdd={{
                service: createGeneralRisk,
                success: onAddSucceedHandler.bind(this, "generalRisk"),
                error: () => {}
              }}
              selectionChange={onSelectHandler.bind(this, "generalRisk")}
              initialOptions={formState.options["generalRisk"]}
              readOnly={readOnly}
            />
          </FormField>
        </div>

        <div className={styles.optionWrapper} style={{ gridTemplateRows: "auto 1fr" }}>
          <h3 className={styles.subTitle} style={{ textAlign: "center" }}>
            Riesgo Específico
          </h3>
          <FormField outline={readOnly ? null : "thick"}>
            <Select
              disabled={formState.disable["specificRisk"]}
              readOnly={readOnly}
              multiple
              canAdd={
                readOnly || !hasPermission("riesgo_especifico.add")
                  ? false
                  : {
                      text: "Agregar Riesgo Específico"
                    }
              }
              onAdd={{
                service: (val) =>
                  createSpecificRisk({
                    name: val,
                    id:
                      formState.values["generalRisk"] === null
                        ? config.riesgo_general?.id
                        : formState.values["generalRisk"]
                  }),
                success: onAddSucceedHandler.bind(this, "specificRisk"),
                error: () => {}
              }}
              selectionChange={onSelectHandler.bind(this, "specificRisk")}
              initialOptions={formState.options["specificRisk"]}
            />
          </FormField>
        </div>

        <div className={styles.optionWrapper} style={{ gridTemplateRows: "auto 1fr" }}>
          <h3 className={styles.subTitle} style={{ textAlign: "center" }}>
            Factor de Riesgo
          </h3>
          <FormField outline={readOnly ? null : "thick"}>
            <Select
              multiple
              canAdd={
                readOnly || !hasPermission("riesgo_factores.add")
                  ? false
                  : {
                      text: "Agregar Factor"
                    }
              }
              onAdd={{
                service: createFactorRisk,
                success: onAddSucceedHandler.bind(this, "riskFactor"),
                error: () => {}
              }}
              selectionChange={onSelectHandler.bind(this, "riskFactor")}
              initialOptions={formState.options["riskFactor"]}
              readOnly={readOnly}
            />
          </FormField>
        </div>

        <div className={styles.optionWrapper} style={{ gridTemplateRows: "auto 1fr" }}>
          <h3 className={styles.subTitle} style={{ textAlign: "center" }}>
            Objetivo Organizacional
          </h3>
          <FormField outline={readOnly ? null : "thick"}>
            <Select
              multiple
              canAdd={
                readOnly || !hasPermission("riesgo_objetivos.add")
                  ? false
                  : {
                      text: "Agregar Objetivo"
                    }
              }
              onAdd={{
                service: createObjectiveRisk,
                success: onAddSucceedHandler.bind(this, "orgObjective"),
                error: () => {}
              }}
              selectionChange={onSelectHandler.bind(this, "orgObjective")}
              initialOptions={formState.options["orgObjective"]}
              readOnly={readOnly}
            />
          </FormField>
        </div>
      </div>
    </ErrorBoundary>
  )
}
