export const getToggleState = (_treeId) => {
  const treeId = localStorage.getItem('treeId')
  const toggleState = localStorage.getItem('toggleState')

  if (treeId && toggleState && JSON.parse(treeId) === _treeId) {
    const { indexes, flag } = JSON.parse(toggleState) ?? {
      indexes: [],
      flag: false
    }

    return { indexes, flag }
  }

  localStorage.setItem('treeId', JSON.stringify(_treeId))
  toggleState && localStorage.removeItem('toggleState')

  return undefined
}
