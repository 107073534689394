import { GanttMode } from './index'
import formatFunc from 'date-fns/format'

function coordsRange(x1 = 0, x2 = 0) {
  if (x1 < 0 || x1 >= x2) {
    return [ x1 ]
  }

  const result = []

  for (let value = x1; value < x2; value++) {
    result.push(value)
  }

  return result
}

function makeCoordsMap(x1 = 0, x2 = 0) {
  return coordsRange(x1, x2).reduce((_map, x) => {
    _map[x] = {
      overlaps: 0
    }

    return _map
  }, {})
}

export function findOverlappingRegions(
  {
    coords = {
      x1: 0,
      x2: 0
    },
    regions = [
      { x1: 0, x2: 0 }
    ]
  }
) {
  const coordsMap = makeCoordsMap(coords.x1, coords.x2 + 1)

  if (coordsMap === null) {
    return [ false, null ]
  }

  regions.forEach(region => {
    const regionRange = coordsRange(region.x1, region.x2 + 1)

    if (regionRange === null) {
      return
    }

    regionRange.forEach(x => {
      if (!(x in coordsMap)) {
        return
      }

      coordsMap[x].overlaps += 1
    })
  })

  // if coordsMap[x].overlaps === 0 then it gets painted red
  // if coordsMap[x].overlaps === 1 then it DOES NOT get painted
  // if coordsMap[x].overlaps >= 2 then it gets painted the (opacity * amount of overlaps)
  // the opacity might be 20% by default, reaching its peak at 100% (5 overlaps), hence why
  // if coordsMap[x].overlaps >= 6 then it gets painted 100% OPACITY

  return [ true, coordsMap ]
}

export function findCoords(start = new Date(), end = new Date(), {
  mode = GanttMode.Daily,
  daysMap = {},
  monthsMap = {}
}) {
  switch (mode) {
    case GanttMode.Daily: {
      if (Object.keys(daysMap).length === 0) {
        return null
      }

      const startDateKey = formatFunc(start, 'yyyy-MM-dd')
      const startDateIdx = daysMap[startDateKey]

      const endDateKey = formatFunc(end, 'yyyy-MM-dd')
      const endDateIdx = daysMap[endDateKey]

      return {
        x1: startDateIdx,
        x2: endDateIdx
      }
    }

    case GanttMode.Monthly: {
      if (Object.keys(monthsMap).length === 0) {
        return null
      }

      const startDateKey = formatFunc(start, 'yyyy-MM-dd')
      const [ startDateYear, startDateMonth, startDateDay ] = startDateKey.split('-')
      const startDateDayKey = parseInt(startDateDay, 10)
      const startDateMonthKey = `${startDateYear}-${startDateMonth}`

      let startDateIdx = -1

      if (startDateMonthKey in monthsMap) {
        const startMonthMondayKeys = Object.keys(monthsMap[startDateMonthKey])
          .map(x => parseInt(x, 10))
          .sort((a, b) => {
            if (a > b) {
              return -1
            }

            if (a < b) {
              return 1
            }

            return 0
          })

        const startDateMondayIdx = startMonthMondayKeys.find(monday => {
          return startDateDayKey >= monday
        }) ?? startMonthMondayKeys[startMonthMondayKeys.length - 1]

        startDateIdx = monthsMap[startDateMonthKey][startDateMondayIdx]
      }

      const endDateKey = formatFunc(end, 'yyyy-MM-dd')
      const [ endDateYear, endDateMonth, endDateDay ] = endDateKey.split('-')
      const endDateDayKey = parseInt(endDateDay, 10)
      const endDateMonthKey = `${endDateYear}-${endDateMonth}`

      let endDateIdx = -1

      if (endDateMonthKey in monthsMap) {
        const endMonthMondayKeys = Object.keys(monthsMap[endDateMonthKey])
          .map(x => parseInt(x, 10))
          .sort((a, b) => {
            if (a > b) {
              return -1
            }

            if (a < b) {
              return 1
            }

            return 0
          })

        const endDateMondayIdx = endMonthMondayKeys.find(monday => {
          return endDateDayKey >= monday
        }) ?? endMonthMondayKeys[endMonthMondayKeys.length - 1]

        endDateIdx = monthsMap[endDateMonthKey][endDateMondayIdx]
      }

      return {
        x1: startDateIdx,
        x2: endDateIdx
      }
    }

    default: {
      return null
    }
  }
}
