import cx from "classnames"
import Icon from "../Icon"
import { tooltipPosition } from "../Tooltip"
import Truncate from "../Truncate"
import styles from "./style.module.scss"

export default function TreeLabel(props) {
  const {
    className = null,
    iconSize = "small",
    icon = null,
    name = null,
    isColorless = false,
    isBold = true,
    color = null,
    showIcon = true,
    innerHtml = true,
    truncate = true,
    ...otherProps
  } = props

  const treeLabelClassName = className !== null ? `${styles.treeLabel} ${className}` : styles.treeLabel
  const labelClassName = `${styles.label}${isColorless ? " " + styles.colorless : ""}${isBold ? " " + styles.bold : ""}`

  const Component = otherProps.href ? "a" : "div"
  if (otherProps.href) {
    otherProps.target = "_blank"
    otherProps.onClick = null
  }

  return (
    <Component className={cx("treeLabel", treeLabelClassName, truncate && styles.truncate)} {...otherProps}>
      {showIcon && (
        <div className={styles.icon}>
          <Icon name={icon} size={iconSize === "small" ? 16 : 24} color={color === null ? undefined : color} />
        </div>
      )}
      <div className={labelClassName}>
        {truncate ? (
          <Truncate position={tooltipPosition.belowRight} style={{ maxWidth: 550 }} innerHtml={innerHtml} atLine={1}>
            {name}
          </Truncate>
        ) : (
          name
        )}
      </div>
    </Component>
  )
}
