import cx from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { Step } from "semantic-ui-react"
import Checkbox from "src/components/FormField/Checkbox"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import Table from "src/components/Table"
import TableContent from "src/components/Table/TableContent"
import TableRow from "src/components/Table/TableContent/TableRowGroup/TableRow"
import TableColumn from "src/components/Table/TableContent/TableRowGroup/TableRow/TableColumn"
import TableHeader from "src/components/Table/TableHeader"
import TableHeaderItem from "src/components/Table/TableHeader/TableHeaderItem"
import { matchsSearchText } from "src/helpers/search"
import useWizardStep from "src/hooks/useWizardStep"
import { getLibrariesRisk } from "src/services/libraries.service"

export default function CreateRiskFromLibrary({ onCheck }) {
  const [dataLibraries, setDataLibraries] = useState(null)
  const [textToFilter, setTextToFilter] = useState("")
  const [selectedMp, setSelectedMp] = useState(null)
  const [selectedProcess, setSelectedProcess] = useState(null)

  useEffect(() => {
    getLibrariesRisk().then(({ data }) => {
      setDataLibraries(data)
    })
  }, [])

  const { currentStep, gotoStep } = useWizardStep()

  const mp = useMemo(() => {
    if (dataLibraries === null) {
      return null
    }

    const p = new Set()

    dataLibraries.forEach((x) => {
      p.add(x.macroproceso_ref)
    })

    return Array.from(p).sort()
  }, [dataLibraries])

  const getProcesos = () => {
    if (dataLibraries === null || selectedMp === null) {
      return []
    }
    const p = new Set()
    dataLibraries
      .filter((x) => x.macroproceso_ref === selectedMp)
      .forEach((x) => {
        p.add(x.proceso_ref)
      })

    return Array.from(p).sort()
  }

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='ui two column grid'>
            <div className='column'>
              <p>a.) Seleccione el macroproceso:</p>
              <ul className='ui fluid vertical menu'>
                {mp.map((proceso, k) => {
                  return (
                    <div
                      key={k}
                      className={cx("item clickable", selectedMp == proceso && "active green")}
                      onClick={() => setSelectedMp(proceso)}
                    >
                      {proceso} <i className='icon caret right ' />
                    </div>
                  )
                })}
              </ul>
            </div>
            <div className='column'>
              {selectedMp && (
                <>
                  <p>b.) Ahora seleccione el proceso:</p>
                  <ul className='ui fluid vertical menu'>
                    {getProcesos().map((proceso, k) => {
                      return (
                        <div
                          key={k}
                          className={cx("item clickable", selectedProcess === proceso && "active green")}
                          onClick={() => {
                            setSelectedProcess(proceso)
                            gotoStep(2)
                          }}
                        >
                          {proceso} <i className='icon caret right ' />
                        </div>
                      )
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
        )

      case 2:
        const riesgos = dataLibraries.filter(
          (x) =>
            x.macroproceso_ref == selectedMp &&
            x.proceso_ref == selectedProcess &&
            matchsSearchText(textToFilter, x, ["nombre"])
        )

        return (
          <>
            <InputSearch
              onValueChange={(value) => {
                setTextToFilter(value)
              }}
            />
            <p style={{ marginBottom: 5 }}>Seleccione los riesgos de la biblioteca que desea agregar a su proyecto</p>
            <div style={{ position: "relative" }}>
              <Table>
                <TableHeader background='#e6e6e6' positionStatic>
                  <TableHeaderItem align='center' color='#424242' width={40}>
                    Riesgo
                  </TableHeaderItem>
                  <TableHeaderItem align='center' color='#424242' width={15}>
                    Riesgo General
                  </TableHeaderItem>
                  <TableHeaderItem align='center' color='#424242' width={20}>
                    Riesgo Específico
                  </TableHeaderItem>
                  <TableHeaderItem align='center' color='#424242' width={20}>
                    Factor del Riesgo
                  </TableHeaderItem>
                  <TableHeaderItem width={5} />
                </TableHeader>
                <TableContent background='#fff'>
                  {riesgos.map((el) => {
                    return (
                      <TableRow key={el.id}>
                        <TableColumn align='left' color='#808080' width={40}>
                          {el.nombre}
                        </TableColumn>
                        <TableColumn align='left' color='#808080' width={15}>
                          {el.riesgogeneral_ref}
                        </TableColumn>
                        <TableColumn align='left' color='#808080' width={20}>
                          {el.riesgoespecifico_ref}
                        </TableColumn>
                        <TableColumn align='left' color='#808080' width={20}>
                          {el.factorriesgo_ref}
                        </TableColumn>
                        <TableColumn align='center' color='#808080' width={5}>
                          <Checkbox checked={el.checked} onCheck={onCheck.bind(this, el)} />
                        </TableColumn>
                      </TableRow>
                    )
                  })}
                </TableContent>
              </Table>
            </div>
          </>
        )
    }
  }

  if (dataLibraries === null) {
    return <Loading text='Cargando riesgos...' curtain={false} />
  }

  return (
    <>
      <Step.Group ordered attached='top'>
        {["Selección del Proceso", "Selección de Riesgos"].map((label, idx) => {
          return (
            <Step
              key={idx}
              active={currentStep == idx + 1}
              onClick={() => gotoStep(idx + 1)}
              completed={currentStep > idx + 1}
            >
              <Step.Content>
                <Step.Title>{label}</Step.Title>
              </Step.Content>
            </Step>
          )
        })}
      </Step.Group>
      <div className='ui attached segment'>{renderContent()}</div>
    </>
  )
}
