import { useCallback } from "react"
import { useSelector } from "react-redux"
import { getTreeitemConfig } from "src/helpers/tree"
import useAccessControl from "./useAccessControl"

/**
 *
 * @typedef {(number|Object.<number, boolean>)} PermissionValue
 */

/**
 *
 * @returns
 */
export function useAuditAccessControl() {
  const { getCurrentUser, isUserAdmin, isUserGuest, hasPermission } = useAccessControl()
  /**
   * The audit data.
   */
  const auditData = useSelector((state) => state.project)
  /**
   * The auditTree configs
   */
  const treeConfigs = useSelector((state) => state.tree?.configs)
  /**
   * Current user
   */
  const user = getCurrentUser()

  /**
   *
   * @param {number} userPermissionValue - The permission value for current user.
   * @param {PermissionValue} permissionValue - The expected permission value.
   *
   * @returns {boolean}
   */
  const checkUserHasAuditPermissionValue = (userPermissionValue, permissionValue) => {
    if (permissionValue === null) {
      return userPermissionValue !== "0"
    }

    if (["number", "string"].includes(typeof permissionValue)) {
      return userPermissionValue == `${permissionValue}`
    }

    if (userPermissionValue === "1") {
      return true
    }

    if (userPermissionValue === "0") {
      return false
    }

    if (userPermissionValue === "undefined") {
      return true
    }

    if (typeof permissionValue[userPermissionValue] === "function") {
      return permissionValue[userPermissionValue]({ currentUserId: user.id })
    }

    const userPermissionValueAsInt = parseInt(userPermissionValue)
    let mismatches = false
    Object.entries(permissionValue).forEach(([k, v]) => {
      if (!v && userPermissionValueAsInt & k) {
        mismatches = true
      }
    })

    return !mismatches
  }

  /**
   * Checks if the current user has the specified permission inside audit.
   *
   * @param {(string|string[])} permissions - The permissions to validate if user has.
   * @param {PermissionValue} [permissionValue] - The expected permission value. This can be a number, an object, or null.
   *  - if it's is an object, the object key is the permission value and the object value is the condition.
   *  - if it's is a number, it's the expected permission value.
   *  - if it's is null, it's ignored.
   *
   * @returns {boolean} Returns true if the user has the specified permission(s), false otherwise.
   */
  const hasAuditPermission = (permissions, permissionValue = null) => {
    if (!auditData || typeof auditData.permissions === "undefined") {
      return false
    }

    if (isUserAdmin()) {
      return true
    }

    if (typeof permissions === "undefined" || permissions === true) {
      return true
    }

    return !Array.isArray(permissions)
      ? checkUserHasAuditPermissionValue(`${auditData.permissions[permissions]}`, permissionValue)
      : permissions.every((perm) => checkUserHasAuditPermissionValue(`${auditData.permissions[perm]}`, permissionValue))
  }

  /**
   *
   * Checks if current user has a non-readonly permission inside audit.
   *
   * @param {(string|string[])} permissions - The permissions to validate if user has.
   * @param {PermissionValue} [permissionValue] - The expected permission value. This can be a number, an object, or null.
   *  - if it's is an object, the object key is the permission value and the object value is the condition.
   *  - if it's is a number, it's the expected permission value.
   *  - if it's is null, it's ignored.
   * @param {object} [record] - The record to check the permission against. This is optional.
   *
   * @returns {boolean} Returns true if the user has the specified permission(s), false otherwise.
   */
  const hasAuditMutablePermission = (permissions, permissionValue = null, record = null) => {
    if (!auditData) {
      return false
    }

    if (isUserGuest()) {
      return false
    }

    if (auditData.fecha_cierre_real != null) {
      const checker = record ? getTreeitemConfig(record, "closedAuditPermChecker", null) : null
      if (checker) {
        const isGranted = checker(record, { ...user, isAdmin: isUserAdmin() }, permissions, treeConfigs)
        if (isGranted !== null) {
          return isGranted
        }
      } else {
        return false
      }
    }

    return hasAuditPermission(permissions, permissionValue, record)
  }

  /**
   * Returns if current audit is in readonly mode.
   *
   * @returns {boolean} Returns true if current audit is in readonly mode, false otherwise.
   */
  const isReadOnlyAudit = useCallback(() => {
    if (!auditData) {
      return true
    }

    if (auditData.fecha_cierre_real != null) {
      return true
    }

    return false
  }, [auditData?.id])

  /**
   *
   * @param {object} record - The record
   * @param {string[]} flags - The flag names
   *
   * @returns {object}
   */
  const buildAuditPermissionFlags = (record, flags) => {
    const permissions = {}

    const condition = (flag) => {
      if (typeof flag === "object") {
        return flag
      }

      switch (flag) {
        case "assigned_user":
          if (!record.usuario_asignado) {
            return record.created_by?.id == user?.id
          }
          return record.usuario_asignado?.id == user?.id
        case "not_reviewed":
          return record.status != "REVISADO"
        default:
          return false
      }
    }

    flags.forEach((flag, idx) => {
      permissions[Math.pow(2, idx + 1)] = condition(flag)
    })

    if (Object.values(permissions) == 0) {
      return null
    }

    return permissions
  }

  /**
   *
   * @param {string} typeTree
   * @param {object} record
   * @param {boolean} [readOnly=false]
   *
   * @returns {function}
   */
  const buildHasWritePermissionFunction = (typeTree, record, readOnly = false) => {
    /**
     *
     * @param {(string|string[])} permission - The permission to check.
     * @param {PermissionValue} permissionValue - The expected value
     * @returns {boolean}
     */
    const func = (permission, permissionValue = null) => {
      if (readOnly) {
        return false
      }

      if (typeTree == "auditPlan") {
        if (Array.isArray(permissionValue)) {
          permissionValue = buildAuditPermissionFlags(record, permissionValue)
        }
        return hasAuditMutablePermission(permission, permissionValue, record)
      }

      if (typeTree == "auditTemplate") {
        return hasPermission("pyr_audit_template.edit")
      }

      return hasPermission("pyr_evaluacion.edit_tree")
    }

    return func
  }

  return {
    hasAuditPermission,
    hasAuditMutablePermission,
    checkUserHasAuditPermissionValue,
    buildAuditPermissionFlags,
    buildHasWritePermissionFunction,
    isReadOnlyAudit
  }
}
