import cx from "classnames"
import { useContext, useMemo } from "react"
import NumberFormat from "src/components/NumberFormat"
import Tooltip from "src/components/Tooltip"
import Truncate from "src/components/Truncate"
import { mapAuditStatus } from "src/helpers/audit"
import { TreeConfigContext } from "src/macrocomponents/Tree"
import { rowFrontendType } from "../../../constants/rowTypes"
import { treeItemColumn } from "../../../constants/treeItemColumn"
import { mapDesignControl, mapSolidity } from "../../../helpers/control"
import { mapActionPlan, mapImpact2, mapModelStatus } from "../../../helpers/find"
import { mapWorksheetsModelStatus } from "../../../helpers/map-statuses"
import { mapRiskControlSolidity, mapRiskEvaluation } from "../../../helpers/risk"
import { mapExecutionValidation } from "../../../helpers/test"
import SignatureSimplified from "../../SignatureSimplified"
import styles from "./style.module.scss"

export default function TreeRowColumn({ columnData, width = -1, onClick, item, propsSigningButtons }) {
  const { treeConfigs } = useContext(TreeConfigContext)

  const getFormattedColumn = ({ statusId, data }) => {
    if (typeof data === "function") {
      data = data(item)
    }

    let formattedColumn
    switch (statusId) {
      case treeItemColumn.DESIGN:
        const designControl = mapDesignControl(data)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Diseño</span>
            {designControl ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: designControl.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{designControl.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )

        break
      case treeItemColumn.EXECUTION:
        const execution = mapExecutionValidation(data.value)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Ejecución</span>
            {execution ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: execution.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{execution.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.SOLIDITY:
        const solidityControl = mapSolidity(data)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Solidez</span>
            {solidityControl ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: solidityControl.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{solidityControl.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.FINDINGS:
        formattedColumn = (
          <>
            <span className={styles.statusTitle} style={{ textAlign: "center" }}>
              Hallazgos
            </span>
            <span className={styles.statusValue} style={{ textAlign: "center" }}>
              {data}
            </span>
          </>
        )
        break
      case treeItemColumn.STATUS:
        {
          let status

          if (data.itemType === rowFrontendType.FINDING) {
            status = mapModelStatus(data.status)
          } else if (data.itemType === rowFrontendType.ACTION_PLAN) {
            status = mapActionPlan(data.status)
          } else if (data.itemType === rowFrontendType.AUDIT) {
            status = mapAuditStatus(data.status)
          } else {
            status = mapWorksheetsModelStatus(data.status)
          }

          formattedColumn = (
            <>
              <span className={styles.statusTitle}>Estado</span>
              {status ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    margin: "auto"
                  }}
                >
                  <div style={{ width: 20 }}>
                    {data.name ? (
                      <Tooltip mode='green' title='Usuario Asignado' content={data.name}>
                        <div
                          style={{
                            textTransform: "uppercase",
                            paddingRight: 4,
                            color: "#808080",
                            fontFamily: '"Open Sans", sans-serif',
                            fontSize: 12
                          }}
                        >
                          {data.initials}
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div
                    style={{
                      backgroundColor: status.color,
                      height: 12,
                      width: 6,
                      borderRadius: 6
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#808080",
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: 12,
                      paddingLeft: 4,
                      width: 63,
                      textAlign: "left"
                    }}
                  >
                    <Truncate>{status.label}</Truncate>
                  </div>
                </div>
              ) : (
                <div className={styles.statusValue}>-</div>
              )}
            </>
          )
        }
        break
      case treeItemColumn.EVALUATION:
        {
          let status = mapRiskEvaluation(data.status)

          formattedColumn = (
            <>
              <span className={styles.statusTitle}>Estado</span>
              {status ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    margin: "auto"
                  }}
                >
                  <div style={{ width: 20 }}>
                    {data.name ? (
                      <Tooltip mode='green' title='Usuario Asignado' content={data.name}>
                        <div
                          style={{
                            textTransform: "uppercase",
                            paddingRight: 4,
                            color: "#808080",
                            fontFamily: '"Open Sans", sans-serif',
                            fontSize: 12
                          }}
                        >
                          {data.initials}
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div
                    style={{
                      backgroundColor: status.color,
                      height: 12,
                      width: 6,
                      borderRadius: 6
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#808080",
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: 12,
                      paddingLeft: 4,
                      width: 63
                    }}
                  >
                    <Truncate>{status.label}</Truncate>
                  </div>
                </div>
              ) : (
                <div className={styles.statusValue}>-</div>
              )}
            </>
          )
        }
        break

      case treeItemColumn.PROGRESS:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Avance</span>
            <div
              className={styles.statusValue}
              style={{
                color: "#808080",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: 12,
                paddingLeft: 4,
                textAlign: "center"
              }}
            >
              {data}
            </div>
          </>
        )
        break

      case "KPI":
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>{data.label}</span>
            <div
              className={styles.statusValue}
              style={{
                color: "#808080",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: 12,
                paddingLeft: 4,
                textAlign: "center"
              }}
            >
              {data.value}
            </div>
          </>
        )
        break
      case treeItemColumn.SIGNATURE:
        formattedColumn = (
          <div className={styles.statusValue}>
            <SignatureSimplified
              propsSigningButtons={propsSigningButtons}
              size='small'
              labelSize='normal'
              signature={data}
            />
          </div>
        )

        break

      case treeItemColumn.DEFEATED:
        formattedColumn =
          data.isDefeated && data.status !== "COMPLETADO" && data.status !== "REVISADO" ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div
                style={{
                  backgroundColor: "#ed4138",
                  borderRadius: "50%",
                  height: 12,
                  width: 12,
                  marginRight: 4
                }}
              ></div>
              <div style={{ color: "#ed4138", fontSize: 12 }}>Vencido</div>
            </div>
          ) : null
        break

      case treeItemColumn.RESIDUAL_RISK:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>R. Residual</span>
            {data.extra}
            {data.value === "@LOADING" ? (
              <div className='ui active xxmini centered inline loader'></div>
            ) : data.value ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: data.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{data.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.INHERENT_RISK:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>R. Inherente</span>
            {data.value === "@LOADING" ? (
              <div className='ui active xxmini centered inline loader'></div>
            ) : data.value ? (
              <div style={{ display: "flex", textAlign: "center", margin: "auto" }}>
                <div
                  style={{
                    backgroundColor: data.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{data.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.YEAR:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Año</span>
            {data.value ? (
              <span className={styles.statusValue} style={{ textAlign: "center" }}>
                {data.value}
              </span>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )

        break
      case treeItemColumn.AUDITS_AMOUNT:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Auditorias</span>
            <span className={styles.statusValue} style={{ textAlign: "center" }}>
              {data.value}
            </span>
          </>
        )

        break
      case treeItemColumn.PREVIOUS_YEAR:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Año Anterior</span>
            {data.value ? (
              <span className={styles.statusValue} style={{ textAlign: "center" }}>
                {data.value}
              </span>
            ) : (
              <div className={styles.statusValue}>NUNCA</div>
            )}
          </>
        )

        break
      case treeItemColumn.TYPE:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Tipo</span>
            {data.value ? (
              <span className={styles.statusValue}>{data.value?.nombre}</span>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.PROCESS_TYPE:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Tipo de proceso</span>
            {data.value ? (
              <span className={styles.statusValue}>{data.value}</span>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.LOST:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Pérdida Cuantificada</span>
            <span className={styles.statusValue} style={{ width: "auto" }}>
              <Truncate innerHtml={false}>
                <NumberFormat
                  currencyFormat={treeConfigs.format_currency}
                  displayType={"text"}
                  value={data.value || 0}
                  renderText={(value) => <div>{value}</div>}
                />
              </Truncate>
            </span>
          </>
        )
        break
      case treeItemColumn.IMPACT:
        const impact = mapImpact2(
          treeConfigs?.hallazgo_impacto_ranges.filter((x) => x.is_active == 1),
          data.value
        )

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Impacto</span>
            {impact ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: impact.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{impact.name}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.ACTION_PLAN:
        const actionPlan = mapActionPlan(item.plan_accion_status)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Estado</span>
            {actionPlan ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",

                    paddingLeft:
                      actionPlan.label === "Implementado" || item?.plan_accion_tratamiento_plan_accion === "NO_EVALUAR"
                        ? 7
                        : 0
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        item?.plan_accion_tratamiento_plan_accion === "NO_EVALUAR" ? "#56AAFF" : actionPlan?.color,
                      height: 12,
                      width: 6,
                      borderRadius: 6
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#808080",
                      fontFamily: '"Open Sans", sans-serif',
                      paddingLeft: 4,
                      maxWidth: "5vw"
                    }}
                  >
                    <Truncate>
                      {item?.plan_accion_tratamiento_plan_accion === "NO_EVALUAR" ? "Implementado" : actionPlan.label}
                    </Truncate>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )

        break
      case treeItemColumn.SOLIDITY_CONTROL:
        const solidityRisk = mapRiskControlSolidity(data.value)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Solidez control</span>
            {solidityRisk ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: solidityRisk.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{solidityRisk.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break

      case treeItemColumn.RISKS:
        formattedColumn = (
          <>
            <span className={styles.statusTitle} style={{ textAlign: "center" }}>
              Riesgos
            </span>
            <span className={styles.statusValue}>{data}</span>
          </>
        )
        break

      case treeItemColumn.PROBABILITY:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Probabilidad</span>
            {data.value ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: data.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{data.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break

      case treeItemColumn.IMPACT_RISK:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Impacto</span>
            {data.value ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: data.color,
                    height: 12,
                    width: 6,
                    borderRadius: 6
                  }}
                ></div>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{data.label}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.EXPIRATION:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Vencimiento</span>
            {item?.plan_accion_implementacion_fecha_fin ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    color: "#808080",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: 12,
                    paddingLeft: 4
                  }}
                >
                  <Truncate>{item.plan_accion_implementacion_fecha_fin}</Truncate>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break
      case treeItemColumn.RESPONSIBLE:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Responsable</span>
            {item?.plan_accion_responsable && item?.plan_accion_responsable !== "None" ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <Tooltip mode='green' title='Responsable del Plan de Acción' content={item?.plan_accion_responsable}>
                  <div
                    style={{
                      textTransform: "uppercase",
                      paddingRight: 4,
                      color: "#808080",
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: 12
                    }}
                  >
                    {item?.plan_accion_responsable.match(/\b\w/g).join("")}
                  </div>
                </Tooltip>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break

      case treeItemColumn.EVALUATION_STATE:
        const evaluationStatus = mapModelStatus(data.status === "EN_PROCESO" ? "SIN_DISCUTIR" : data.status)

        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Estado</span>
            {evaluationStatus ? (
              <div className={styles.statusValue} style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    textAlign: "center"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: evaluationStatus.color,
                      height: 12,
                      width: 6,
                      borderRadius: 6
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#808080",
                      fontFamily: '"Open Sans", sans-serif',
                      paddingLeft: 4,
                      maxWidth: "5vw"
                    }}
                  >
                    <Truncate>{evaluationStatus.label}</Truncate>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.statusValue}>-</div>
            )}
          </>
        )
        break

      case treeItemColumn.CUMPLIMIENTO:
        formattedColumn = (
          <>
            <span className={styles.statusTitle}>Cumplimiento</span>

            <div className={styles.statusValue} style={{ display: "flex" }}>
              {item?.plan_accion_nivel_cumplimiento} %
            </div>
          </>
        )
        break
      case "custom":
        formattedColumn = (
          <>
            <Tooltip disabled={!data.tooltip} content={data.tooltip}>
              <span className={styles.statusTitle}>{data.title}</span>
            </Tooltip>
            <div className={cx(styles.statusValue, data.style)} style={{ display: "flex" }}>
              {data.value}
            </div>
          </>
        )
        break
    }

    return formattedColumn
  }

  const statusStyle = useMemo(() => {
    if (typeof width !== "number") {
      return undefined
    }

    if (width < 0) {
      return undefined
    }

    return {
      width
    }
  }, [width])

  return (
    <div className={`${styles.status}`} onClick={onClick} style={statusStyle}>
      {getFormattedColumn(columnData)}
    </div>
  )
}
