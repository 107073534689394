import { useEffect, useMemo, useState } from "react"
import { Label } from "semantic-ui-react"
import AdvancedTable from "src/components/AdvancedTable"
import Checkbox from "src/components/FormField/Checkbox"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import SpecialBox from "src/components/SpecialBox"
import useAdvancedFilters from "src/hooks/useAdvancedFilters"
import useWizardStep from "src/hooks/useWizardStep"
import { actionsLibrayRisk } from "src/services/libraries.service"
import { getGeneralRisks } from "src/services/paramlists.service"
import FilterWarning from "../Tree/FilterWarning"

const displayedColumns = [
  "nombre",
  "descripcion",
  "riesgo_general",
  "riesgo_especifico",
  "factores",
  "objetivos",
  "acciones"
]

export default function CreateRiskFromMyLibraryNoWizard({ onCheck, handleCurrenStep, onlyItemChecked = false }) {
  const [dataLibraries, setDataLibraries] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [data, setData] = useState(null)
  const [textToFilter, setTextToFilter] = useState("")
  const [riskGeneralId, setRiskGeneralId] = useState(null)
  const [paginatorData, setPaginatorData] = useState({
    currentPage: null,
    totalPages: null
  })

  const { currentStep } = useWizardStep(1)

  const mp = useMemo(() => {
    if (dataLibraries === null) {
      return null
    }

    const p = new Set()

    dataLibraries.forEach((x) => {
      p.add(x)
    })

    return Array.from(p).sort()
  }, [dataLibraries])

  const AdvancedFilters = useAdvancedFilters({
    fields: [
      {
        label: "frontend.plantillas.biblioteca_riesgos.filtros_avanzados.riesgo_general",
        componentType: "select",
        choices: mp?.map((item) => ({ id: item?.id, label: item?.nombre })),
        add_empty: true,
        name: "riesgo_general"
      }
    ]
  })

  const getData = async (params) => {
    setLoadingData(true)
    const response = await actionsLibrayRisk().GET({ ...params })
    setData(response.data?.records)
    setPaginatorData({
      currentPage: response?.data?.cur_page ?? 1,
      totalPages: response?.data?.num_pages ?? 1
    })
    setLoadingData(false)
  }

  const checkedOnlyItem = (row) => {
    if (onlyItemChecked) {
      const newData = data?.map((item) => ({ ...item, checked: row?.id === item?.id }))
      setData(newData)
      onCheck?.(row)
    } else onCheck?.(row)
  }

  useEffect(() => {
    getGeneralRisks().then(({ data }) => {
      setDataLibraries(data)
    })
  }, [])

  useEffect(() => {
    handleCurrenStep?.(currentStep)
    if (currentStep == 1) setRiskGeneralId(null)
  }, [currentStep])

  useEffect(() => {
    getData({ search: textToFilter, riesgo_general: riskGeneralId, ...AdvancedFilters.values })
  }, [riskGeneralId, textToFilter, AdvancedFilters.values])

  const paginatorConfig = {
    ...paginatorData,
    onBack: () => {
      getData({ page: paginatorData.currentPage - 1, riesgo_general: riskGeneralId, search: textToFilter })
    },
    onNext: () => {
      getData({ page: paginatorData.currentPage + 1, riesgo_general: riskGeneralId, search: textToFilter })
    }
  }

  const onRemoveFilters = () => {
    AdvancedFilters.clear()
    setTextToFilter("")
  }

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
              {AdvancedFilters.Button({ className: "mini" })}
              <InputSearch
                onValueChange={(value) => {
                  setTextToFilter(value)
                }}
              />
            </div>
            {loadingData ? (
              <div style={{ marginTop: "5rem" }} className='ui active centered inline loader'></div>
            ) : (
              <div>
                {AdvancedFilters.Panel()}
                {textToFilter != "" || !AdvancedFilters.isEmpty() ? (
                  <div style={{ width: "100%" }}>
                    <FilterWarning onRemoveFilter={onRemoveFilters} />
                  </div>
                ) : null}
                {/* <p style={{ marginBottom: 2, marginTop: 10 }}>Seleccione los Riesgos:</p> */}
                <AdvancedTable
                  isLoading={data?.length < 0}
                  paginatorConfig={{ ...paginatorConfig }}
                  stickyHeader
                  data={data}
                  displayedColumns={displayedColumns}
                  config={{
                    rows: {
                      nombre: {
                        headerCell: "Código del Riesgo",
                        cell: (row) => {
                          return <div key={row.id}>{row.codigo}</div>
                        }
                      },
                      descripcion: {
                        headerCell: "Descripción",
                        cell: (row) => {
                          return <SpecialBox text={row?.nombre} />
                        }
                      },
                      riesgo_general: {
                        headerCell: "Riesgos General",
                        cell: (row) => {
                          return (
                            <Label style={{ marginRight: 3, marginBottom: 3, marginLeft: 0 }}>
                              {row?.riesgo_general?.nombre}
                            </Label>
                          )
                        }
                      },
                      riesgo_especifico: {
                        headerCell: "Riesgos Especificos",
                        cell: (row) => {
                          return row?.riesgos_especificos?.map((risk) => (
                            <Label style={{ marginRight: 3, marginBottom: 3, marginLeft: 0 }} key={risk.id}>
                              {risk.nombre}
                            </Label>
                          ))
                        }
                      },
                      factores: {
                        headerCell: "Factores de Riesgo",
                        cell: (row) => {
                          return row?.factores?.map((risk) => (
                            <Label style={{ marginRight: 3, marginBottom: 3, marginLeft: 0 }} key={risk.id}>
                              {risk.nombre}
                            </Label>
                          ))
                        }
                      },

                      objetivos: {
                        headerCell: "Objetivos Organizacionales",
                        cell: (row) => {
                          return row?.objetivos?.map((risk) => (
                            <Label style={{ marginRight: 3, marginBottom: 3, marginLeft: 0 }} key={risk.id}>
                              {risk.nombre}
                            </Label>
                          ))
                        }
                      },

                      acciones: {
                        headerCell: "",
                        cell: (row) => {
                          return <Checkbox checked={row.checked} onCheck={() => checkedOnlyItem(row)} />
                        }
                      }
                    }
                  }}
                />
              </div>
            )}
          </>
        )
    }
  }

  if (dataLibraries === null) {
    return <Loading text='Cargando riesgos...' curtain={false} />
  }

  return (
    <>
      <div className='ui attached segment'>{renderContent()}</div>
    </>
  )
}
