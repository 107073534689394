import React from 'react'
import styles from './style.module.scss'

export default function TableHeader({background='#e6e6e6', children, positionStatic=false}) {
  const positionClassName = positionStatic ? `${styles.positionStatic}` : '' 
  return (
    <div 
      className={positionClassName}
      style={{
        backgroundColor: background,
        display: 'flex',
        flexFlow: 'row wrap',
      }}>
      {children}
    </div>
  )
}