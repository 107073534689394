import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getAuditTeam = authValidationMiddleware(
  async ({ auditId, page = 1, entriesPerPage = null, search = null }) => {
    let response

    if (typeof page === "number" && page > 0) {
      response = await axiosApiInstance.get(
        `/proyecto/riesgo/auditoria/${auditId}/equipo/?page=${page}${
          entriesPerPage ? `&max_per_page=${entriesPerPage}` : ""
        }${search ? `&search=${search}` : ""}`
      )
    } else {
      response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${auditId}/equipo/`)
    }

    return response.data
  }
)

export const getEvaluationById = authValidationMiddleware(async ({ evaluationId }) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/equipo/`)

  return response.data
})

export const getEvaluationTeam = authValidationMiddleware(async ({ evaluationId }) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/equipo/`)

  return response.data
})

export const addMember = authValidationMiddleware(async ({ auditId, member }) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria/${auditId}/equipo/`, {
    ...member
  })

  return response.data
})

export const deleteMember = authValidationMiddleware(async ({ auditId, memberId }) => {
  const response = await axiosApiInstance.delete(`/proyecto/riesgo/auditoria/${auditId}/equipo/${memberId}/`)

  return response.data
})

export const updateMember = authValidationMiddleware(async ({ auditId, memberId, body }) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/auditoria/${auditId}/equipo/${memberId}/`, {
    ...body
  })

  return response.data
})
