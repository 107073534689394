import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const adminAuditSprint = (auditId) => {
  const URL = `/proyecto/riesgo/auditoria/${auditId}/sprint/`

  return {
    URL,
    GET: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.get(URL)
      return response.data
    }),
    POST: authValidationMiddleware(async (payload) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    PUT: authValidationMiddleware(async (id, payload) => {
      const response = await axiosApiInstance.put(URL + id + "/", payload)
      return response.data
    }),
    DELETE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.delete(URL + id + "/")
      return response.data
    }),
    GET_COLL_ACTION: authValidationMiddleware(async (action, params = {}) => {
      const response = await axiosApiInstance.get(URL + action + "/", { params })
      return response.data
    }),
    POST_OBJECT_ACTION: authValidationMiddleware(async (id, action, payload = {}) => {
      const response = await axiosApiInstance.post(URL + id + "/" + action + "/", payload)
      return response.data
    })
  }
}
