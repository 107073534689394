import dynamic from "next/dynamic"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import Loading from "src/components/Loading"
import { createTreeEvaluationItem } from "src/services/treeEvaluation.service"
import BreadCrumbs from "../../components/BreadCrumbs"
import Button from "../../components/Button"
import Dialog from "../../components/Dialog"
import DialogBody from "../../components/Dialog/DialogBody"
import DialogFooter from "../../components/Dialog/DialogFooter"
import DialogHeader from "../../components/Dialog/DialogHeader"
import Editor from "../../components/Editor"
import FormField from "../../components/FormField"
import Tab from "../../components/Tab"
import TabNumber from "../../components/Tab/TabNumber"
import { rowBackendType } from "../../constants/rowTypes"
import ErrorBoundary from "../../errors/ErrorBoundary"
import { getBreadcrumbs } from "../../helpers/breadcrumbs"
import { createTreeItem, createTreeItemFromLibrary } from "../../services/tree.service"
import { calculateNextItemPosition, findInTreeBy } from "../../store/tree/helpers"
import RiskSelectsManagement from "../Risk/RiskSelectsManagemet"
import CreateRiskFromLibrary from "./CreateRiskFromLibrary"
import CreateRiskFromMyLibraryNoWizard from "./CreateRiskFromMyLibraryNoWizard"
import styles from "./style.module.scss"

const CreateRiskFromProject = dynamic(() => import("./CreateRiskFromProject"), {
  loading: () => <Loading curtain={false} text='Cargando componente...' />
})

export default function CreateRisk({
  open = false,
  onClose,
  title = "Crear Riesgo",
  onCreate,
  parentId,
  auditId,
  typeTree = "auditPlan"
}) {
  const [tabSelected, setTabSelected] = useState(0)
  const [disabledButton, setDisabledButton] = useState(true)
  const [code, setCode] = useState("")
  const [description, setDescription] = useState({ html: "", plain: "" })
  const [isSaving, setIsSaving] = useState(false)

  const [riskSelectValues, setRiskSelectValues] = useState({
    generalRisk: null,
    orgObjective: [],
    riskFactor: [],
    specificRisk: []
  })

  const [riskIds, setRiskIds] = useState([])

  const { tree } = useSelector((state) => state)

  const [targetRow, indexes] = findInTreeBy(tree.rows, (item) => item.id == parentId)

  const breadcrumbs = useMemo(() => {
    if (indexes !== null) {
      return getBreadcrumbs(indexes, tree.rows)
    } else {
      return []
    }
  }, [parentId])

  const createNew = async () => {
    if (!description.plain) {
      throw new Error("Ingrese la descripción por favor")
    }

    setDisabledButton(false)
    setIsSaving(true)

    const position = calculateNextItemPosition(targetRow)

    let _res

    switch (typeTree) {
      case "auditPlan":
      case "auditTemplate":
        const res = await createTreeItem({
          auditId,
          item_tipo: rowBackendType.HT_RISK,
          name: description.html,
          _name: description.plain,
          codigo: code,
          parent: parentId,
          riesgos_especificos: riskSelectValues.specificRisk,
          riesgo_general: riskSelectValues.generalRisk,
          factores: riskSelectValues.riskFactor,
          objetivos: riskSelectValues.orgObjective,
          position
        })
        _res = res
        break
      case "riskEvaluation":
        const resRiskEvaluation = await createTreeEvaluationItem({
          evaluationId: auditId,
          item_tipo: rowBackendType.HT_RISK,
          name: description.html,
          _name: description.plain,
          codigo: code,
          parent: parentId,
          riesgos_especificos: riskSelectValues.specificRisk,
          riesgo_general: riskSelectValues.generalRisk,
          factores: riskSelectValues.riskFactor,
          objetivos: riskSelectValues.orgObjective,
          position
        })
        _res = resRiskEvaluation
        break

      default:
        break
    }

    if (!_res) throw "Ha ocurrido un error"

    onCreate(_res, "new")
    setIsSaving(false)
  }

  const createFromMyLibrary = async () => {
    try {
      if (riskIds.length === 0) {
        throw new Error("Seleccione un riesgo por lo menos.")
      }

      setIsSaving(true)

      const position = calculateNextItemPosition(targetRow)

      const { data } = await createTreeItemFromLibrary({
        riskIds,
        parentId,
        copy_from: "my_library",
        auditId,
        model: ["auditPlan", "auditTemplate"].includes(typeTree) ? "auditoria" : "evaluacion",
        position
      })

      if (data.length === 0) {
        toast.error("El riesgo seleccionado ya fue creado")
        return
      }

      onCreate(data, "my_library")
    } catch (error) {
      throw new Error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const createFromLibrary = async () => {
    try {
      if (riskIds.length === 0) {
        throw new Error("Seleccione un riesgo por lo menos.")
      }

      const position = calculateNextItemPosition(targetRow)

      setIsSaving(true)

      const { data } = await createTreeItemFromLibrary({
        riskIds,
        parentId,
        auditId,
        model: ["auditPlan", "auditTemplate"].includes(typeTree) ? "auditoria" : "evaluacion",
        position
      })

      if (data.length === 0) {
        toast.error("El riesgo seleccionado ya fue creado")
        return
      }

      onCreate(data, "library")
    } catch (error) {
      throw new Error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const onClickHandler = () => {
    switch (tabSelected) {
      case 0:
        createFromLibrary()
        break
      case 1:
        createFromMyLibrary()
        break
      case 3:
        createNew()
        break
    }
  }

  useEffect(() => {
    setCode("")
    setDescription({ html: "", plain: "" })
    setRiskIds([])
    setDisabledButton(true)
    setTabSelected(0)
    setRiskSelectValues({ generalRisk: null, orgObjective: [], riskFactor: [], specificRisk: [] })
  }, [open])

  useEffect(() => {
    if (open) {
      if (tabSelected === 3) {
        setDisabledButton(false)
      } else {
        if (riskIds.length === 0) {
          setDisabledButton(true)
        } else {
          setDisabledButton(false)
        }
      }
    }
  }, [tabSelected])

  const onCheckHandler = (el) => {
    el.checked = !el.checked

    if (el.checked) {
      setRiskIds((last) => {
        setDisabledButton(false)
        return [...last, el.id]
      })
    } else {
      setRiskIds((last) => {
        const updatedList = [...last]

        const _index = updatedList.findIndex((_id) => _id === el.id)

        updatedList.splice(_index, 1)

        setDisabledButton(updatedList.length == 0)

        return updatedList
      })
    }
  }

  const onSelectRisksToCopy = ({ ids, copy_from, copyOptions }) => {
    if (ids.length === 0) {
      throw new Error("Seleccione un riesgo por lo menos.")
    }

    setIsSaving(true)

    createTreeItemFromLibrary({
      riskIds: ids,
      parentId,
      auditId,
      model: ["auditPlan", "auditTemplate"].includes(typeTree) ? "auditoria" : "evaluacion",
      copy_from,
      copy_options: copyOptions
    })
      .then(({ data }) => {
        onCreate(data, "project")
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <ErrorBoundary>
      <Dialog
        isOpen={open}
        style={{
          width: "80vw",
          height: "80vh",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <DialogHeader background='#0a858d' color='#e6e6e6' align='center' buttonClose={true} handleClose={onClose}>
          <h4>{title}</h4>
        </DialogHeader>
        <DialogBody
          background='#fff'
          color='#424242'
          align='left'
          style={{ overflow: "auto", flex: 1, padding: "0 14px" }}
        >
          <BreadCrumbs list={breadcrumbs} link={false} />
          <Tab currentTab={(tab) => setTabSelected(tab)} style={{ height: "calc(100% - 40px)" }}>
            <TabNumber headerText='Desde biblioteca AuditBrain'>
              <CreateRiskFromLibrary onCheck={onCheckHandler} />
            </TabNumber>
            <TabNumber headerText='Desde mi Biblioteca'>
              <CreateRiskFromMyLibraryNoWizard onCheck={onCheckHandler} />
            </TabNumber>
            <TabNumber headerText='Desde Otro Proyecto'>
              <CreateRiskFromProject typeTree={typeTree} onSelect={onSelectRisksToCopy} />
            </TabNumber>
            <TabNumber headerText='Crear nuevo'>
              <div style={{ display: "grid", rowGap: 20 }}>
                <p>Diligencie los siguientes campos para crear un nuevo riesgo</p>
                <div
                  style={{
                    display: "flex",
                    gap: 100
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "max-content max-content",
                      columnGap: 15,
                      alignItems: "center"
                    }}
                  >
                    <h3 className={styles.subTitle}>ID del Riesgo:</h3>
                    <FormField inputWrapperStyle={{ width: 160 }}>
                      <input value={code} onChange={(event) => setCode(event.target.value)} maxLength={15} />
                    </FormField>
                  </div>
                </div>
                <div>
                  <h3 className={styles.subTitle} style={{ marginBottom: 10 }}>
                    Descripción del Riesgo:
                  </h3>
                  <div style={{ height: 120 }}>
                    <Editor mode='basic' onDataChange={(obj) => setDescription(obj)} />
                  </div>
                </div>
                <RiskSelectsManagement
                  config={{ mode: "CREATE" }}
                  onChange={(values) => {
                    setRiskSelectValues(values)
                  }}
                  auditId={auditId}
                />
              </div>
            </TabNumber>
          </Tab>
        </DialogBody>

        {tabSelected != 2 && (
          <DialogFooter background='#fff' align='right'>
            <Button text='Cancelar' name='warning' type='big' handleClick={onClose} />
            <Button
              text='Crear Riesgo'
              name='normal'
              type='big'
              loading={isSaving ? "Guardando" : false}
              handleClick={onClickHandler}
              disabled={disabledButton}
            />
          </DialogFooter>
        )}
      </Dialog>
      {isSaving && <Loading text='Creando riesgos...' />}
    </ErrorBoundary>
  )
}
