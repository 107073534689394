export const executionValidation = Object.freeze({
  INEFFECTIVE: {
    value: 1,
    color: '#ed4138',
    label: 'Inefectivo'
  },
  DEFICIENT: {
    value: 3,
    color: '#ffe543',
    label: 'Deficiente'
  },
  EFFECTIVE: {
    value: 5,
    color: '#00a750',
    label: 'Efectivo'
  }
})
