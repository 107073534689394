export const colorsMap = Object.freeze({
  1: "#00a750",
  2: "#56aaff",
  3: "#ffe543",
  4: "#ffae39",
  5: "#ed4138"
})

export const inherentRisk = Object.freeze({
  LOW: {
    id: 2,
    color: "#00a750",
    label: "Bajo"
  },
  MEDIUM: {
    id: 3,
    color: "#ffe543",
    label: "Medio"
  },
  HIGH: {
    id: 4,
    color: "#ffae39",
    label: "Alto"
  },
  VERY_HIGH: {
    id: 5,
    color: "#ed4138",
    label: "Muy\nAlto"
  }
})

export const residualRisk = Object.freeze({
  LOW: {
    id: 2,
    color: "#00a750",
    label: "Bajo"
  },
  MEDIUM: {
    id: 3,
    color: "#ffe543",
    label: "Medio"
  },
  HIGH: {
    id: 4,
    color: "#ffae39",
    label: "Alto"
  },
  VERY_HIGH: {
    id: 5,
    color: "#ed4138",
    label: "Muy\nAlto"
  }
})

export const impact = Object.freeze({
  VERY_LOW: {
    value: 1,
    color: "#00a750",
    label: "Muy\nBajo"
  },
  LOW: {
    value: 2,
    color: "#56aaff",
    label: "Bajo"
  },
  MEDIUM: {
    value: 3,
    color: "#ffe543",
    label: "Medio"
  },
  HIGH: {
    value: 4,
    color: "#ffae39",
    label: "Alto"
  },
  VERY_HIGH: {
    value: 5,
    color: "#ed4138",
    label: "Muy\nAlto"
  }
})

export const probability = Object.freeze({
  VERY_LOW: {
    value: 1,
    color: "#00a750",
    label: "Muy\nBajo"
  },
  LOW: {
    value: 2,
    color: "#56aaff",
    label: "Bajo"
  },
  MEDIUM: {
    value: 3,
    color: "#ffe543",
    label: "Medio"
  },
  HIGH: {
    value: 4,
    color: "#ffae39",
    label: "Alto"
  },
  VERY_HIGH: {
    value: 5,
    color: "#ed4138",
    label: "Muy\nAlto"
  }
})

export const riskEvaluation = Object.freeze({
  OPENED: {
    value: "ABIERTO",
    color: "#ffe543",
    label: "Abierto"
  },
  CLOSED: {
    value: "CERRADO",
    color: "#00a750",
    label: "Cerrado"
  },
  NULLED: {
    value: "ANULADO",
    color: "#ed4138",
    label: "Anulado"
  },
  DELETED: {
    value: "ELIMINADO",
    color: "#56aaff",
    label: "Eliminado"
  }
})

export const controlSolidity = Object.freeze({
  WEAK: {
    id: 1,
    color: "#ed4138",
    label: "Débil"
  },
  MODERATE: {
    id: 3,
    color: "#ffe543",
    label: "Moderado"
  },
  STRONG: {
    id: 5,
    color: "#00a750",
    label: "Fuerte"
  }
})
