import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { statusType, typeWorkSheets } from "src/constants/status"
import useAdvancedFilters from "src/hooks/useAdvancedFilters"
import { getAuditTeam } from "src/services/auditTeam.service"

export const useAdvancedFiltersAudit = (typeTree = "auditPlan") => {
  const router = useRouter()
  const auditId = router.query["auditoria-id"]
  const { tree: treeStateRedux } = useSelector((state) => state)

  const { t } = useTranslation()

  const [users, setUsers] = useState([])

  const status = () => {
    return statusType.flat()
  }

  const AdvancedFilters = useAdvancedFilters({
    fields:
      typeTree === "auditPlan"
        ? [
            {
              label: "frontend.auditoria.fields.user_assigned",
              componentType: "select",
              choices: users,
              name: "usuario_asignado",
              add_empty: true
            },
            {
              label: "frontend.auditoria.treeitem.fields.status",
              componentType: "select",
              choices: status(),
              name: "status",
              add_empty: true
            },
            {
              label: "frontend.auditoria.treeitem.type__name__",
              componentType: "select",
              choices: typeWorkSheets(t),
              name: "item_tipo",
              add_empty: true
            }
          ]
        : [
            {
              label: "frontend.auditoria.treeitem.fields.status",
              componentType: "select",
              choices: status(),
              name: "status",
              add_empty: true
            }
          ]
  })

  const removeFilters = () => {
    AdvancedFilters.setValues({})
    AdvancedFilters.setOpen(false)
  }

  const getUsers = useCallback(async () => {
    const { data } = await getAuditTeam({ auditId })

    const noUser = { id: "sin_asignar", name: "-Sin asignar-" }

    const usersFormat = data?.records?.map((user) => ({
      id: user.usuario.id,
      name: `${user.usuario.name} (${user.usuario.sigla})`
    }))

    setUsers([noUser].concat(usersFormat))
  }, [auditId])

  useEffect(() => {
    if (typeTree === "auditPlan") {
      getUsers()
    }
  }, [])

  return {
    Button: AdvancedFilters.Button,
    Panel: AdvancedFilters.Panel,
    removeFilters,
    values: {
      ...AdvancedFilters.values
    },
    treeState: treeStateRedux
  }
}
