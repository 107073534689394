export const designEvaluation = Object.freeze({
  NONE: {
    id: 0,
    color: "#DDDDDD",
    label: "Inexistente"
  },
  WEAK: {
    id: 1,
    color: "#ed4138",
    label: "Débil"
  },
  MODERATE: {
    id: 3,
    color: "#ffe543",
    label: "Moderado"
  },
  STRONG: {
    id: 5,
    color: "#00a750",
    label: "Fuerte"
  }
})

export const solidity = Object.freeze({
  INEFFECTIVE: {
    id: 1,
    color: "#ed4138",
    label: "Débil"
  },
  DEFICIENT: {
    id: 3,
    color: "#ffe543",
    label: "Moderado"
  },
  STRONG: {
    id: 5,
    color: "#00a750",
    label: "Fuerte"
  }
})
