import { cloneDeep } from "lodash"
import dynamic from "next/dynamic"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import Loading from "src/components/Loading"
import BreadCrumbs from "../../components/BreadCrumbs"
import Button from "../../components/Button"
import Dialog from "../../components/Dialog"
import Editor from "../../components/Editor"
import FormField from "../../components/FormField"
import Tab from "../../components/Tab"
import TabNumber from "../../components/Tab/TabNumber"
import { getBreadcrumbs } from "../../helpers/breadcrumbs"
import { calculateNextItemPosition, findInTreeBy } from "../../store/tree/helpers"
import styles from "./style.module.scss"

const CopyExistingControl = dynamic(() => import("./CopyExistingControl"), {
  loading: () => <Loading centered={false} />
})

export default function CreateControl({
  onClose,
  title = "Crear Control",
  onCreate,
  createService,
  open = false,
  parentId = null,
  auditId
}) {
  const [isOpen, setIsOpen] = useState(open)
  const [tabSelected, setTabSelected] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState("")
  const [description, setDescription] = useState({ html: "", plain: "" })
  const [copyInfo, setCopyInfo] = useState({ type: null, ids: [] })

  const { tree } = useSelector((state) => state)

  const [targetRow, indexes] = findInTreeBy(tree.rows, (item) => item.id == parentId)

  const breadcrumbs = useMemo(() => {
    if (indexes !== null) {
      return getBreadcrumbs(cloneDeep(indexes), tree.rows)
    } else {
      return []
    }
  }, [indexes])

  const onClickHandler = () => {
    createControlHandler()
  }

  const createControlHandler = async () => {
    try {
      setIsLoading(true)

      const position = calculateNextItemPosition(targetRow)

      let createServiceData = {}
      switch (tabSelected) {
        case 0:
          createServiceData = { create_type: "new", codigo: code, ...description, position }
          break
        case 1:
          createServiceData = { create_type: "copy", ...copyInfo, position }
          break
      }

      const res = await createService(createServiceData)

      onCreate(res)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsOpen(open)
    setCode("")
    setDescription({ html: "", plain: "" })
    setTabSelected(0)
  }, [open])

  const disabledSubmitText =
    tabSelected == 0
      ? description.plain.length === 0
        ? "Ingresa la descripción del control"
        : null
      : copyInfo.ids.length == 0
      ? "Seleccione por lo menos un control a copiar"
      : null

  if (!isOpen) {
    return null
  }

  return (
    <Dialog isOpen isLoading={isLoading} style={{ width: "80vw", display: "flex", flexDirection: "column" }}>
      <Dialog.Header background='#0a858d' color='#e6e6e6' align='center' buttonClose handleClose={onClose}>
        <h4>{title}</h4>
      </Dialog.Header>
      <Dialog.Body background='#fff' color='#424242' align='left' style={{ overflow: "auto", flex: 1 }}>
        <BreadCrumbs list={breadcrumbs} link={false} />
        <Tab currentTab={(tab) => setTabSelected(tab)} initialTab={0}>
          <TabNumber headerText='Crear nuevo'>
            <div className={styles.containerNew}>
              <p>Diligencie los siguientes campos para crear un nuevo control: </p>
              <div>
                <FormField label='Código del Control' positionLabel='left' inputWrapperStyle={{ width: 160 }}>
                  <input value={code} onChange={(event) => setCode(event.target.value)} maxLength={15} />
                </FormField>
              </div>
              <div style={{ marginBottom: 40 }}>
                <label className={styles.subTitle} style={{ marginBottom: 10, display: "block" }}>
                  Descripción del Control: <span style={{ color: "#ed4138" }}>*</span>
                </label>
                <Editor mode='basic' onDataChange={(obj) => setDescription(obj)} height={80} />
              </div>
            </div>
          </TabNumber>
          <TabNumber headerText='Copiar Existente'>
            <CopyExistingControl auditId={auditId} onSelect={(v) => setCopyInfo(v)} />
          </TabNumber>
        </Tab>
      </Dialog.Body>
      <Dialog.Footer background='#fff' align='right'>
        <Button text='Cancelar' name='warning' type='big' handleClick={onClose} />

        <Button
          text='Aceptar'
          name='normal'
          type='big'
          handleClick={onClickHandler}
          disabledText={disabledSubmitText}
          loading={isLoading}
        />
      </Dialog.Footer>
    </Dialog>
  )
}
