import cx from "classnames"
import React, { useState } from "react"
import Button from "src/components/Button"
import useTranslation from "src/hooks/useTranslation"
import styles from "./ButtonSwitch.module.scss"

function WizardContentButton({ button, selected, design = "default", onSelect }) {
  const { t } = useTranslation()

  const checkDisabled = () => {
    if (typeof button.disabled === "undefined" || button.disabled === null) {
      return false
    }

    let disabledText = button.disabled
    if (typeof disabledText == "object") {
      const f = Object.entries(disabledText).find(([text, condition]) => condition)
      disabledText = f ? f[0] : null
      if (disabledText && typeof disabledText === "string") {
        disabledText = t(disabledText, disabledText)
      }
    }

    return disabledText
  }
  const isDisabled = checkDisabled()

  const renderContent = () => {
    switch (design) {
      case "basic":
        return (
          <div>
            <Button
              className={cx("ui button", button.className ?? (isDisabled ? "" : "green"), {
                active: selected,
                disabled: isDisabled
              })}
              disabledText={isDisabled}
              onClick={() => onSelect(button.value)}
              style={{ display: "inline-block" }}
              text={button.label}
            />
          </div>
        )
      default:
        return (
          <>
            <div className='content'>
              <div className={cx("ui header", styles.header, button.description && styles.withDescription)}>
                {React.isValidElement(button.label) ? button.label : t(button.label)}
                {button.label_suffix && <div>{t(button.label_suffix)}</div>}
              </div>
              {button.description && (
                <div className={cx("description", styles.description)}>
                  {React.isValidElement(button.description) ? (
                    <>
                      <i className='icon question circle' /> {button.description}
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<i class='icon question circle'></i> ${t(button.description)}`
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className='extra content'>
              <Button
                className={cx("ui button", button.className ?? (isDisabled ? "" : "green"), {
                  active: selected,
                  disabled: isDisabled
                })}
                disabledText={isDisabled}
                onClick={() => onSelect(button.value)}
                style={{ display: "inline-block" }}
                text={"btn_select"}
              />
            </div>
          </>
        )
    }
  }

  return (
    <div className={cx("ui card", styles.button, { "active primary": !isDisabled && selected, disabled: isDisabled })}>
      {renderContent()}
    </div>
  )
}
export default function WizardContentButtonSwitch({ buttons, onSelect, design = "default", defaultValue = null }) {
  const [value, setValue] = useState(defaultValue)

  const selectButton = (v) => {
    setValue(v)
    onSelect(v)
  }

  return (
    <div className='ui segment basic'>
      <div className={cx("ui cards", styles.wrapper, styles[design])}>
        {buttons.map((button, idx) => (
          <React.Fragment key={button.value}>
            <WizardContentButton
              button={button}
              selected={button.value == value}
              onSelect={selectButton}
              design={design}
            />
            {buttons.length == 2 && idx < buttons.length - 1 && (
              <div className='ui divider vertical' style={{ left: "calc(50% - 10px)" }}>
                O
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
