import React from 'react'
import Icon from '../../../Icon'
import styles from './style.module.scss'

export default function SideModalCloser({
  onClose = () => {},
  config = { color: null, textColor: null, text: null, isLoading: false },
  onTextClick = () => {}
}) {
  let { color, textColor, text, isLoading } = config
  let newText = []
  if ( text?.props ) {
    text = text.props.children
    newText = typeof(text) === 'string' ? text.split(" ") : text
    newText = newText.filter(t => t !== undefined)
  }

  return isLoading ? null : (
    <div
      className={styles.closer}
      style={{ backgroundColor: color, color: textColor ?? '#FFF' }}
    >
      <div className={styles.closerIcon} onClick={onClose}>
        <Icon name='close' color={textColor ?? '#FFF'} />
        <div className={styles.closerIconFocusOverlay}></div>
      </div>
      {text && (
        <div onClick={onTextClick} className={styles.closerText}>
          {text}
        </div>
      )}
    </div>
  )
}
