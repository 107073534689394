import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getLibrariesRisk = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get(`/proyecto/biblioteca_riesgo/`)

  return response.data
})

export const actionsLibrayRiskFromProject = () => {
  const endPoint = "/proyecto/riesgo_base/riesgos_base_tree/"
  return {
    CREATE: authValidationMiddleware(async (data) => {
      const response = await axiosApiInstance.post(endPoint, {
        ...data
      })

      return response.data
    })
  }
}

export const actionsRiskAttachLibrary = () => {
  const endPoint = "/proyecto/riesgo_base/vincular_riesgo/"
  return {
    CREATE: authValidationMiddleware(async (data) => {
      const response = await axiosApiInstance.post(endPoint, {
        ...data
      })

      return response.data
    })
  }
}

export const actionsLibrayRisk = () => {
  const endPoint = "/proyecto/riesgo_base/"

  return {
    GET: authValidationMiddleware(async (params) => {
      const response = await axiosApiInstance.get(endPoint, { params })

      return response.data
    }),

    CREATE: authValidationMiddleware(async (data) => {
      const response = await axiosApiInstance.post(endPoint, {
        ...data
      })

      return response.data
    }),

    UPDATE: authValidationMiddleware(async ({ data, id }) => {
      const response = await axiosApiInstance.put(`${endPoint}${id}/`, {
        ...data
      })

      return response.data
    }),

    DELETE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.delete(`${endPoint}${id}/`)

      return response.data
    })
  }
}
