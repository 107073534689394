import React, { useContext } from "react"
import { UserContext } from "../../../../UserContext"

export default function TableColumn({
  align = "left",
  color = "#808080",
  children,
  padding = 8,
  width = "",
  style = {},
  ...attrs
}) {
  const { childrenSize } = useContext(UserContext)
  return (
    <div
      style={{
        width: width === "" ? `calc(100% / ${childrenSize})` : `${width}%`,
        color: color,
        borderBottom: "1px solid #e6e6e6",
        borderRight: "1px solid #e6e6e6",
        padding: padding,
        textAlign: align,
        wordBreak: "break-word",
        ...style
      }}
      {...attrs}
    >
      {children}
    </div>
  )
}
