import { designEvaluation, solidity } from "../constants/control"

export const mapDesignControl = (id) => {
  switch (id) {
    case designEvaluation.WEAK.id:
      return designEvaluation.WEAK
    case designEvaluation.MODERATE.id:
      return designEvaluation.MODERATE
    case designEvaluation.STRONG.id:
      return designEvaluation.STRONG
    default:
      return null
  }
}

export const mapSolidity = (id) => {
  switch (id) {
    case solidity.INEFFECTIVE.id:
      return solidity.INEFFECTIVE
    case solidity.DEFICIENT.id:
      return solidity.DEFICIENT
    case solidity.STRONG.id:
      return solidity.STRONG
    default:
      return null
  }
}

export const formatControlServiceCreateParams = (auditId, parentId, { create_type, ...params }) => {
  const createData = {
    create_type,
    auditId,
    item_tipo: "HT_CONTROL",
    parent: parentId,
    position: params.position
  }

  switch (create_type) {
    case "new":
      return { ...createData, name: params.html, codigo: params.codigo }
    case "copy":
      return { ...createData, ...params }
  }
}
