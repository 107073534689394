import { useTranslation } from "react-i18next"
import { Popup } from "semantic-ui-react"
import Checkbox from "src/components/FormField/Checkbox"
import Icon from "src/components/Icon"
import RadioButtonAlone from "src/components/RadioGroup/RadioButtonAlone"
import SpecialBox from "src/components/SpecialBox"
import { tooltipPosition } from "src/components/Tooltip"
import Truncate from "src/components/Truncate"
import { rowBackendType } from "src/constants/rowTypes"
import { checkTypeElements } from "src/helpers/rowTypes"
import { getTreeitemConfig } from "src/helpers/tree"
import { getFrontendType } from "src/helpers/typeFormatter"
import { rowLabelIconColor } from "src/macrocomponents/Tree"
import styles from "./style.module.scss"

export default function TreeRowSelectable({
  data,
  onArrowClick = () => {},
  onCheck = () => {},
  open,
  checked,
  disabledText = null,
  disabledHeaderText = "main_ui.general.lb_disabled_option_title",
  rightComponent = true,
  checkable = true,
  multiple = true
}) {
  const { t } = useTranslation()

  const barColor = () => {
    let color

    switch (data.item_tipo) {
      case rowBackendType.GR_PHASE:
        color = "#a8cf45"
        break
      case rowBackendType.GR_BUSINESS_UNIT:
        color = "#662e93"
        break
      case rowBackendType.GR_MACROPROCESS:
        color = "#ffe543"
        break
      case rowBackendType.GR_PROCESS:
        color = "#ffae39"
        break
      case rowBackendType.GR_ACTIVITY:
        color = "#56aaff"
        break
    }
    return color
  }

  if (disabledText && typeof disabledText == "object") {
    const f = Object.entries(disabledText).find(([text, condition]) => condition)
    disabledText = f ? f[0] : null
    if (disabledText && typeof disabledText === "string") {
      disabledText = t(disabledText, disabledText)
    }
  }
  if (disabledText === false) {
    disabledText = null
  }

  const renderBar = (_color) => {
    if (!_color) return null

    return <div style={{ backgroundColor: _color, width: 10, height: "100%" }} />
  }

  const renderIcon = () => {
    if (data.icon === false) {
      return null
    }

    const icon = getTreeitemConfig(data, "icon")
    if (icon === false) {
      return null
    }

    const data_type = getFrontendType(data)
    const _color = rowLabelIconColor(data_type, data.statuses, data)

    return <Icon name={`tree/${data_type}`} size={16} color={_color} />
  }

  const renderArrow = () => {
    return data?.items?.length > 0 && !(data.noArrow ?? false) ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          transform: open ? "rotateZ(0deg)" : "rotateZ(-90deg)"
        }}
        onClick={onArrowClick}
      >
        <Icon name='arrow' size={12} />
      </div>
    ) : (
      <div style={{ width: 12 }} />
    )
  }

  const renderRadioButton = (itemTipo) => {
    const { isTypeWorksheet } = checkTypeElements(itemTipo)
    return isTypeWorksheet && <RadioButtonAlone color='#0a858d' onClick={onCheck} selected={checked} />
  }

  const renderCheck = () => {
    if (!checkable) {
      return null
    }

    return multiple ? (
      <Checkbox disabled={disabledText !== null} checked={checked} onCheck={onCheck} />
    ) : (
      renderRadioButton(data.item_tipo)
    )
  }

  const renderRightComponent = () => {
    if (rightComponent === true) {
      return data?.usuario_asignado?.sigla
    }
    return rightComponent ? rightComponent : null
  }

  const checkIsItemRowType = (item, types) => {
    return (
      types.includes(item.item_tipo) || (item.item_tipo == "HT_REF" && types.includes(item.refitem_details?.item_tipo))
    )
  }

  const renderContent = () => {
    const _color = barColor()
    const { isTypeWorksheet } = checkTypeElements(data.item_tipo)

    const renderLabel = (removeLinebreak = true) => {
      let label = ""
      if (
        isTypeWorksheet &&
        checkIsItemRowType(data, [rowBackendType.HT_RISK, rowBackendType.HT_CONTROL, rowBackendType.HT_HALLAZGO])
      ) {
        label = data.codigo ? `${data.codigo} - ` : ""
      }
      return <SpecialBox text={`${label} ${data.nombre}`} removeLinebreak={removeLinebreak} innerHtml={false} />
    }

    const component = (
      <div className={disabledText === null ? styles.active : styles.disabled}>
        {renderBar(_color)}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            padding: "6px 9px",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              flex: 1,
              alignItems: "center",
              display: "flex",
              gap: 6,
              overflow: "hidden"
            }}
          >
            {renderIcon()}
            <div className={styles.name}>
              <Truncate
                position={tooltipPosition.belowRight}
                maxWidth='90%'
                style={{ maxWidth: 550 }}
                innerHtml={false}
                content={renderLabel(false)}
              >
                <div style={{ display: "flex", gap: 3 }}>{renderLabel()}</div>
              </Truncate>
            </div>
          </div>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            {renderRightComponent()}
            {renderCheck()}
          </div>
        </div>
      </div>
    )

    return disabledText === null ? (
      component
    ) : (
      <Popup
        position='top right'
        content={disabledText}
        header={t(disabledHeaderText, disabledHeaderText)}
        className='disabled-op'
        size='small'
        disabled={disabledText === null}
        trigger={component}
      />
    )
  }

  return (
    <div
      style={{
        display: "flex",
        height: 41,
        gap: 5,
        margin: "5px 0"
      }}
    >
      {renderArrow()}
      {renderContent()}
    </div>
  )
}
