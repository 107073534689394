import React, { useEffect, useRef, useState } from "react"
import useAccessControl from "src/hooks/useAccessControl"
import Portal from "../Portal"
import styles from "./style.module.scss"

export default function Menu({ children, content, permission = null }) {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef(null)
  const requestRef = useRef(null)
  const [coords, setCoords] = useState({})
  const { hasPermission } = useAccessControl()

  const updateCoords = () => {
    const rect = ref.current?.getBoundingClientRect()

    if (!rect) {
      return
    }

    setCoords({
      left: rect.x,
      top: rect.bottom - 3
    })
    requestRef.current = requestAnimationFrame(updateCoords)
  }

  const mouseEnterHandler = (e) => {
    setShowMenu(true)
    requestAnimationFrame(updateCoords)
  }
  const mouseLeaveHandler = (event) => {
    cancelAnimationFrame(requestRef.current)
    setShowMenu(false)
  }

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  if (permission && !hasPermission(permission)) {
    return null
  }

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={mouseEnterHandler}
        style={{
          cursor: "pointer",
          display: "flex",
          maxWidth: "fit-content"
        }}
        onMouseLeave={mouseLeaveHandler}
      >
        {children}
        {showMenu && (
          <Portal>
            <div
              className={styles.menu}
              style={{
                position: "absolute",
                ...coords,
                maxWidth: 400,
                pointerEvents: "auto",
                zIndex: 1
              }}
            >
              {content}
            </div>
          </Portal>
        )}
      </div>
    </>
  )
}
