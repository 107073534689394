import React from 'react'
import styles from './style.module.scss'

export default function GanttHeaderItem(props) {
  const { children, ...otherProps } = props

  return (
    <div className={`${styles.ganttHeaderItem}`} {...otherProps}>
      {children}
    </div>
  )
}
