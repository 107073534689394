import { useEffect, useState } from "react"
import Icon from "../../components/Icon"
import Tooltip from "../Tooltip"
import styles from "./style.module.scss"

export default function InputSearch({
  align = "left",
  style = {},
  initialValue = "",
  onValueChange = null,
  onKeyDown = () => {},
  textTooltip = "",
  icon = true,
  name = "search",
  onInputError = false
}) {
  const color = "#e6e6e6"
  const size = 22
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    setValue(ev.target.value)

    if (typeof onValueChange === "function") {
      onValueChange(ev.target.value)
    }
  }

  const renderBody = () => (
    <input
      className={`${styles.search} ${onInputError ? "input_error" : ""}`}
      style={{ paddingLeft: icon ? 29 : 8 }}
      value={value}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      type='search'
    />
  )

  return (
    <div style={{ textAlign: align, position: "relative", ...style }}>
      {icon && (
        <div className={styles.boxIcon}>
          <Icon name={name} size={size} color={color} />
        </div>
      )}
      {textTooltip !== "" ? <Tooltip content={<h3>{textTooltip}</h3>}>{renderBody()}</Tooltip> : renderBody()}
    </div>
  )
}
