import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function NoData({ text = "", style = {} }) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100%"
      }}
    >
      <div
        style={{
          padding: 20,
          textTransform: "uppercase",
          color: "var(--primary-dark-grey)",
          fontSize: "larger",
          ...style
        }}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 10 }} /> {text}
      </div>
    </div>
  )
}

export function NoDataAlert({ text, icon = null, style = {} }) {
  return (
    <div
      style={{
        textAlign: "center",
        padding: 20,
        borderRadius: 5,
        backgroundColor: "#fff8ed",
        color: "white",
        border: "2px solid #efd9ba",
        marginTop: 20,
        display: "flex",
        flexDirection: "column"
      }}
    >
      {icon && (
        <div>
          <i className={"ui icon huge " + icon} style={{ color: "#ebe6e0", marginBottom: 10 }} />
        </div>
      )}
      <NoData text={text} style={{ color: "#b97615", ...style }} />
    </div>
  )
}

export function WarningText({ text, inline = true, iconSize = null, style = {} }) {
  return (
    <div
      style={{
        color: "var(--accent-orange)",
        padding: "10px 20px",
        fontWeight: 600,
        borderRadius: 5,
        textAlign: "center",
        margin: "auto",
        ...style
      }}
    >
      {inline ? (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} size={iconSize} />
          <span style={{ display: "inline-block", marginLeft: 8 }}>{text}</span>
        </>
      ) : (
        <>
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} size={iconSize ?? "5x"} />
          </div>
          <br />
          {text}
        </>
      )}
    </div>
  )
}
