import dynamic from "next/dynamic"
import { DeleteItemAction } from "src/components/DeleteItemAction"
import Loading from "src/components/Loading"
import { getTreeitemType } from "src/helpers/tree"

const DeleteControlAction = dynamic(() => import("./Delete/DeleteControlAction"), {
  loading: () => <Loading centered={false} text='Cargando Componente' />
})

export default function DeleteTreeItemAction({ item, onDelete }) {
  const treetype = getTreeitemType(item).substr(3).toLowerCase()

  const handleDelete = () => {
    onDelete(item)
  }

  if (["HT_CONTROL"].includes(item.item_tipo)) {
    return <DeleteControlAction item={item} onDelete={onDelete} />
  }

  return (
    <DeleteItemAction
      onDelete={handleDelete}
      confirmTooltip={{
        key: "main_ui.general.delete_action",
        action: `$t(frontend.auditoria.treeitem.${treetype})`
      }}
      confirmText={{
        key: "main_ui.general.delete_action_confirmation",
        action: `$t(frontend.auditoria.treeitem.${treetype})`
      }}
    />
  )
}
