import cx from "classnames"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Step } from "semantic-ui-react"
import BreadCrumbs from "src/components/BreadCrumbs"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import FormField from "src/components/FormField"
import InputSearch from "src/components/InputSearch"
import Tab from "src/components/Tab"
import WizardContentButtonSwitch from "src/components/Wizard/ButtonSwitch"
import { getTreeitemConfig, getTreeitemShortName } from "src/helpers/tree"
import useTranslation from "src/hooks/useTranslation"
import { findInTreeBy } from "src/store/tree/helpers"
import { ProjectTreeWithSprint } from "../ProjectTree"
import SelectProjectForm from "../SelectProjectForm"
import style from "./style.module.scss"

const FormNew = ({ value, setValue, handleSave }) => {
  const { t } = useTranslation()

  return (
    <div style={{ padding: "10px 2px" }}>
      <FormField label={t("frontend.default.name")} required inputWrapperStyle={{ padding: 2 }}>
        <InputSearch
          icon={false}
          initialValue={value}
          onValueChange={(v) => {
            setValue(v)
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              handleSave()
            }
          }}
        />
      </FormField>
    </div>
  )
}

const FormCopy = ({ itemTipo, setValue, handleSave }) => {
  const [currentStep, setCurrentStep] = useState(1)

  const [projectType, setProjectType] = useState(null)
  const [selectedProjectInfo, setSelectedProjectInfo] = useState(null)
  const [selectedIds, setSelectedIds] = useState(null)

  const { t } = useTranslation()

  const STEPS = [
    { label: "choose_projecttype_to_copy__title" },
    { label: "choose_project_to_copy__title" },
    { label: "choose_treeitem_to_copy__title" }
  ]

  const selectProjectType = (v) => {
    setProjectType(v)
    setCurrentStep(2)
  }

  const getItemTipoParents = (item_tipo) => {
    const parents = [item_tipo]
    return parents.concat(getTreeitemConfig(item_tipo, "parents", []))
  }

  const filterSelectableRow = (row) => {
    row.checkable = row.item_tipo == itemTipo

    return row
  }

  const handleSelectId = (v) => {
    setSelectedIds(v)
    setValue({ value: v, copy_from: projectType })
  }

  const checkProjectTypeSupports = (projectType) => {
    const supports = getTreeitemConfig(itemTipo, "projectTypeSupports", "auditoria")
    return supports === true || supports === projectType
  }

  const gotoStep = (step) => () => {
    if (currentStep > step) {
      setCurrentStep(step)
    }
  }

  const gotoPrev = () => {
    return setCurrentStep(currentStep - 1)
  }

  const gotoNext = () => {
    return setCurrentStep(currentStep + 1)
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='ui center aligned basic segment' style={{ padding: 10 }}>
            <p style={{ marginBottom: 10 }}>
              {t("frontend.auditoria.treeitem.actions.addchildren.choose_projecttype_to_copy__description")}
            </p>
            <WizardContentButtonSwitch
              design='mini'
              buttons={[
                {
                  label:
                    "frontend.auditoria.treeitem.actions.addchildren.choose_projecttype_to_copy_choices.evaluacion",
                  value: "evaluacion",
                  disabled:
                    !checkProjectTypeSupports("evaluacion") &&
                    t("frontend.auditoria.treeitem.lb_disabled_for_this_worksheet")
                },
                {
                  label: "frontend.auditoria.treeitem.actions.addchildren.choose_projecttype_to_copy_choices.auditoria",
                  value: "auditoria",
                  disabled:
                    !checkProjectTypeSupports("auditoria") &&
                    t("frontend.auditoria.treeitem.lb_disabled_for_this_worksheet")
                }
              ]}
              defaultValue={projectType}
              onSelect={selectProjectType}
            />
          </div>
        )

      case 2:
        return (
          <div>
            <p style={{ textAlign: "center" }}>
              {t("frontend.auditoria.treeitem.actions.addchildren.choose_project_to_copy__description")}
            </p>
            <SelectProjectForm
              projectType={projectType}
              value={selectedProjectInfo}
              onSelect={(v) => {
                setSelectedProjectInfo(v)
                if (v?.id) {
                  gotoStep(3)
                }
              }}
            />
            <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
              <button onClick={gotoPrev} className={cx("ui button")} style={{ marginRight: 20 }}>
                {t("main_ui.general.btn_prev")}
              </button>
              <Button
                disabled={!selectedProjectInfo?.id}
                className='ui primary button'
                handleClick={gotoNext}
                text={"btn_next"}
              />
            </div>
          </div>
        )

      case 3:
        return (
          <>
            <p style={{ marginBottom: 5, textAlign: "left" }}>
              {t("frontend.auditoria.treeitem.actions.addchildren.choose_treeitem_to_copy__description")}
            </p>
            <ProjectTreeWithSprint
              projectType={projectType}
              projectId={selectedProjectInfo?.id}
              defaultValue={selectedIds}
              onSelect={handleSelectId}
              filterRow={filterSelectableRow}
              maxHeight={"40vh"}
              hiddenBarActions
              multiple
            />

            <div style={{ textAlign: "center", marginTop: 5, display: "flex", justifyContent: "center" }}>
              <button onClick={gotoPrev} className={cx("ui button")} style={{ marginRight: 20 }}>
                {t("main_ui.general.btn_prev")}
              </button>
              <Button disabled={!selectedIds} className='ui primary button' handleClick={handleSave} text={"btn_ok"} />
            </div>
          </>
        )
    }
  }

  return (
    <>
      <Step.Group ordered attached='top'>
        {STEPS.map((step, idx) => {
          return (
            <Step
              key={idx}
              active={currentStep == idx + 1}
              onClick={() => gotoStep(idx + 1, true)}
              completed={currentStep > idx + 1}
              disabled={currentStep < idx + 1}
            >
              <Step.Content>
                <Step.Title>{t(`frontend.auditoria.treeitem.actions.addchildren.${step.label}`)}</Step.Title>
              </Step.Content>
            </Step>
          )
        })}
      </Step.Group>
      <div className='ui center aligned  attached segment'>{renderStepContent()}</div>
    </>
  )
}

const DefaultTreeitemNewWizardDialog = ({ typeTree, itemTipo, configs, onClose }) => {
  const [value, setValue] = useState(configs?.initialValues?.name ?? "")
  const [tabSelected, setTabSelected] = useState(0)

  const { t } = useTranslation()

  const isDisabled =
    value == null ||
    (typeof value === "string" && value.length == 0) ||
    (typeof value === "object" && Object.values(value).length === 0)

  const { tree } = useSelector((state) => state)
  const [targetRow, indexes] = findInTreeBy(tree.rows, (item) => item.id == configs.parent?.id)

  const handleSave = (create_type) => {
    return (ev) => {
      if (isDisabled) {
        return false
      }

      const values = {
        ...(configs?.initialValues ?? {}),
        value,
        create_type,
        item_tipo: itemTipo,
        parentId: configs.parent?.id
      }

      configs.onSave(ev, values, configs).then(() => {
        onClose()
      })
    }
  }

  const breadcrumbs = useMemo(() => {
    return configs.parent ? [].concat(configs.parent.parents, [configs.parent]).map((x) => getTreeitemShortName(x)) : []
  }, [configs.parent?.id])

  const renderBody = () => {
    if (["auditPlan", "auditTemplate"].includes(typeTree) && (configs.allowCopy ?? true)) {
      return (
        <Tab currentTab={(tab) => setTabSelected(tab)} style={{ height: "calc(100% - 40px)" }}>
          <Tab.Content headerText={t("main_ui.general.lb_create_type_new")}>
            <FormNew value={value} setValue={setValue} handleSave={handleSave("new")} />
          </Tab.Content>

          <Tab.Content headerText={t("main_ui.general.lb_create_type_copy")}>
            <FormCopy itemTipo={itemTipo} value={value} setValue={setValue} handleSave={handleSave("copy")} />
          </Tab.Content>
        </Tab>
      )
    } else {
      return <FormNew value={value} setValue={setValue} handleSave={handleSave("new")} />
    }
  }

  const renderFooter = () => {
    switch (tabSelected) {
      case 0:
        return (
          <>
            <Button
              text='btn_cancel'
              name='warning'
              type='big'
              handleClick={(event) => {
                event.preventDefault()

                onClose()
              }}
            />
            <Button text='btn_save' name='normal' type='big' handleClick={handleSave("new")} disabled={isDisabled} />
          </>
        )
    }
  }

  return (
    <Dialog isOpen>
      <Dialog.Header theme='light' buttonClose handleClose={onClose}>
        <h4>
          {t("main_ui.general.create_action", {
            action: `$t(frontend.auditoria.treeitem.${itemTipo.substring(3).toLowerCase()})`
          })}
        </h4>
      </Dialog.Header>
      <Dialog.Body theme='light'>
        <BreadCrumbs list={breadcrumbs} className={style.breadcrumbs} link={false} />
        {renderBody()}
      </Dialog.Body>
      <Dialog.Footer theme='light'>{renderFooter()}</Dialog.Footer>
    </Dialog>
  )
}

export default function TreeitemNewWizardDialog({ typeTree, itemTipo, configs, onClose }) {
  return configs?.component ? (
    configs?.component(configs, onClose)
  ) : (
    <DefaultTreeitemNewWizardDialog typeTree={typeTree} itemTipo={itemTipo} configs={configs} onClose={onClose} />
  )
}
