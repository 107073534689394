import { useEffect, useRef, useState } from "react"
import useAuthentication from "./useAuthentication"

export default function useQueryString(
  params = [],
  listener = (v) => {},
  defaults = {},
  noInit = false,
  afterAuthentication = false
) {
  const { router, isAuthenticated, finishedLoading } = useAuthentication()

  const loadingTimeoutRef = useRef(0)
  const [isLoading, setIsLoading] = useState(true)
  const [parameters, setParameters] = useState(
    params.reduce((agg, value) => {
      agg[value] = null

      return agg
    }, {})
  )

  const initQueryStrings = () => {
    const _defaults = {
      ...defaults,
      ...Object.keys(router.query).reduce((agg, queryStr) => {
        agg[queryStr] = router.query[queryStr]
        return agg
      }, {})
    }

    const defaultParameters = params
      .map((param) => ({ param, default: _defaults[param] ?? null }))
      .filter((param) => param.default !== null)
      .reduce(function (acc, item) {
        acc[item.param] = item.default
        return acc
      }, {})

    router
      .push(
        {
          pathname: window.location.pathname,
          query: { ...router.query, ...defaultParameters }
        },
        undefined,
        { shallow: true }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (afterAuthentication) {
      if (!finishedLoading) {
        return
      }

      if (!isAuthenticated) {
        return
      }
    }

    if (noInit) {
      setIsLoading(false)
      return
    }

    if (!isLoading) {
      return
    }

    loadingTimeoutRef.current = setTimeout(initQueryStrings, 0)

    if (Object.keys(router.query).length === 0) {
      return () => {
        clearTimeout(loadingTimeoutRef.current)
      }
    }

    clearTimeout(loadingTimeoutRef.current)
    initQueryStrings()
  }, [router.query, isLoading, noInit, afterAuthentication, finishedLoading, isAuthenticated])

  useEffect(() => {
    if (isLoading) {
      return
    }

    const changed = {}

    Object.keys(router.query).forEach((queryItem) => {
      if (!(queryItem in parameters)) {
        return
      }

      const value = router.query[queryItem]

      if (parameters[queryItem] === value) {
        return
      }

      setParameters({
        ...parameters,
        [queryItem]: value
      })

      changed[queryItem] = value
    })

    listener({ ...changed })
  }, [router.query, isLoading])

  return (parameter = "", newValue = "") => {
    if (parameter === "_clear") {
      return router.push(router.pathname, undefined, { shallow: true })
    }

    if (!(parameter in parameters)) {
      return Promise.resolve(false)
    }

    return router.push({ query: { ...router.query, [parameter]: newValue } }, undefined, { shallow: true })
  }
}
