import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getRiskNoControls = authValidationMiddleware(async (auditId = null) => {
  const response = await axiosApiInstance.get(
    `/proyecto/riesgo/auditoria/${auditId}/tree/actions/riesgos_sin_controles`
  )
  return response.data
})

export const uploadRiskExcel = authValidationMiddleware(
  async (evaluationId = "", blobData = new Blob(), fileName = "", typeTree) => {
    const url = `/proyecto/riesgo/${typeTree}/${evaluationId}/import_riesgos/`

    const formData = new FormData()

    formData.append("archivo", blobData, fileName)

    const response = await axiosApiInstance.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })

    return response.data
  }
)

export const uploadRiskLibraryExcel = authValidationMiddleware(async (blobData = new Blob(), fileName = "") => {
  const url = `/proyecto/riesgo_base/import_riesgos/`

  const formData = new FormData()

  formData.append("archivo", blobData, fileName)

  const response = await axiosApiInstance.post(url, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  })

  return response.data
})

export const uploadControlExcel = authValidationMiddleware(async (auditId, blobData, fileName) => {
  const url = `/proyecto/riesgo/auditoria/${auditId}/import_controles/`

  const formData = new FormData()

  formData.append("archivo", blobData, fileName)

  const response = await axiosApiInstance.post(url, formData, { headers: { "content-type": "multipart/form-data" } })

  return response.data
})

export const createFromTemplate = authValidationMiddleware(async (typeTree, id, template) => {
  const url = `/proyecto/riesgo/${typeTree}/${id}/from_template/`
  const response = await axiosApiInstance.post(url, { value: template })

  return response.data
})

export const getRiskByAudit = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${id}/riesgos/`)
  return response.data
})

export const getHeatmapAuditRisks = authValidationMiddleware(async (projectId, filters) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${projectId}/riesgos_mapacalor/`, {
    params: filters
  })
  return response.data
})

export const getHeatmapEvaluacionRisks = authValidationMiddleware(async (filters) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/riesgos_mapacalor/`, {
    params: filters
  })
  return response.data
})
