import React, { forwardRef, useEffect, useRef, useState } from "react"
import Icon from "../../Icon"
import Portal from "../../Portal"
import styles from "./style.module.scss"

function GanttTooltip(
  {
    open = false,
    children,
    title = "",
    width = null,
    entries = [],
    leftPadding = 0,
    outerTrigger = false,
    ...otherProps
  },
  ref
) {
  const [isOpen, setIsOpen] = useState(open)

  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0
  })

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  })

  const [tooltipDimensions, setTooltipDimensions] = useState({
    width: 0,
    height: 0
  })

  const triggerRef = useRef(null)
  const tooltipRef = useRef(null)
  const animationRef = useRef(0)

  useEffect(() => {
    if (ref === null || !("current" in ref) || ref.current === null) {
      return
    }

    ref.current.addEventListener("mouseenter", onMouseEnter)
    ref.current.addEventListener("mouseleave", onMouseLeave)

    return () => {
      ref?.current?.removeEventListener("mouseenter", onMouseEnter)
      ref?.current?.removeEventListener("mouseleave", onMouseLeave)
    }
  }, [ref])

  const updateDimensions = () => {
    let rect

    if (outerTrigger) {
      if (ref === null || !("current" in ref) || ref.current === null) {
        return
      }

      rect = ref.current.getBoundingClientRect()
    } else {
      if (triggerRef === null || !("current" in triggerRef) || triggerRef.current === null) {
        return
      }

      rect = triggerRef.current.getBoundingClientRect()
    }

    setDimensions({
      width: rect.width,
      height: rect.height
    })
  }

  const updateTooltipDimensions = () => {
    if (typeof tooltipRef.current === "undefined" || tooltipRef.current === null) {
      return
    }

    const rect = tooltipRef.current?.getBoundingClientRect()

    setTooltipDimensions({
      width: rect.width,
      height: rect.height
    })
  }

  const updateCoordinates = () => {
    animationRef.current = requestAnimationFrame(updateCoordinates)

    let rect

    if (outerTrigger) {
      if (ref === null || !("current" in ref) || ref.current === null) {
        return
      }

      rect = ref.current.getBoundingClientRect()
    } else {
      if (triggerRef === null || !("current" in triggerRef) || triggerRef.current === null) {
        return
      }

      rect = triggerRef.current.getBoundingClientRect()
    }

    setCoordinates({
      x: rect.x,
      y: rect.y
    })
  }

  useEffect(() => {
    updateDimensions()
    updateTooltipDimensions()

    return () => cancelAnimationFrame(animationRef.current)
  }, [triggerRef, tooltipRef, animationRef])

  const onMouseEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setIsOpen(true)

    updateDimensions()
    updateTooltipDimensions()
    updateCoordinates()
  }

  const onMouseLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setIsOpen(false)

    cancelAnimationFrame(animationRef.current)
  }

  const makeTooltipStyle = () => {
    let marginX = coordinates.x + dimensions.width

    if (marginX >= window.innerWidth - 180 - 15) {
      marginX = window.innerWidth - 180 - 15
    } else {
      marginX = marginX + 15
    }

    const widthStyle = {}

    if (width !== null) {
      widthStyle.width = width
    }

    return {
      left: marginX + leftPadding,
      top: coordinates.y + dimensions.height / 2 - tooltipDimensions.height / 2,
      ...widthStyle
    }
  }

  const openClassName = isOpen ? ` ${styles.open}` : ""
  const tooltipClassName = `${styles.ganttTooltip}${openClassName}`

  return (
    <>
      {!outerTrigger ? (
        <div
          className={styles.gantTooltipTrigger}
          ref={triggerRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...otherProps}
        >
          {children}
        </div>
      ) : null}

      <Portal>
        <div ref={tooltipRef} className={tooltipClassName} style={makeTooltipStyle()}>
          <div className={styles.gantTooltipContentContainer}>
            <div className={styles.gantTooltipHeader}>{title}</div>
            <div className={styles.ganttTooltipBody}>
              {entries.map((entry, entryIdx) =>
                entry.hasUserIcon || entry.hasCalendarIcon ? (
                  <div
                    key={`gantt-tooltip-entry-${entryIdx}`}
                    style={{
                      paddingTop: entryIdx === 0 ? 0 : 5,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center"
                    }}
                  >
                    <span className={styles.item}>{entry.label}:</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        paddingTop: 5,
                        paddingLeft: 3
                      }}
                    >
                      <div
                        style={{
                          height: 20
                        }}
                      >
                        <Icon
                          size={16}
                          color={"#0a858d"}
                          name={
                            entry.hasUserIcon
                              ? "font-awesome/user_circle"
                              : entry.hasCalendarIcon
                              ? "font-awesome/calendar-regular"
                              : null
                          }
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 5,
                          boxSizing: "border-box"
                        }}
                      >
                        {entry.value}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`gantt-tooltip-entry-${entryIdx}`}
                    style={{
                      paddingTop: entryIdx === 0 ? 0 : 5
                    }}
                  >
                    <span className={styles.item}>{entry.label}:</span> {entry.value}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Portal>
    </>
  )
}

export default forwardRef(GanttTooltip)
