import compareAsc from "date-fns/compareAsc"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import endOfDay from "date-fns/endOfDay"
import endOfMonth from "date-fns/endOfMonth"
import formatFunc from "date-fns/format"
import isDate from "date-fns/isDate"
import isValid from "date-fns/isValid"
import startOfDay from "date-fns/startOfDay"
import startOfMonth from "date-fns/startOfMonth"
import debounce from "lodash/debounce"
import { useRouter } from "next/router"
import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import GanttHeader from "src/components/Gantt/GanttHeader"
import GanttRow from "src/components/Gantt/GanttRow"
import GanttRowBar from "src/components/Gantt/GanttRowBar"
import GanttSeparator from "src/components/Gantt/GanttSeparator"
import GanttTooltip from "src/components/Gantt/GanttTooltip"
import Icon from "src/components/Icon"
import { treeMode } from "src/constants/tree"
import { dateFormatter, dateParser, ShortISOFormat } from "src/helpers/dateFormatter"
import { BAR_COLUMN_WIDTH, calculateBarWidth, calculateColumnsSize, calculateWidth } from "src/helpers/gantt"
import useAccessControl from "src/hooks/useAccessControl"
import { logoutCallback } from "src/services/authValidationMiddleware"
import { getUsers } from "src/services/user.service"
import useDispatch from "src/store"
import { findDateRanges } from "src/store/tree/helpers"
import styles from "./style.module.scss"
import { findCoords, findOverlappingRegions } from "./utils"

export const GANTT_SET_BOUNDARIES = "GANTT_SET_BOUNDARIES"

export function makeDefaultGanttState() {
  return {
    start: null,
    end: null,
    days: {
      sorted: [],
      _map: {}
    },
    months: {
      sorted: [],
      _map: {},
      sortedMondays: []
    }
  }
}

function ganttReducer(
  state = makeDefaultGanttState(),
  action = {
    type: "",
    value: new Date(),
    boundaries: {
      start: new Date(),
      end: new Date()
    },
    mode: GanttMode.Monthly
  }
) {
  const { type, boundaries, mode } = action

  switch (type) {
    case GANTT_SET_BOUNDARIES: {
      const { start, end } = boundaries

      const getPaddedLimits = () => {
        switch (mode) {
          case GanttMode.Monthly: {
            const _start = startOfMonth(startOfDay(start))
            const _end = endOfMonth(startOfDay(end))

            return {
              start: _start,
              end: _end
            }
          }

          case GanttMode.Daily: {
            const _start = startOfDay(start)
            const _end = startOfDay(end)

            return {
              start: _start,
              end: _end
            }
          }

          default: {
            return {
              start: null,
              end: null
            }
          }
        }
      }

      const { start: paddedStart, end: paddedEnd } = getPaddedLimits()

      if (
        paddedStart === null ||
        paddedEnd === null ||
        !isValid(paddedStart) ||
        !isValid(paddedEnd) ||
        paddedStart > paddedEnd
      ) {
        return { ...state }
      }

      const sortedDays = eachDayOfInterval({
        start: paddedStart,
        end: paddedEnd
      })

      const { sortedMonths, sortedWeeks, mappedDays, mappedMonths } = sortedDays.reduce(
        (agg, day, dayIdx) => {
          const formattedDate = formatFunc(day, "yyyy-MM-dd")
          const [yearStr, monthStr, dayStr] = formattedDate.split("-")
          const monthKey = `${yearStr}-${monthStr}`

          agg.mappedDays[formattedDate] = dayIdx

          if (day.getDate() === 1 && !(monthKey in agg.mappedMonths)) {
            // Day is the first day of the month
            agg.mappedMonths[monthKey] = {}
            agg.sortedMonths.push({
              year: yearStr,
              month: monthStr,
              mondays: []
            })
          }

          if (day.getDay() === 1) {
            // Day is monday
            const latestIdx = agg.sortedMonths.length - 1

            if (latestIdx === -1) {
              return agg
            }

            agg.mappedMonths[monthKey][parseInt(dayStr, 10)] = agg.sortedMonths.reduce((monthAgg, month) => {
              return monthAgg + month.mondays.length
            }, 0)

            agg.sortedMonths[latestIdx].mondays.push(day)
            agg.sortedWeeks.push(day)
          }

          return agg
        },
        {
          sortedMonths: [],
          sortedWeeks: [],
          mappedMonths: {},
          mappedDays: {}
        }
      )

      return {
        ...state,
        start: paddedStart,
        end: paddedEnd,
        days: {
          sorted: sortedDays,
          _map: mappedDays
        },
        months: {
          sorted: sortedMonths,
          _map: mappedMonths,
          sortedMondays: sortedWeeks
        }
      }
    }

    default: {
      return { ...state }
    }
  }
}

export const GanttContext = createContext({
  state: null,
  dispatch: null
})

function validItems(items) {
  return !(typeof items === "undefined" || items === null || !Array.isArray(items))
}

export const GanttMode = Object.freeze({
  Daily: 1,
  Monthly: 2
})

export default function Gantt({
  rows = [],
  groups = [],
  separators = [],
  dateRangeFinder = (rows) => findDateRanges(rows),

  mode = GanttMode.Monthly,
  showOverlaps = false,
  disableUsedDayOverlap = false,
  supportsRowEditModal = true,

  todayClassName = "",
  todayColor = "",
  maxHeight = "calc(100vh - 120px)",
  maxWidth = "calc(100vw - 80px)",

  onInit = () => {},

  onRowToggle = () => {},
  onRowUpdate = () => {},

  onRowDateUpdate = () => {
    // Same args as onRowUpdate
    return Promise.resolve()
  },

  rowStatusSelector = () => {
    return null
  },
  rowProgressSelector = () => {
    return null
  },
  rowUserSelector = () => {
    return null
  },
  rowLabelSelector = () => {
    return null
  },
  rowEmphasisSelector = (row) => {
    return !row.colorless
  },
  rowImportanceSelector = (row) => {
    return row.important
  },

  rowStatusLabelSelector = (status) => {
    return status.label
  },
  rowStatusColourSelector = (status) => {
    return status.color
  },

  rowIconMaker = () => {
    return null
  },

  rowItemsSelector = (row) => row.items,

  extraColumns = [],

  filters = {
    start: "",
    end: ""
  },

  title = null,
  typeTree = treeMode.AUDITPLAN,
  style = {},
  disableDrag = false
}) {
  const { hasPermission } = useAccessControl()
  const router = useRouter()
  const dispatch = useDispatch()

  const ganttRef = useRef(null)
  const todayRef = useRef(null)
  const [isLoadingResponsibles, setIsLoadingResponsibles] = useState(false)
  const [finishedLoadingResponsibles, setFinishedLoadingResponsibles] = useState(false)
  const [responsibles, setResponsibles] = useState([])
  const [ganttMode, setGanttMode] = useState(mode)

  const [ganttState, dispatchGanttState] = useReducer(ganttReducer, makeDefaultGanttState())

  useEffect(() => {
    if (mode === ganttMode) {
      return
    }

    setGanttMode(mode)
  }, [mode])

  const ganttFilters = useMemo(() => {
    if (typeof filters === "undefined" || filters === null) {
      return {}
    }

    const result = {}

    if ("start" in filters && typeof filters.start === "string") {
      if (isDate(filters.start)) {
        result.start = startOfDay(filters.start)
      } else if (typeof filters.start === "string" && filters.start !== "") {
        result.start = dateParser(filters.start)
      } else if (typeof filters.start !== "string") {
        throw new Error(`filter.start has type "${typeof filters.start}" which is not supported,
        only Date objs and strings (yyyy-MM-dd) are supported`)
      }

      if ("start" in result && ganttMode === GanttMode.Monthly) {
        result.start = startOfMonth(result.start)
      }
    }

    if ("end" in filters && typeof filters.end !== "undefined") {
      if (isDate(filters.end)) {
        result.end = endOfDay(filters.end)
      } else if (typeof filters.end === "string" && filters.end !== "") {
        result.end = endOfDay(dateParser(filters.end))
      } else if (typeof filters.end !== "string") {
        throw new Error(`filter.end has type "${typeof filters.end}" which is not supported,
        only Date objs and strings (yyyy-MM-dd) are supported`)
      }

      if ("end" in result && ganttMode === GanttMode.Monthly) {
        result.end = endOfMonth(result.end)
      }
    }

    return result
  }, [filters, ganttMode])

  const filterRows = useCallback(
    (_rows = []) => {
      if (typeof ganttFilters === "undefined" || ganttFilters === null) {
        return _rows
      }

      if (!("start" in ganttFilters) && !("end" in ganttFilters)) {
        return _rows
      }

      return _rows.filter((row) => {
        let flag = true

        if ("start" in ganttFilters) {
          flag = isDate(row.end) && compareAsc(row.end, ganttFilters.start) >= 0
        }

        if (!flag) {
          return false
        }

        if ("end" in ganttFilters) {
          flag = isDate(row.start) && compareAsc(row.start, ganttFilters.end) <= 0
        }

        return flag
      })
    },
    [ganttFilters]
  )

  const renderedRowsAmount = useMemo(() => {
    const countRows = (_rows = [], amount = 0) => {
      const _filtered = filterRows(_rows)

      return (
        amount +
        _filtered.length +
        _filtered.reduce((agg, _row) => {
          return _row.isOpen ? countRows(_row.items ?? [], agg) : agg
        }, 0)
      )
    }

    if (groups.length > 0) {
      const filteredGroups = groups
        .map((group) => {
          return {
            ...group,
            items: filterRows(group?.items ?? [])
          }
        })
        .filter((g) => g.items.length > 0)

      return (
        countRows(
          filteredGroups.reduce((agg, group) => {
            return [...agg, ...group.items]
          }, [])
        ) + filteredGroups.length
      )
    }

    return countRows(filterRows(rows))
  }, [rows, groups, filterRows])

  const regionPadding = useMemo(() => {
    return Array.isArray(extraColumns) && extraColumns.length > 0
      ? extraColumns.reduce((_agg, _value) => {
          if (typeof _value.span === "number" && _value.span > 0) {
            return _agg + calculateWidth(_value.span)
          }

          return _agg + calculateWidth(1)
        }, 0)
      : 0
  }, [extraColumns])

  const [scrolled, setScrolled] = useState({
    hasScrolled: false,
    scrolledX: 0,
    hasScrolledY: false,
    scrolledY: 0
  })

  const ganttRange = useMemo(() => {
    if (Array.isArray(groups) && groups.length > 0) {
      const { earliest, latest } = dateRangeFinder(
        groups.reduce((groupAgg, group) => {
          if ("items" in group && Array.isArray(group.items)) {
            groupAgg.push(...group.items)
          }

          return groupAgg
        }, [])
      )

      return {
        start: earliest,
        end: latest
      }
    }

    if (!Array.isArray(rows) || rows.length === 0) {
      return {
        start: null,
        end: null
      }
    }

    const { earliest, latest } = dateRangeFinder(rows)

    return {
      start: earliest,
      end: latest
    }
  }, [dateRangeFinder, groups, rows])

  const { start, end } = useMemo(() => {
    const { start: ganttStart, end: ganttEnd } = ganttRange

    if (Object.keys(ganttFilters).length === 0) {
      return {
        start: ganttStart,
        end: ganttEnd
      }
    }

    return {
      start: ganttFilters.start || ganttStart,
      end: ganttFilters.end || ganttEnd
    }
  }, [ganttRange, ganttFilters])

  useEffect(() => {
    if (
      typeof ganttRange.start === "undefined" ||
      ganttRange.start === null ||
      typeof ganttRange.end === "undefined" ||
      ganttRange.end === null
    ) {
      return
    }

    const _start = typeof ganttRange.start === "string" ? dateParser(ganttRange.start) : ganttRange.start
    const _end = typeof ganttRange.end === "string" ? dateParser(ganttRange.end) : ganttRange.end

    onInit({
      dates: {
        start: _start,
        end: _end
      },
      strings: {
        start: dateFormatter(_start),
        end: dateFormatter(_end)
      }
    })
  }, [ganttRange.start, ganttRange.end, onInit])

  const ganttContextValue = useMemo(() => {
    return {
      state: ganttState,
      dispatch: dispatchGanttState,
      rows,
      groups
    }
  }, [ganttState, dispatchGanttState, rows, groups])

  const today = useMemo(() => {
    return startOfDay(new Date())
  }, [])

  const updateScroll = useCallback(
    (scrolledX = 0, scrolledY = 0) => {
      const hasScrolled = scrolledX > 0
      const hasScrolledY = scrolledY > 0

      setScrolled({
        hasScrolled,
        scrolledX,
        hasScrolledY,
        scrolledY
      })
    },
    [scrolled]
  )

  useEffect(() => {
    if (start === null || end === null) {
      return
    }

    dispatchGanttState({
      type: GANTT_SET_BOUNDARIES,
      boundaries: {
        start,
        end
      },
      mode: ganttMode
    })
  }, [start, end, ganttMode])

  useEffect(() => {
    if (finishedLoadingResponsibles) {
      return
    }

    setIsLoadingResponsibles(true)

    getUsers(logoutCallback(dispatch, router))
      .then((result) => {
        setResponsibles(result?.data?.records ?? [])
      })
      .finally(() => {
        setIsLoadingResponsibles(false)
        setFinishedLoadingResponsibles(true)
      })
  }, [finishedLoadingResponsibles])

  const handleOnScroll = useCallback(() => {
    const scrolledX = ganttRef.current?.scrollLeft ?? 0
    const scrolledY = ganttRef.current?.scrollTop ?? ""

    updateScroll(scrolledX, scrolledY)
  }, [updateScroll, ganttRef.current])

  const renderSundaySeparators = useCallback(() => {
    let separators

    switch (ganttMode) {
      case GanttMode.Daily: {
        separators = ganttState.days.sorted
          .filter((day) => day.getDay() === 0)
          .map((sunday) => {
            const sundayKey = formatFunc(sunday, "yyyy-MM-dd")
            const sundayIdx = ganttState.days._map[sundayKey]

            return (
              <GanttSeparator
                headerSize={40}
                renderedRowsAmount={renderedRowsAmount}
                style={{ left: calculateWidth(sundayIdx + 1) - 1 + regionPadding }}
                key={`gantt-separator-sunday-${sundayIdx}`}
              />
            )
          })

        break
      }

      case GanttMode.Monthly: {
        separators = ganttState.months.sorted.map((month) => {
          const firstMonday = month.mondays[0]
          const formattedDate = formatFunc(firstMonday, "yyyy-MM-dd")
          const [yearStr, monthStr, dayStr] = formattedDate.split("-")
          const monthKey = `${yearStr}-${monthStr}`
          const dayKey = parseInt(dayStr, 10)
          const mondayIdx = ganttState.months._map[monthKey][dayKey]

          return (
            <GanttSeparator
              headerSize={80}
              renderedRowsAmount={renderedRowsAmount}
              style={{ left: calculateWidth(mondayIdx) - 1 + regionPadding }}
              key={`gantt-separator-month-first-day-${mondayIdx}`}
            />
          )
        })

        break
      }

      default: {
        return null
      }
    }

    return <>{separators}</>
  }, [
    ganttMode,
    regionPadding,
    ganttState.days.sorted,
    ganttState.days._map,
    ganttState.months.sorted,
    ganttState.months._map
  ])

  const todayIdx = useMemo(() => {
    switch (ganttMode) {
      case GanttMode.Daily: {
        if (Object.keys(ganttState.days._map).length === 0) {
          return null
        }

        const todayKey = formatFunc(today, "yyyy-MM-dd")
        const _todayIdx = ganttState.days._map[todayKey]

        if (typeof _todayIdx !== "number") {
          return null
        }

        return _todayIdx
      }

      case GanttMode.Monthly: {
        if (Object.keys(ganttState.months._map).length === 0) {
          return null
        }

        const todayKey = formatFunc(today, "yyyy-MM-dd")
        const [yearStr, monthStr, dayStr] = todayKey.split("-")
        const monthKey = `${yearStr}-${monthStr}`
        let dayKey = parseInt(dayStr, 10)
        const month = ganttState.months._map[monthKey]

        if (typeof month === "undefined" || month === null) {
          return null
        }

        let mondayIdx

        // noinspection DuplicatedCode
        if (typeof month[dayKey] === "number") {
          mondayIdx = month[dayKey]
        } else {
          dayKey = Object.keys(month)
            .map((x) => parseInt(x, 10))
            .sort((a = 0, b = 0) => {
              if (a > b) {
                return -1
              }

              if (a < b) {
                return 1
              }

              return 0
            })
            .find((_dayKey) => dayKey > _dayKey)

          mondayIdx = month[dayKey]
        }

        return mondayIdx ?? null
      }

      default: {
        return null
      }
    }
  }, [ganttMode, ganttState.days._map, ganttState.months._map, today])

  const scrollToToday = useCallback(
    debounce(() => {
      const ref = ganttRef.current ?? null

      if (ref === null) {
        return
      }

      const ganttWidth = ref.offsetWidth
      const containerWidth = ganttWidth - 300
      const renderedColumns = calculateColumnsSize(containerWidth)
      const renderedColumnsAsIdx = renderedColumns - 1

      const lowScrollIdx = Math.floor(renderedColumnsAsIdx / 2.0)

      if (todayIdx > renderedColumnsAsIdx) {
        ref.scrollLeft = calculateWidth(todayIdx - lowScrollIdx + 1) + 1

        return
      }

      if (todayIdx <= lowScrollIdx) {
        return
      }

      ref.scrollLeft = calculateWidth(todayIdx - lowScrollIdx + 1) + 1
    }, 10),
    [ganttRef.current, todayRef.current, todayIdx, ganttMode]
  )

  useEffect(() => {
    if (typeof ganttRef.current === "undefined" || ganttRef.current === null) {
      return
    }

    if (typeof todayRef.current === "undefined" || todayRef.current === null) {
      return
    }

    if (todayIdx === null) {
      return
    }

    switch (ganttMode) {
      case GanttMode.Monthly:
      case GanttMode.Daily: {
        break
      }

      default: {
        return
      }
    }

    ganttRef.current.scrollLeft = 0
    scrollToToday()
  }, [ganttRef.current, todayRef.current, todayIdx, ganttMode, scrollToToday])

  const renderTodaySeparator = useCallback(() => {
    const _color = typeof todayColor === "string" && todayColor !== "" ? todayColor : "#CCCCCC"

    if (todayIdx === null) {
      return null
    }

    switch (ganttMode) {
      case GanttMode.Daily: {
        return (
          <GanttSeparator
            headerSize={40}
            renderedRowsAmount={renderedRowsAmount}
            special={true}
            className={todayClassName}
            style={{ left: calculateWidth(todayIdx + 1) - 1 + regionPadding }}
            ref={todayRef}
            key={`gantt-separator-today`}
            label={"HOY"}
            color={_color}
          />
        )
      }

      case GanttMode.Monthly: {
        return (
          <GanttSeparator
            headerSize={80}
            renderedRowsAmount={renderedRowsAmount}
            special={true}
            className={todayClassName}
            ref={todayRef}
            style={{ left: calculateWidth(todayIdx + 1) - 1 + regionPadding }}
            key={`gantt-separator-today`}
            label={"HOY"}
            color={_color}
          />
        )
      }

      default: {
        return null
      }
    }
  }, [todayRef, todayIdx, todayColor, ganttMode, regionPadding, renderedRowsAmount])

  const renderSeparators = useCallback(() => {
    const renderedSundays = renderSundaySeparators()
    const renderedToday = renderTodaySeparator()
    let renderedStandaloneSeparators

    switch (ganttMode) {
      case GanttMode.Daily: {
        if (Object.keys(ganttState.days._map).length === 0) {
          return null
        }

        renderedStandaloneSeparators = separators.map((separator) => {
          if (!("date" in separator) || typeof separator.date !== "string" || separator.date === "") {
            return null
          }

          const separatorDate = new Date(`${separator.date} 00:00:00`)
          const separatorHasTooltip = "tooltip" in separator
          const separatorKey = formatFunc(separatorDate, "yyyy-MM-dd")
          const separatorIdx = ganttState.days._map[separatorKey]

          if ("start" in ganttFilters && compareAsc(separatorDate, ganttFilters.start) === -1) {
            return null
          }

          if ("end" in ganttFilters && compareAsc(separatorDate, ganttFilters.end) === 1) {
            return null
          }

          if (typeof separatorIdx !== "number") {
            return null
          }

          return (
            <GanttSeparator
              special={"special" in separator ? !!separator.special : false}
              style={{ left: calculateWidth(separatorIdx + 1) - 1 + regionPadding }}
              className={separator.className}
              label={separator.label}
              headerSize={40}
              renderedRowsAmount={renderedRowsAmount}
              color={"color" in separator ? separator.color : undefined}
              key={`gantt-separator-${separatorIdx}-${separatorKey}`}
            >
              {separatorHasTooltip ? (
                <div className={styles.ganttSeparatorTriggerZone}>
                  <GanttTooltip
                    leftPadding={-10}
                    title={separator.tooltip.title}
                    entries={"entries" in separator.tooltip ? separator.tooltip.entries : []}
                  />
                </div>
              ) : null}
            </GanttSeparator>
          )
        })

        break
      }

      case GanttMode.Monthly: {
        if (Object.keys(ganttState.months._map).length === 0) {
          return null
        }

        renderedStandaloneSeparators = separators?.map((separator) => {
          if (!("date" in separator) || typeof separator.date !== "string" || separator.date === "") {
            return null
          }

          const separatorDate = new Date(`${separator.date} 00:00:00`)
          const separatorHasTooltip = "tooltip" in separator
          const separatorKey = formatFunc(separatorDate, "yyyy-MM-dd")

          const [yearStr, monthStr, dayStr] = separatorKey.split("-")
          const monthKey = `${yearStr}-${monthStr}`
          const month = ganttState.months._map[monthKey]

          if (typeof month === "undefined" || month === null) {
            return null
          }

          let mondayIdx
          let dayKey = parseInt(dayStr, 10)

          // noinspection DuplicatedCode
          if (typeof month[dayKey] === "number") {
            mondayIdx = month[dayKey]
          } else {
            const monthKeys = Object.keys(month)
              .map((x) => parseInt(x, 10))
              .sort((a = 0, b = 0) => {
                if (a > b) {
                  return -1
                }

                if (a < b) {
                  return 1
                }

                return 0
              })
            dayKey = monthKeys.find((_dayKey) => dayKey > _dayKey)

            if (typeof dayKey !== "undefined") {
              mondayIdx = month[dayKey]
            } else {
              mondayIdx = month[monthKeys[monthKeys.length - 1]] - 1
            }
          }

          return (
            <GanttSeparator
              special={"special" in separator ? !!separator.special : false}
              headerSize={80}
              renderedRowsAmount={renderedRowsAmount}
              style={{ left: calculateWidth(mondayIdx + 1) - 1 + regionPadding }}
              className={separator.className}
              label={separator.label}
              color={"color" in separator ? separator.color : undefined}
              key={`gantt-separator-${mondayIdx}-${separatorKey}-${separator.key ? separator.key : ""}`}
            >
              {separatorHasTooltip ? (
                <div className={styles.ganttSeparatorTriggerZone}>
                  <GanttTooltip
                    leftPadding={-10}
                    title={separator.tooltip.title}
                    entries={"entries" in separator.tooltip ? separator.tooltip.entries : []}
                  />
                </div>
              ) : null}
            </GanttSeparator>
          )
        })

        break
      }

      default: {
        return null
      }
    }

    return (
      <>
        {renderedSundays}
        {renderedToday}
        {renderedStandaloneSeparators}
      </>
    )
  }, [
    ganttMode,
    separators,
    regionPadding,
    ganttState.days._map,
    ganttState.months._map,
    renderSundaySeparators,
    renderTodaySeparator,
    ganttFilters
  ])

  const makeOnToggle = useCallback(
    (indexes) => {
      return (value) => {
        onRowToggle(value, indexes, dispatch)
      }
    },
    [onRowToggle]
  )

  const renderRows = useCallback(
    (_rows = [], columns, group = null, ...indexes) => {
      return filterRows(_rows).map((row, idx) => {
        const rowStatus = rowStatusSelector(row)
        const rowProgress = rowProgressSelector(row)
        const rowUser = rowUserSelector(row)
        const rowLabel = rowLabelSelector(row)

        const rowKey = `gantt-row${indexes.length > 0 ? "-" : ""}${indexes.join("-")}-final_idx-${idx}`

        const _indexes = [...indexes, idx]

        let disableDragOnRow = false

        if (disableDrag) {
          disableDragOnRow = true
        } else if (typeof row.disableDrag === "boolean" && row.disableDrag) {
          disableDragOnRow = true
        }

        let filterStart, filterEnd

        if (ganttMode === GanttMode.Monthly) {
          filterStart = startOfMonth(ganttFilters.start)
          filterEnd = endOfMonth(ganttFilters.end)
        } else {
          filterStart = ganttFilters.start
          filterEnd = ganttFilters.end
        }

        const startsBeforeFilter = compareAsc(filterStart, row.start) > 0
        const endsAfterFilter = compareAsc(filterEnd, row.end) < 0
        const disabledDueToFilter = startsBeforeFilter || endsAfterFilter

        return (
          <GanttRow
            supportsEditModal={supportsRowEditModal}
            key={rowKey}
            row={row}
            regionPadding={regionPadding}
            indexes={[..._indexes]}
            label={rowLabel}
            open={row.isOpen}
            itemId={row.id}
            emphasis={rowEmphasisSelector(row)}
            important={rowImportanceSelector(row)}
            rowIcon={rowIconMaker(row)}
            ganttState={ganttState}
            filterization={{
              startsBeforeFilter,
              endsAfterFilter,
              ganttFilters,
              disabled: disabledDueToFilter
            }}
            statusLabel={
              rowStatus !== null && typeof rowStatus !== "undefined" ? rowStatusLabelSelector(rowStatus) : undefined
            }
            barColour={
              rowStatus !== null && typeof rowStatus !== "undefined" ? rowStatusColourSelector(rowStatus) : undefined
            }
            progress={rowProgress}
            responsible={rowUser}
            today={today}
            start={row.start}
            end={row.end}
            mode={ganttMode}
            duration={row.duration}
            onToggle={makeOnToggle(_indexes)}
            onRowUpdate={onRowUpdate}
            responsibles={responsibles}
            type={row.type}
            tooltip={"tooltip" in row && Object.keys(row.tooltip).length > 0 ? row.tooltip : undefined}
            scrolled={scrolled}
            columns={columns}
            onDrag={onRowDateUpdate}
            group={group}
            typeTree={typeTree}
            disableDrag={disableDragOnRow}
          >
            {validItems(rowItemsSelector(row)) ? renderRows(rowItemsSelector(row), columns, ..._indexes) : null}
          </GanttRow>
        )
      })
    },
    [
      filterRows,
      rowStatusSelector,
      rowProgressSelector,
      rowUserSelector,
      rowLabelSelector,
      rowItemsSelector,
      ganttMode,
      today,
      rowIconMaker,
      rowImportanceSelector,
      rowEmphasisSelector,
      makeOnToggle,
      onRowDateUpdate,
      scrolled,
      responsibles,
      regionPadding,
      ganttState,
      ganttFilters,
      supportsRowEditModal,
      disableDrag
    ]
  )

  const renderOverlappingRegions = useCallback(
    ({ items = [], headerSize = 40, defaultCoords = null, disableUsedPeriods = false }) => {
      if (!showOverlaps) {
        return null
      }

      if (typeof items === "undefined" || items === null || !Array.isArray(items)) {
        return null
      }

      const coords =
        defaultCoords !== null
          ? {
              x1: defaultCoords.x1,
              x2: defaultCoords.x2
            }
          : {
              x1: 0,
              x2: 0
            }

      if (defaultCoords === null) {
        switch (ganttMode) {
          case GanttMode.Daily: {
            coords.x2 = ganttState.days.sorted.length > 0 ? ganttState.days.sorted.length - 1 : 0
            break
          }

          case GanttMode.Monthly: {
            coords.x2 = ganttState.months.sortedMondays.length > 0 ? ganttState.months.sortedMondays.length - 1 : 0
            break
          }

          default: {
            return null
          }
        }
      }

      const reduceRow = (row = {}, agg = []) => {
        agg.push(row)

        if (validItems(rowItemsSelector(row))) {
          row.items.forEach((subRow) => {
            reduceRow(subRow, agg)
          })
        }

        return agg
      }

      const regions = items
        .reduce((rowsAgg, row) => reduceRow(row, rowsAgg), [])
        .map((row) => {
          if (
            typeof row.start === "undefined" ||
            row.start === null ||
            typeof row.end === "undefined" ||
            row.end === null
          ) {
            return null
          }

          const _coords = findCoords(row.start, row.end, {
            mode: ganttMode,
            daysMap: ganttState.days._map,
            monthsMap: ganttState.months._map
          })

          if (_coords === null) {
            return null
          }

          return _coords
        })
        .filter((rowCoords) => rowCoords !== null)

      const [ok, overlapCoordsMap] = findOverlappingRegions({
        coords,
        regions
      })

      if (!ok) {
        return null
      }

      return Object.keys(overlapCoordsMap).map((x) => {
        const { overlaps } = overlapCoordsMap[x]

        if (overlaps < 0) {
          // Never happens
          return null
        }

        const positionStyle = {
          left: (x > 0 ? x * calculateWidth(1) : 0) + 300 + regionPadding
        }

        const elementStyle = {
          position: "absolute",
          width: calculateWidth(1),
          height: headerSize > 0 ? `calc(100% - ${headerSize}px)` : "100%",
          content: "",
          top: headerSize,
          zIndex: 0,
          ...positionStyle
        }

        if (overlaps === 0) {
          // Paints red
          return (
            <div
              key={x}
              style={{
                backgroundColor: "red",
                opacity: 0.2,
                ...elementStyle
              }}
            />
          )
        }

        if (overlaps === 1) {
          // Does NOT paint
          return null
        }

        // EXECUTES IF (overlaps >= 2) // Gets painted according to the opacity

        return (
          <div
            key={x}
            style={{
              backgroundColor: "purple",
              opacity: disableUsedPeriods ? 0 : 0.2,
              ...elementStyle
            }}
          />
        )
      })
    },
    [ganttMode, showOverlaps, regionPadding, ganttState.days._map, ganttState.months._map]
  )

  const renderGroups = useCallback(() => {
    const renderGroupDays = (group = {}) => {
      switch (ganttMode) {
        case GanttMode.Daily: {
          return (
            <div
              className={styles.ganttGroupDays}
              key={`gantt-group-header-${group.nombre}-${ganttState.days.sorted.length}-days`}
              style={{
                width: calculateWidth(ganttState.days.sorted.length)
              }}
            />
          )
        }

        case GanttMode.Monthly: {
          return (
            <div
              className={styles.ganttGroupDays}
              key={`gantt-row-${group.nombre}-${ganttState.months.sortedMondays.length}-days`}
              style={{
                width: calculateWidth(ganttState.months.sortedMondays.length)
              }}
            />
          )
        }

        default: {
          return null
        }
      }
    }

    const renderGroupExtraColumns = (groupItems = []) => {
      if (!Array.isArray(extraColumns) || extraColumns.length === 0) {
        return null
      }

      const renderExtraColumn = (groupItems = [], column = {}, key = "") => {
        return (
          <div
            className={styles.groupExtraColumn}
            style={{
              width: calculateWidth(column.span ?? 1),
              color: column.color ?? "#DDDDDD",
              left: scrolled.hasScrolled ? scrolled.scrolledX : "auto"
            }}
            key={key}
          >
            {groupItems.reduce((groupValueAgg, groupItem) => {
              return groupValueAgg + column.attrSelector(groupItem)
            }, 0)}
            h
          </div>
        )
      }

      return extraColumns.map((column, columnIdx) => renderExtraColumn(groupItems, column, `extra-column-${columnIdx}`))
    }

    const renderGroupBar = (ok = false, group = {}, _groupCoords = { x1: 0, x2: 0 }) => {
      if (!ok) {
        return null
      }

      let groupBarBoundaries = [0, 0]
      let groupBarWidth

      const _groupRowX1 = _groupCoords.x1
      const _groupRowX2 = _groupCoords.x2

      if (_groupRowX2 - _groupRowX1 < 1) {
        groupBarWidth = calculateWidth(1)
      } else {
        groupBarWidth = calculateBarWidth(_groupRowX1, _groupRowX2)
      }

      switch (ganttMode) {
        case GanttMode.Daily: {
          groupBarBoundaries = [0, calculateWidth(ganttState.days.sorted.length)]
          break
        }

        case GanttMode.Monthly: {
          groupBarBoundaries = [0, calculateWidth(ganttState.months.sortedMondays.length)]
          break
        }
      }

      let disableDragOnGroupBar = false

      if (disableDrag) {
        disableDragOnGroupBar = true
      } else if (typeof group.disableDrag === "boolean" && group.disableDrag) {
        disableDragOnGroupBar = true
      }

      // noinspection DuplicatedCode
      return (
        <div
          style={{
            position: "absolute",
            zIndex: 899,
            top: 0,
            left: 0
          }}
        >
          <GanttRowBar
            disableDrag={disableDragOnGroupBar}
            width={groupBarWidth}
            boundaries={groupBarBoundaries}
            color={"#99CC00"}
            tooltip={group.tooltip}
            progress={(group.progress ?? 0) + "%"}
            snapJumps={_groupRowX1 + 1}
            onClick={() => {}}
            onDrag={(jumps = 0) => {
              const startDateNeedle = jumps - 1
              const diff = _groupRowX2 - _groupRowX1
              const endDateNeedle = startDateNeedle + diff
              let selectedStartDate, selectedEndDate

              switch (ganttMode) {
                case GanttMode.Daily: {
                  selectedStartDate = ganttState.days.sorted[startDateNeedle]
                  selectedEndDate = ganttState.days.sorted[endDateNeedle]
                  break
                }

                case GanttMode.Monthly: {
                  selectedStartDate = ganttState.months.sortedMondays[startDateNeedle]
                  selectedEndDate = ganttState.months.sortedMondays[endDateNeedle]
                  break
                }

                default: {
                  return
                }
              }

              return onRowDateUpdate(
                {
                  isGroupBar: true,
                  startDate: dateFormatter(selectedStartDate, ShortISOFormat),
                  endDate: dateFormatter(selectedEndDate, ShortISOFormat)
                },
                group.id,
                dispatch
              )
            }}
            onResize={(startJumps = 0, endJumps = 0) => {
              const startDateNeedle = startJumps - 1
              const endDateNeedle = startDateNeedle === endJumps - 1 ? endJumps : endJumps - 1

              let selectedStartDate, selectedEndDate

              switch (ganttMode) {
                case GanttMode.Daily: {
                  selectedStartDate = ganttState.days.sorted[startDateNeedle]
                  selectedEndDate = ganttState.days.sorted[endDateNeedle]
                  break
                }

                case GanttMode.Monthly: {
                  selectedStartDate = ganttState.months.sortedMondays[startDateNeedle]
                  selectedEndDate = ganttState.months.sortedMondays[endDateNeedle]
                  break
                }

                default: {
                  return
                }
              }

              return onRowDateUpdate(
                {
                  isGroupBar: true,
                  startDate: dateFormatter(selectedStartDate, ShortISOFormat),
                  endDate: dateFormatter(selectedEndDate, ShortISOFormat)
                },
                group.id,
                dispatch
              )
            }}
            snap={BAR_COLUMN_WIDTH}
          />
        </div>
      )
    }

    const renderGroup = (group = {}, key = "") => {
      const groupHasDates = "start" in group && "end" in group
      let groupPassesFilters = false

      if (groupHasDates) {
        if ("start" in ganttFilters && "end" in ganttFilters) {
          groupPassesFilters =
            compareAsc(group.start, ganttFilters.start) >= 0 && compareAsc(group.end, ganttFilters.end) <= 0
        } else if ("start" in ganttFilters) {
          groupPassesFilters = compareAsc(group.start, ganttFilters.start) >= 0
        } else if ("end" in ganttFilters) {
          groupPassesFilters = compareAsc(group.end, ganttFilters.end) <= 0
        } else {
          groupPassesFilters = true
        }
      }

      const groupHasBar = groupHasDates && groupPassesFilters
      const groupItems = filterRows(group.items ?? [])

      if (groupItems.length === 0) {
        return null
      }

      const groupRowCoords =
        typeof group.start !== "undefined" &&
        group.start !== null &&
        typeof group.end !== "undefined" &&
        group.end !== null
          ? findCoords(group.start, group.end, {
              mode: ganttMode,
              daysMap: ganttState.days._map,
              monthsMap: ganttState.months._map
            })
          : null

      const groupRowX1 = groupRowCoords?.x1 ?? 0
      const groupRowX2 = groupRowCoords?.x2 ?? 0

      // console.log({
      //   name: group.nombre,
      //   start: group.start, end: group.end,
      //   mode: ganttMode,
      //   daysMap: ganttState.days._map,
      //   monthsMap: ganttState.months._map,
      //   groupRowCoords,
      //   groupItems,
      //   regions: renderOverlappingRegions({
      //     items: groupItems,
      //     headerSize: 0,
      //     defaultCoords:
      //       groupRowCoords === null
      //         ? null
      //         : {
      //           x1: groupRowX1,
      //           x2: groupRowX2
      //         },
      //     disableUsedPeriods: disableUsedDayOverlap
      //   }, group)
      // })

      const groupNameIcon =
        typeof group.icon === "boolean" && group.icon ? (
          <div
            style={{
              paddingRight: 10,
              boxSizing: "border-box"
            }}
          >
            <Icon size={24} color={"#424242"} name='font-awesome/user_circle' />
          </div>
        ) : null

      return (
        <div className={styles.ganttGroup} key={key}>
          <div className={styles.ganttGroupHeader}>
            <div
              className={`${styles.ganttGroupHeaderTitle}`}
              style={{
                left: scrolled.hasScrolled ? scrolled.scrolledX : "auto"
              }}
            >
              {groupNameIcon}
              <div>{group.nombre ?? "-"}</div>
            </div>
            {renderGroupExtraColumns(groupItems)}

            <div className={styles.ganttGroupBarWrapper}>
              {renderGroupDays()}
              {renderGroupBar(groupHasBar, group, {
                x1: groupRowX1,
                x2: groupRowX2
              })}
            </div>
          </div>

          <div className={styles.ganttGroupBody}>
            {renderRows(groupItems, extraColumns, group)}
            {renderOverlappingRegions(
              {
                items: groupItems,
                headerSize: 0,
                defaultCoords:
                  groupRowCoords === null
                    ? null
                    : {
                        x1: groupRowX1,
                        x2: groupRowX2
                      },
                disableUsedPeriods: disableUsedDayOverlap
              },
              group
            )}
          </div>
        </div>
      )
    }

    return groups.map((group, groupIdx) => renderGroup(group, `gantt-group-${group.nombre}-${groupIdx}`))
  }, [
    groups,
    showOverlaps,
    disableUsedDayOverlap,
    regionPadding,
    ganttMode,
    renderRows,
    extraColumns,
    ganttState.days.sorted,
    ganttState.months.sortedMondays,
    ganttState.days._map,
    ganttState.months._map,
    renderOverlappingRegions,
    start,
    end,
    disableDrag,
    filterRows
  ])

  return (
    <GanttContext.Provider value={ganttContextValue}>
      <div
        ref={ganttRef}
        className={styles.gantt}
        onScroll={handleOnScroll}
        style={{
          maxHeight,
          maxWidth,
          ...style
        }}
      >
        <GanttHeader
          columns={extraColumns}
          mode={ganttMode}
          scrolled={scrolled}
          title={title === null ? (typeTree == treeMode.AUDITPLAN ? "AUDITORÍA" : "UNIDAD AUDITABLE") : title}
        />

        {renderSeparators()}

        {Array.isArray(groups) && groups.length > 0 ? (
          renderGroups(groups)
        ) : (
          <>
            {renderRows(rows, extraColumns, null)}
            {renderOverlappingRegions({
              items: rows,
              headerSize: ganttMode === GanttMode.Daily ? 40 : 80
            })}
          </>
        )}
      </div>
    </GanttContext.Provider>
  )
}
