import endOfYear from "date-fns/endOfYear"
import startOfYear from "date-fns/startOfYear"
import { dateFormatter } from "src/helpers/dateFormatter"
import { auditStatus, frequencyType } from "../constants/audit"

export const mapAuditStatus = (value) => {
  return Object.values(auditStatus).find((el) => el.value === value)
}

export const getAuditStatus = (audit) => {
  if (!audit) {
    return null
  }

  if (audit.status == "CULMINADO" && audit.fecha_cierre_real !== null) {
    return "CULMINADO_CERRADO"
  }

  return audit.status
}

export const calculateAuditProgressColor = (value) => {
  if (value < 70) {
    return "var(--accent-red)"
  } else if (value < 90) {
    return "var(--accent-orange)"
  } else {
    return "var(--accent-green)"
  }
}

export const mapFrequencyType = (value) => {
  return Object.values(frequencyType).find((el) => el.value === value)
}

export const dateRangeOfAuditYear = (year) => {
  return {
    FIRST_DAY_YEAR: dateFormatter(startOfYear(new Date(year, 0))),
    LAST_DAY_YEAR: dateFormatter(endOfYear(new Date(year, 0)))
  }
}

export const currentDateRange = () => {
  return {
    FIRST_DAY_YEAR: dateFormatter(startOfYear(new Date())),
    LAST_DAY_YEAR: dateFormatter(endOfYear(new Date()))
  }
}

export const buildAuditPermissionValue = (auditData) => {
  return {
    2: ({ currentUserId }) => auditData?.created_by?.id == currentUserId,
    7: ({ currentUserId }) => auditData?.created_by?.id == currentUserId || auditData?.in_team !== null
  }
}

export const formatAuditName = (auditData) => {
  return auditData ? `${auditData.codigo}-${auditData.nombre} (${auditData.ano_auditoria})` : null
}
