export default function TreeSelectable({ data, arrayKey, openKey, renderRow = (_) => {} }) {
  let renderedCount = 0

  const traverseData = (_data, ...indexes) =>
    _data.map((_row, _idx) => {
      const _indexes = [...indexes, _idx]

      const { component: rowComponent, exclude, processedRow } = renderRow(_row, _indexes)

      if (processedRow) {
        _row = processedRow
      }

      const childComponents =
        _row[arrayKey]?.length > 0 ? (
          <div
            style={{
              paddingLeft: typeof _row.padding !== "undefined" ? _row.padding : exclude ? 0 : 15,
              display: _row[openKey] ? "block" : "none"
            }}
          >
            {traverseData(_row[arrayKey], ..._indexes)}
          </div>
        ) : null

      if (!exclude && rowComponent !== null) {
        renderedCount++
      }

      return (
        <div
          key={_row.id}
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div style={{ display: exclude ? "none" : "block" }}>{rowComponent}</div>
          {childComponents}
        </div>
      )
    })

  const content = traverseData(data)

  return renderedCount == 0 ? null : <div style={{ height: "100%", overflow: "auto", width: "100%" }}>{content}</div>
}
