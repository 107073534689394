import cx from "classnames"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import Tooltip from "src/components/Tooltip"
import styles from "./style.module.scss"

export default function DropdownOption(props) {
  const {
    value = null,
    href = null,
    action = null,
    disabled = false,

    ...otherProps
  } = props

  const { t } = useTranslation()

  const isButton = (action === null && href === null) || action !== null

  const onClick = (ev) => {
    if (disabled) {
      return
    }

    action(ev)
  }

  return (
    <div className={cx(styles.dropdownOption, disabled && styles.disabled)} {...otherProps}>
      <Tooltip
        title={t("main_ui.general.disabled_option_title")}
        content={disabled}
        disabled={!disabled || typeof disabled == "boolean"}
      >
        {isButton ? (
          <button
            type='button'
            onClick={onClick}
            className={cx(styles.dropdownButton, disabled && styles.disabled)}
            disabled={!!disabled}
          >
            {t(value, value)}
          </button>
        ) : disabled ? (
          <a className={cx(styles.dropdownButton, disabled && styles.disabled)}>{t(value, value)}</a>
        ) : (
          <Link href={href}>
            <a className={cx(styles.dropdownButton, disabled && styles.disabled)}>{t(value, value)}</a>
          </Link>
        )}
      </Tooltip>
    </div>
  )
}
