import { isValidElement, useState } from "react"
import { Step } from "semantic-ui-react"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import Icon from "src/components/Icon"
import Tooltip from "src/components/Tooltip"
import useTranslation from "src/hooks/useTranslation"
import useWizardStep from "src/hooks/useWizardStep"
import ProjectTree from "../ProjectTree"
import ProjectTypeSwitch from "../ProjectTree/ProjectTypeSwitch"
import SelectProjectForm from "../SelectProjectForm"
import styles from "./style.module.scss"

function NewTreeitemWizard({ choices, onClose }) {
  const { t } = useTranslation()

  const onItemClick = (treeitem) => {
    return () => {
      treeitem.action()
      onClose()
    }
  }

  const filterChoices = (preffix) => choices.filter((x) => x.item_tipo.startsWith(preffix))

  const renderChoices = (choices) => {
    if (choices.length == 0) {
      return null
    }

    return (
      <div className='ui menu vertical'>
        {choices.map((treeitem, k) => {
          return (
            <div key={k} className='link item' onClick={onItemClick(treeitem)}>
              <span>{t(`frontend.auditoria.treeitem.${treeitem.item_tipo.substr(3).toLowerCase()}`)}</span>
              <i className='ui icon caret right' />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={styles.wizardContent}>
      <p style={{ textAlign: "center" }}>
        {t("frontend.auditoria.treeitem.actions.addchildren.choose_treeitem__description")}
      </p>
      {renderChoices(filterChoices("GR"))}
      {renderChoices(filterChoices("HT"))}
    </div>
  )
}

function CopyTreeitemWizard({ choices, onClose }) {
  const { currentStep, gotoStep } = useWizardStep()
  const [projectType, setProjectType] = useState(null)
  const [selectedProjectInfo, setSelectedProjectInfo] = useState(null)
  const [selectedTreeitemIds, setSelectedTreeitemIds] = useState(null)

  const { t } = useTranslation()

  const handleSelectProjectType = (v) => {
    setProjectType(v)
    gotoStep(2)
  }

  const filterSelectableRow = (row) => {
    row.checkable = choices.map((x) => x.item_tipo).includes(row.item_tipo)

    return row
  }

  const renderNavs = ({ disabledNext }) => (
    <div className={styles.navBar}>
      <button onClick={() => gotoStep(currentStep - 1)} className='ui left floated button'>
        {t("main_ui.general.btn_back")}
      </button>
      <Button
        text={currentStep == 3 ? "btn_ok" : "btn_next"}
        disabled={disabledNext}
        onClick={() => gotoStep(currentStep + 1)}
        name='normal'
      />
    </div>
  )

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='ui center aligned basic segment' style={{ padding: 10 }}>
            <p style={{ marginBottom: 10 }}>
              {t("frontend.auditoria.treeitem.actions.addchildren.choose_projecttype_to_copy__description")}
            </p>
            <ProjectTypeSwitch defaultValue={projectType} onSelect={handleSelectProjectType} />
          </div>
        )

      case 2:
        return (
          <div className='ui center aligned basic segment' style={{ padding: 10 }}>
            <p> {t("frontend.auditoria.treeitem.actions.addchildren.choose_project_to_copy__description")}</p>
            <SelectProjectForm
              projectType={projectType}
              value={selectedProjectInfo}
              onSelect={(v) => setSelectedProjectInfo(v)}
            />
            {renderNavs({ disabledNext: !selectedProjectInfo?.id })}
          </div>
        )
      case 3:
        return (
          <>
            <p style={{ marginBottom: 5, float: "left" }}>
              {t("frontend.auditoria.treeitem.actions.addchildren.choose_treeitem_to_copy__description")}
            </p>
            <ProjectTree
              projectType={projectType}
              projectId={selectedProjectInfo?.id}
              defaultValue={selectedTreeitemIds}
              onSelect={(v) => setSelectedTreeitemIds(v)}
              hiddenBarActions
              filterRow={filterSelectableRow}
              maxHeight={"40vh"}
              multiple
            />

            {renderNavs({ disabledNext: !selectedTreeitemIds })}
          </>
        )
    }
  }

  return (
    <div>
      <Step.Group ordered attached='top'>
        {["Tipo de Proyecto", "Proyecto", "Elementos a Copiar"].map((step, idx) => {
          return (
            <Step
              key={idx}
              active={currentStep == idx + 1}
              onClick={() => (typeof step.clickable === "undefined" || step.clickable ? gotoStep(idx + 1, true) : null)}
              completed={currentStep > idx + 1}
              disabled={currentStep < idx + 1}
            >
              <Step.Content>
                <Step.Title>{step}</Step.Title>
              </Step.Content>
            </Step>
          )
        })}
      </Step.Group>
      <div className='ui attached segment'>{renderStepContent()}</div>
    </div>
  )
}

export default function CreateTreeItemAction({ item }) {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (item.disabled) {
      return
    }

    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (item.options?.length == 0) {
    return null
  }

  return (
    <>
      <Tooltip
        containerStyle={{ cursor: "pointer" }}
        content={t("frontend.auditoria.treeitem.actions.addchildren.title")}
      >
        <button ref={item.ref} type='button' onClick={onClick} className={styles.buttonAction}>
          {isValidElement(item.icon) ? (
            item.icon
          ) : typeof item.icon === "string" ? (
            <i className={`ui icon ${item.icon} `} />
          ) : (
            <Icon size={item.icon.size ?? 24} name={item.icon.name} color={item.icon.color} />
          )}
        </button>
      </Tooltip>
      {open && (
        <Dialog isOpen>
          <Dialog.Header theme='light' handleClose={onClose} buttonClose>
            {t("frontend.auditoria.treeitem.actions.addchildren.title")}
          </Dialog.Header>
          <Dialog.Body theme='light'>
            <NewTreeitemWizard choices={item.options} onClose={onClose} />
          </Dialog.Body>
        </Dialog>
      )}
    </>
  )
}
