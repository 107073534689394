import cx from "classnames"
import React, { useEffect, useState } from "react"
import ErrorBoundary from "src/errors/ErrorBoundary"
import Loading from "../Loading"
import Portal from "../Portal"
import SideModalCloser from "../SidenavContainer/SideModal/SideModalCloser"
import styles from "./style.module.scss"

export default function IndependentSideModal({
  open = false,
  className = "",
  onFinish = () => {},
  children,
  showCurtain = false,
  closeConfig
}) {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const makeOnFinish = (handler = null) => {
    if (handler === null) {
      return () => {
        setIsOpen(false)
      }
    }

    return () => {
      setIsOpen(false)

      handler()
    }
  }

  const handleOnFinish = makeOnFinish(onFinish)

  const overlayOnClick = (event) => {
    event.preventDefault()

    handleOnFinish()
  }

  const handleEscapeKeystroke = (event) => {
    if (event.keyCode !== 27) {
      return
    }

    handleOnFinish()
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKeystroke, false)

    return () => {
      document.removeEventListener("keydown", handleEscapeKeystroke, false)
    }
  }, [open])

  const dialogClassName = cx("sidemodal", styles.dialog, className)

  return !isOpen ? null : (
    <Portal>
      <div className={styles.overlay} onClick={overlayOnClick} />

      <div className={dialogClassName}>
        <SideModalCloser config={closeConfig} onClose={handleOnFinish} onTextClick={() => {}} />
        <ErrorBoundary>
          <div className={styles.wrapperDialog}>{children}</div>
        </ErrorBoundary>
        {showCurtain && <Loading curtain />}
      </div>
    </Portal>
  )
}
