/**
 * Debounces a function, ensuring it is only called after a specified wait time has passed since the last invocation.
 *
 * @param {Function} func - The function to be debounced.
 * @param {number} wait - The number of milliseconds to wait before invoking the function.
 *
 * @returns {Function} - The debounced function.
 */
export function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}
