import { rowBackendType } from "src/constants/rowTypes"

export const checkTypeElements = (key) => {
  let isTypeGrouper = false
  let isTypeWorksheet = false
  switch (key) {
    case rowBackendType.GR_PHASE:
    case rowBackendType.GR_MACROPROCESS:
    case rowBackendType.GR_PROCESS:
    case rowBackendType.GR_ACTIVITY:
      isTypeGrouper = true
      break
    case rowBackendType.HT_UNDERSTANDING:
    case rowBackendType.HT_RISK:
    case rowBackendType.HT_CONTROL:
    case rowBackendType.HT_TEST:
    case rowBackendType.HT_FINDING:
    case rowBackendType.HT_STEP:
    case rowBackendType.HT_LINK:
    case rowBackendType.HT_SURVEY:
    case rowBackendType.HT_REF:
      isTypeWorksheet = true
      break
    default:
      break
  }
  return {
    isTypeGrouper,
    isTypeWorksheet
  }
}
