import React, { useEffect, useState } from "react"
import styles from "../RadioButton/style.module.scss"

export default function RadioButtonAlone({ children, style, onClick, selected = false, disabled, color = "#ed4138" }) {
  const [radioSelected, setRadioSelected] = useState(selected)

  useEffect(() => {
    setRadioSelected(selected)
  }, [selected])

  const onChangeHandler = () => {
    if (disabled) return

    onClick()
  }

  return (
    <div className={styles.radio} style={{ ...style }}>
      <label
        className={styles.radioLabel}
        style={{
          cursor: disabled ? "not-allowed" : "",
          opacity: disabled ? 0.7 : ""
        }}
        onClick={onChangeHandler}
      >
        <div className={styles.radioContainer}>
          <div
            className={styles.radioOuterCircle}
            style={{
              backgroundColor: `${radioSelected ? color : "#fff"}`
            }}
          />
        </div>
        {children && <div className={styles.radioLabelContent}>{children}</div>}
      </label>
    </div>
  )
}
