import React from 'react'

export default function TableContent({background='#fff', children}) {
  return (
    // <tbody style={{backgroundColor: background}}>
    //   {children}
    // </tbody>
    <div style={{backgroundColor: background}}>
      {children}
    </div>
  )
}