import {
  impact,
  inherentRisk,
  probability,
  residualRisk,
  riskEvaluation,
  controlSolidity
} from '../constants/risk'

export const mapInherentRisk = (id) => {
  return Object.values(inherentRisk).find((el) => el.id === id)
}

export const mapResidualRisk = (id) => {
  return Object.values(residualRisk).find((el) => el.id === id)
}

export const mapImpactRisk = (value) => {
  return Object.values(impact).find((el) => el.value === value)
}

export const mapRiskEvaluation = (value) => {
  return Object.values(riskEvaluation).find((el) => el.value === value)
}

export const mapRiskProbability = (value) => {
  return Object.values(probability).find((el) => el.value === value)
}

export const mapRiskControlSolidity = (id) => {
  return Object.values(controlSolidity).find((el) => el.id === id)
}
