export const hardcodedAuditId = "7e97471d-7ca6-4440-9168-6e54ff12ae88"

export const auditStatus = Object.freeze({
  NO_STARTED: {
    value: "SIN INICIAR",
    color: "#ed4138",
    colorDarken: "#A30C04",
    label: "Sin Iniciar"
  },
  IN_PROCESS: {
    value: "EN PROCESO",
    color: "#ffe543",
    colorDarken: "#B9A007",
    label: "En Proceso"
  },
  CONCLUDED: {
    value: "CULMINADO",
    color: "#00a750",
    colorDarken: "#006833",
    colorFg: "#fff",
    label: "Culminado"
  },
  DISCONTINUED: {
    value: "ANULADO",
    color: "#56aaff",
    colorDarken: "#0D82FB",
    colorFg: "#fff",
    label: "Anulado"
  },
  POSTPONED: {
    value: "APLAZADO",
    color: "#662e93",
    colorDarken: "#3B0A63",
    colorFg: "#fff",
    label: "Aplazado"
  }
})

export const frequencyType = Object.freeze({
  PROGRAMMED: {
    value: "PRG",
    label: "Programada"
  },
  SPECIAL: {
    value: "ESP",
    label: "Especial"
  }
})

export const COPY_OPTIONS_SCHEMA = [
  {
    label: "frontend.auditoria.treeitem.riesgo__list",
    name: "riesgo",
    items: [
      { label: "Información del Riesgo", name: "riesgo", default: true },
      { name: "riesgo_calificacion", label: "Calificación del Riesgo" }
    ]
  },
  {
    label: "frontend.auditoria.treeitem.control__list",
    typeTree: "auditPlan",
    projectType: "auditoria",
    name: "control",
    items: [
      { label: "Información del Control", name: "control", readOnly: true },
      { label: "Horas presupuestadas", name: "control_horas" },
      { label: "Evaluación del Diseño", name: "control_evaluacion" }
    ]
  },
  {
    label: "frontend.auditoria.treeitem.control_test__list",
    typeTree: "auditPlan",
    projectType: "auditoria",
    name: "control_test",
    depends: ["control"],
    items: [
      { label: "Información de la Validación", name: "control", readOnly: true },
      { label: "Horas presupuestadas", name: "control_test_horas" },
      { label: "Evidencia Documentada", name: "control_test_evidencia" },
      { label: "Calificación Eficiencia Operativa", name: "control_test_calificacion" }
    ]
  },
  {
    label: "frontend.auditoria.treeitem.paso__list",
    typeTree: "auditPlan",
    projectType: "auditoria",
    name: "step",
    items: [
      { label: "Instrucciones y Objetivo", name: "step", readOnly: true },
      { label: "Evidencia", name: "step_evidencia" },
      { label: "Horas presupuestadas", name: "step_horas" }
    ]
  },
  {
    label: "frontend.auditoria.treeitem.hallazgo__list",
    typeTree: "auditPlan",
    projectType: "auditoria",
    name: "hallazgo",
    items: [
      { label: "Información del Hallazgo", name: "hallazgo", readOnly: true },
      { label: "Impacto", name: "hallazgo_impacto" }
    ]
  },
  {
    label: "frontend.auditoria.treeitem.entendimiento__list",
    name: "entendimiento",
    items: [
      { label: "Levantamiento del Proceso", name: "entendimiento_descripcion1" },
      { label: "Prueba de Recorrido", name: "entendimiento_descripcion2" },
      { label: "Flujograma del Proceso", name: "entendimiento_flujograma" }
    ]
  },
  {
    label: "Archivos Adjuntos",
    name: "attachments"
  }
]
/**
 *
 * @param {array} only - Option's names to include in filtered.
 * @param {object} options
 * @param {array|false} options.include_items -
 * @param {object} options.overwrite - options to overwrite.
 *
 * @returns array - filtered options
 */
export const filterAuditCopyOptionsSchema = (only, { include_items, overwrite = {} } = {}) => {
  let filtered = JSON.parse(JSON.stringify(COPY_OPTIONS_SCHEMA.filter((x) => only.includes(x.name))))

  filtered = filtered.map((x) => {
    if (typeof overwrite[x.name] !== "undefined") {
      return { ...x, ...overwrite[x.name] }
    }
    return x
  })

  if (filtered.length == 1 && typeof filtered[0].name !== "undefined") {
    delete filtered[0].name
    if (include_items) {
      filtered[0].items = filtered[0].items.concat(include_items)
    }
  }

  if (include_items === false) {
    return filtered.map((x) => {
      delete x.items
      return x
    })
  }

  return filtered
}
