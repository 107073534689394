import { executionValidation } from '../constants/test'

export const mapExecutionValidation = (id) => {
  switch (id) {
    case executionValidation.INEFFECTIVE.value:
      return executionValidation.INEFFECTIVE
    case executionValidation.DEFICIENT.value:
      return executionValidation.DEFICIENT
    case executionValidation.EFFECTIVE.value:
      return executionValidation.EFFECTIVE
    default:
      return null
  }
}
