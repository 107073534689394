import { rowFrontendType } from "../constants/rowTypes"

export function buildAuditBreadcumb(auditData, sections, lastSection) {
  const breadcrumb =
    auditData === null
      ? ["@dashboard"]
      : [
          { name: "dashboard", router: { pathname: "/dashboard" } },
          {
            name: "auditoria__plan",
            router: {
              pathname: "/",
              query: {
                año: auditData.ano_auditoria
              }
            }
          },
          {
            name: `${auditData.codigo}-${auditData.nombre}`,
            router: {
              pathname: "/auditorias/",
              query: {
                "auditoria-id": auditData.id
              }
            }
          }
        ]

  sections?.forEach(function (section) {
    if (typeof section === "string") {
      section = {
        name: section,
        pathname: `/auditorias/${section}`
      }
    }

    breadcrumb.push({
      name: `auditoria__${section.name}`,
      router: {
        pathname: `/auditorias/${section.pathname}/`,
        query: {
          "auditoria-id": auditData.id,
          ...section.query
        }
      }
    })
  })

  if (lastSection) {
    breadcrumb.push({
      name: [`auditoria__${lastSection}`, lastSection]
    })
  }

  return breadcrumb
}

export function findBreadcrumbs(indexes = [], rows = []) {
  const breadcrumbs = []

  const findFn = (_indexes = [], _rows = []) => {
    const toFind = _indexes[0]
    _indexes.shift()
    const rowFound = _rows !== null ? _rows[toFind] : null

    const goNext = () => {
      if (_indexes.length > 0) {
        findFn(_indexes, rowFound?.items)
      }
    }

    if (typeof rowFound === "undefined" || rowFound === null) {
      return goNext()
    }

    breadcrumbs.push([rowFound, toFind])

    return goNext()
  }

  findFn(indexes, rows)

  return breadcrumbs
}

export function breadcrumbsFromGroupsOnly(indexes = [], rows = []) {
  return findBreadcrumbs(indexes, rows)
    .filter(([row]) => {
      switch (row.type) {
        case rowFrontendType.MACROPROCESS:
        case rowFrontendType.PROCESS:
        case rowFrontendType.SUB_PROCESS:
          return true
        default:
          return false
      }
    })
    .map(([row]) => {
      const rowName = row.name

      switch (row.type) {
        case rowFrontendType.MACROPROCESS:
          return `${rowName}`
        case rowFrontendType.PROCESS:
          return `${rowName}`
        case rowFrontendType.SUB_PROCESS:
          return `${rowName}`
      }

      return rowName
    })
}

export const cleanHtml = (html) => {
  const temp = document.createElement("div")
  temp.innerHTML = html
  return temp.textContent || temp.innerText || ""
}

export function getBreadcrumbs(indexes = [], rows = []) {
  return findBreadcrumbs(indexes, rows).map(([row]) => {
    let name = row.name

    switch (row.type) {
      case rowFrontendType.RISK:
      case rowFrontendType.CONTROL:
        name = row.codigo
        break

      case rowFrontendType.FINDING:
        name = row.codigo ?? "-"
        break
    }

    return cleanHtml(name)
  })
}
