import { cloneDeep } from "lodash"
import { rowFrontendType } from "../../constants/rowTypes"
import { executionValidation } from "../../constants/test"
import { worksheetsModelStatus } from "../../helpers/map-statuses"

export const getExecutionAverage = (_item) => {
  let executionAverage = 0

  if (!_item.items) return executionAverage

  const validationList = _item.items.filter((el) => el.type === rowFrontendType.TEST)

  if (validationList.length === 0) return executionAverage

  const executionList = validationList.map((el) => +el.validacion_ejecucion)

  const executionSum = executionList.length > 0 ? executionList.reduce((total, val) => total + val) : 0

  const average = executionSum / validationList.length

  if (average < 3 && average >= 1) {
    executionAverage = executionValidation.INEFFECTIVE.value
  } else if (average < 4.5 && average >= 3) {
    executionAverage = executionValidation.DEFICIENT.value
  } else if (average >= 4.5) {
    executionAverage = executionValidation.EFFECTIVE.value
  }

  return executionAverage
}

export const getProgress = (item) => {
  const worksheetsModelStatusCopy = cloneDeep(worksheetsModelStatus)

  Object.values(worksheetsModelStatusCopy).forEach((value) => {
    value.amount = 0
  })

  const getSum = (obj, key) => {
    return Object.values(obj).reduce((valueTotal, value) => {
      return valueTotal + value[key]
    }, 0)
  }

  const calculateAmount = (data) => {
    data?.forEach((el) => {
      const found = Object.values(worksheetsModelStatusCopy).find((_el) => _el.value === el.status)

      if (
        el.item_tipo != "HT_INSPECCION" &&
        el.item_tipo != "HT_EVALUACION" &&
        el.item_tipo != "HT_PLAN_ACCION" &&
        found
      ) {
        found.amount++
      }

      if (el.items && el.items.length > 0) {
        calculateAmount(el.items)
      }
    })
  }

  const calculatePercentage = () => {
    const worksheetsTotal = getSum(worksheetsModelStatusCopy, "amount")

    Object.values(worksheetsModelStatusCopy).forEach((value) => {
      value.percentage = value.amount / worksheetsTotal

      if (isNaN(value.percentage)) {
        value.percentage = 0
      }
    })
  }

  const calculateProgress = () => {
    Object.values(worksheetsModelStatusCopy).forEach((value) => {
      value.progress = value.percentage * value.score
    })
  }

  calculateAmount(item.items)

  calculatePercentage()

  calculateProgress()

  const progress = getSum(worksheetsModelStatusCopy, "progress")

  return progress
}

export const renameTests = (_item) => {
  let testList = []

  if (_item.items) {
    testList = _item.items.filter((el) => el.type === rowFrontendType.TEST)

    if (testList.length > 0) {
      testList.forEach((elem, i) => {
        elem.name = `Validación ${i + 1}`
      })
    }
  }
}
