import React, { useContext } from 'react'
import { UserContext } from '../../UserContext'

export default function TableHeaderItem({
  align = 'center',
  color = '#424242',
  children,
  width = '',
  style = {}
}) {
  const { childrenSize } = useContext(UserContext)
  return (
    <div
      style={{
        width: width === '' ? `calc(100% / ${childrenSize})` : `${width}%`,
        color: color,
        padding: '8px',
        fontWeight: 600,
        textAlign: align,
        ...style
      }}
    >
      {children}
    </div>
  )
}
