import cx from "classnames"
import useConfirm from "src/hooks/useConfirm"
import useTranslation from "src/hooks/useTranslation"
import Button from "../Button"
import Icon from "../Icon"
import Tooltip from "../Tooltip"
import styles from "./style.module.scss"

export function DeleteItemActioButton({ onClick, iconSize = 16, confirmTooltip = "Eliminar", icon = null }) {
  const { t } = useTranslation()

  return (
    <Tooltip containerStyle={{ cursor: "pointer" }} content={t(confirmTooltip)}>
      <button className={styles.button} onClick={onClick}>
        {icon ? icon : <i className={cx("ui icon trash alternate", styles.icon)} style={{ fontSize: iconSize }} />}
      </button>
    </Tooltip>
  )
}
export function DeleteItemAction({
  confirmText,
  onDelete,
  icon = null,
  iconSize = 16,
  disabledText = null,
  confirmTooltip = null
}) {
  const { confirm: confirmDelete, Confirm: ConfirmDelete } = useConfirm()
  const { t } = useTranslation()

  if (confirmTooltip === null) {
    confirmTooltip = t("main_ui.general.btn_delete")
  }

  const showConfirm = async (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    const isConfirmed = await confirmDelete(t(confirmText), {
      componentType: "YesNo",
      confirmButtonText: t("main_ui.general.lb_yes__delete"),
      cancelButtonText: "No"
    })
    if (isConfirmed) {
      onDelete()
    }
  }

  if (disabledText && typeof disabledText == "object") {
    const f = Object.entries(disabledText).find(([text, condition]) => condition)
    disabledText = f ? f[0] : null
  }

  if (disabledText !== null) {
    return (
      <Button
        className={styles.button}
        disabledText={t(disabledText)}
        disabledHeaderText={`${t("main_ui.general.lb_disabled_option_title")} | ${t("main_ui.general.lb_yes__delete")}`}
        type='tertiary'
        text={<Icon name='delete' color='#424242' className={styles.icon} size={iconSize} />}
      />
    )
  }

  return (
    <div>
      <DeleteItemActioButton onClick={showConfirm} icon={icon} iconSize={iconSize} confirmTooltip={confirmTooltip} />
      <ConfirmDelete />
    </div>
  )
}
