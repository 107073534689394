export default function formatMessage({ type, button, motive }) {
  let nameType
  switch (type) {
    case 'disabled':
      nameType = 'ACCIÓN DESHABILITADA'
      break
    default:
      break
  }
  const format = (
    <div
      className='tooltip-disable-alert'
      style={{
        display: 'flex',
        gap: 5,
        flexDirection: 'column',
        width: 'fit-content'
      }}
    >
      <div className='title' style={{ display: 'flex', gap: 5 }}>
        <div>{nameType}</div>
        <div>|</div>
        <div>{button.toUpperCase()}</div>
      </div>
      <div className='body' style={{ display: 'flex', gap: 5 }}>
        <div>
          <strong style={{ fontWeight: 600 }}>Motivo:</strong> {motive}
        </div>
      </div>
    </div>
  )

  return format
}
