import formatFunc from "date-fns/format"
import es from "date-fns/locale/es"
import startOfDay from "date-fns/startOfDay"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { settings } from "src/constants/userSettings"
import { dateParser } from "src/helpers/dateFormatter"
import FormErrors from "../FormErrors"
import FormField from "../FormField"
import styles from "./style.module.scss"

export default function SingleDatePicker({
  twoPoints = true,
  bold = false,
  color = "#e6e6e6",
  label = null,
  name = null,
  value = null,
  exclude = [],
  onChange = null,
  min = null,
  max = null,
  disabled = false,
  placeholder = null,
  positionLabel,
  className = null,
  inputWrapperStyle = null,
  errorsForm = false,
  required = false,
  ...otherProps
}) {
  const [startDate, setStartDate] = useState(null)

  useEffect(() => {
    if (value === null || typeof value !== "string" || value === "") {
      setStartDate(null)
      return
    }

    try {
      setStartDate(dateParser(value))
    } catch {
      setStartDate(null)
    }
  }, [value])

  const onChangeFactory = () => {
    if (typeof onChange !== "undefined" && onChange !== null) {
      return (date) => {
        if (date === null) {
          setStartDate(null)

          onChange([null, null])
          return
        }
        const _date = startOfDay(date)

        try {
          const formatted = formatFunc(_date, "yyyy-MM-dd", { locale: es })
          setStartDate(_date)
          onChange([_date, formatted])
        } catch (e) {
          setStartDate(null)
          onChange([null, null])
        }
      }
    }

    return (date) => {
      setStartDate(startOfDay(date))
    }
  }

  const ArrowDownIcon = ({ color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
      <path fill='none' d='M0 0h24v24H0V0z' />
      <path fill={color} d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
    </svg>
  )

  const DatePickerInput = forwardRef((args, ref) => {
    const { onClick, onBlur, onChange, onFocus, onKeyDown, value: inputValue } = args
    const inputRef = useRef(null)

    return (
      <>
        <FormField
          label={label}
          positionLabel={positionLabel}
          inputWrapperStyle={inputWrapperStyle}
          bold={bold}
          twoPoints={twoPoints}
          color={color}
          required={required}
          classRequired={errorsForm}
          disabled={disabled}
        >
          <div ref={ref} className={styles.inputWrapper}>
            <input
              ref={inputRef}
              type='text'
              name={name}
              onClick={onClick}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              value={inputValue}
              placeholder={placeholder}
              disabled={disabled}
              {...otherProps}
            />
            {!disabled && (
              <div
                className={styles.arrowDown}
                onClick={() => {
                  inputRef.current.focus()
                }}
              >
                <ArrowDownIcon color='#808080' />
              </div>
            )}
          </div>
        </FormField>
        <FormErrors errors={errorsForm} style={{ marginTop: 5 }} />
      </>
    )
  })

  const parseMin = useMemo(() => {
    return min instanceof Date && !isNaN(min.valueOf()) ? min : dateParser(min)
  }, [min])

  const parsedMax = useMemo(() => {
    return max instanceof Date && !isNaN(max.valueOf()) ? max : dateParser(max)
  }, [max])

  return (
    <div className={className !== null ? className : undefined}>
      <DatePicker
        locale={es}
        excludeDates={exclude}
        selected={startDate}
        showYearDropdown={true}
        showMonthDropdown={true}
        fixedHeight={true}
        yearDropdownItemNumber={3}
        minDate={parseMin}
        maxDate={parsedMax}
        dateFormat={settings.dateFormat}
        onChange={onChangeFactory()}
        customInput={<DatePickerInput />}
        portalId='portal-reactdatepicker'
      />
    </div>
  )
}
