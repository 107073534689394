import React from "react"
import { UserContext } from "./UserContext"

export default function Table({ children, columnCount, style }) {
  const childrenSize = columnCount ?? children[0].props.children.length
  return (
    <UserContext.Provider
      value={{
        childrenSize
      }}
    >
      <div style={{ ...style }}>{children}</div>
    </UserContext.Provider>
  )
}
