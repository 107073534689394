import formatFunc from 'date-fns/format'

export const signatureFormatter = (data) => {
  let formattedData = []

  if (data) {
    formattedData = data.map(({ created_by: { name, sigla }, created_at }) => {
      return {
        label: sigla,
        name,
        role: '',
        date: formatFunc(new Date(created_at), 'yyyy-MM-dd')
      }
    })
  }

  return formattedData
}

export const getFormattedSignatureData = (signing) => {
  const completes = signatureFormatter(signing?.completado)
  const reviews = signatureFormatter(signing?.revisado)

  const isCompleted = signing?.completado.length > 0 ? true : false
  const isReviewed = signing?.revisado.length > 0 ? true : false

  return {
    completes,
    reviews,
    isCompleted,
    isReviewed
  }
}

export const getFormattedFindingsSignatureData = (signing) => {
  const completes = signatureFormatter(signing?.completers)
  const reviews = signatureFormatter(signing?.reviewers)

  const isCompleted = signing?.completers.length > 0 ? true : false
  const isReviewed = signing?.reviewers.length > 0 ? true : false

  return {
    completes,
    reviews,
    isCompleted,
    isReviewed
  }
}
