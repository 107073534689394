import format from "date-fns/format"
import es from "date-fns/locale/es"
import React, { useCallback, useContext } from "react"
import GanttHeaderItem from "../GanttHeaderItem"
import { GanttContext, GanttMode } from "../index"
import styles from "./style.module.scss"

export default function GanttHeader({
  columns = [],
  scrolled = {
    hasScrolled: false,
    scrolledX: 0,
    scrolledY: 0,
    hasScrolledY: false
  },
  mode = GanttMode.Monthly,
  title = ""
}) {
  const { state: ganttState } = useContext(GanttContext)

  const renderHeaderItems = useCallback(() => {
    switch (mode) {
      case GanttMode.Daily: {
        return ganttState.days.sorted.map((day, idx) => (
          <GanttHeaderItem key={`gant-header-item-${idx}`}>
            {format(day, "MMM. dd", { locale: es })}
          </GanttHeaderItem>
        ))
      }

      case GanttMode.Monthly: {
        return ganttState.months.sortedMondays.map((day, idx) => (
          <GanttHeaderItem
            key={`gant-header-item-${idx}`}
            style={{
              lineHeight: "2em",
              textAlign: "center"
            }}
          >
            {format(day, "dd", { locale: es })}
          </GanttHeaderItem>
        ))
      }

      default: {
        return null
      }
    }
  }, [mode, ganttState.days.sorted, ganttState.months.sortedMondays])

  const renderHeaderMonths = useCallback(() => {
    if (mode !== GanttMode.Monthly) {
      return null
    }

    return ganttState.months.sorted.map((month, monthIdx) => (
      <GanttHeaderItem
        key={`gant-header-month-${monthIdx}`}
        style={{
          width: month.mondays.length * 35,
          maxWidth: month.mondays.length * 35,
          lineHeight: "2em",
          textAlign: "center"
        }}
      >
        {format(month.mondays[0], "MMMM", { locale: es })} - {month.year}
      </GanttHeaderItem>
    ))
  }, [mode, ganttState.months.sorted])

  const hasScrolledClassName = scrolled.hasScrolled
    ? ` ${styles.headerTitleSticky}`
    : ""
  const headerTitleClassName = `${styles.headerTitle}${hasScrolledClassName}`

  const titleIsStickyClassName = scrolled.hasScrolled
    ? ` ${styles.headerContainerHasSticky}`
    : ""
  const headerContainerClassName = `${styles.headerContainer}${titleIsStickyClassName}`

  const renderExtraColumns = useCallback(() => {
    if (!Array.isArray(columns) || columns.length === 0) {
      return null
    }

    const columnHeight = mode === GanttMode.Daily ? 40 : 80

    const renderedColumns = columns.map((column, columnIdx) => {
      return (
        <GanttHeaderItem
          key={`gantt-header-extra-column-${columnIdx}`}
          style={{
            left: scrolled.hasScrolled ? scrolled.scrolledX : "auto",
            height: columnHeight,
            position: "relative",
            zIndex: 901
          }}
        >
          {column.title ?? "-"}
        </GanttHeaderItem>
      )
    })

    return (
      <>
        {renderedColumns}
        <div style={{ display: "none" }} />
      </>
    )
  }, [columns, mode, scrolled])

  const render = useCallback(() => {
    return (
      <div
        className={headerContainerClassName}
        style={{
          maxHeight: mode === GanttMode.Daily ? 40 : 80,
          top: scrolled.hasScrolledY ? scrolled.scrolledY : "auto"
        }}
      >
        <div
          className={headerTitleClassName}
          style={{
            left: scrolled.hasScrolled ? scrolled.scrolledX : "auto",
            height: mode === GanttMode.Monthly ? 80 : 40
          }}
        >
          <p
            style={{
              marginTop: mode === GanttMode.Monthly ? "30px" : "10px",
              textAlign: "center"
            }}
          >
            {title}
          </p>
        </div>

        <div>{renderExtraColumns()}</div>
        <div>
          {mode === GanttMode.Monthly ? (
            <div
              style={{
                display: "flex",
                height: 40
              }}
            >
              {renderHeaderMonths()}
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              height: 40
            }}
          >
            {renderHeaderItems()}
          </div>
        </div>
      </div>
    )
  }, [
    mode,
    scrolled,
    renderExtraColumns,
    renderHeaderMonths,
    renderHeaderItems,
    ganttState.months.sorted,
    ganttState.days.sorted,
    ganttState.months.sortedMondays
  ])

  return render()
}
