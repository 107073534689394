import addDays from "date-fns/addDays"
import formatFunc from "date-fns/format"
import isEqual from "date-fns/isEqual"
import isFunction from "lodash/isFunction"
import map from "lodash/map"
import React, { cloneElement, useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { Permissions } from "src/constants/permissions"
import { rowFrontendType } from "src/constants/rowTypes"
import { treeMode } from "src/constants/tree"
import { BAR_COLUMN_WIDTH, calculateBarWidth, calculateWidth } from "src/helpers/gantt"
import useAccessControl from "src/hooks/useAccessControl"
import useDispatch from "../../../store"
import GanttModal from "../GanttModal"
import GanttRowBar from "../GanttRowBar"
import { GanttMode, makeDefaultGanttState } from "../index"
import { findCoords } from "../utils"
import styles from "./style.module.scss"

export function GanttRowDropdownIcon({ color, size = 24, ...otherProps }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox={`0 0 ${size} ${size}`} width={size} {...otherProps}>
      <path fill='none' d={`M0 0h${size}v${size}H0V0z`} />
      <path d={`M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z`} />
    </svg>
  )
}

export default function GanttRow({
  label = "",
  emphasis = false,
  important = false,
  open = false,
  children = null,
  level = 1,
  itemId = "",
  start,
  end,
  mode = GanttMode.Daily,
  className = null,
  group = null,
  progress = "100%",
  filterization = {
    startsBeforeFilter: false,
    endsAfterFilter: false,
    disabled: false,
    ganttFilters: {
      start: null,
      end: null
    }
  },
  ganttState = makeDefaultGanttState(),
  responsible = {
    email: null,
    id: null,
    name: null,
    sigla: null
  },
  onToggle = null,
  type = null,
  scrolled = {
    hasScrolled: false,
    scrolledX: 0
  },
  regionPadding = 0,
  responsibles = [],
  columns = [],
  tooltip = {
    title: "Detalle de la actividad",
    width: null,
    entries: []
  },
  duration = 0,
  barColour = "#808080",
  statusLabel = "Desconocido",

  rowIcon = null,
  supportsEditModal = true,

  row = {},

  onRowUpdate = () => {},

  onDrag = () => {
    // Same args as onRowUpdate
    return Promise.resolve()
  },
  disableDrag = false,
  typeTree = treeMode.AUDITPLAN
}) {
  const { hasPermission } = useAccessControl()
  const dispatch = useDispatch()

  const hasChildren = Array.isArray(children) && children.length > 0
  const [cannotDrag, setCannotDrag] = useState(true)
  const [isOpen, setIsOpen] = useState(open)
  const [openEdit, setOpenEdit] = useState(false)
  const [rerenderKey, forceRerender] = useReducer((x) => x + 1, 0)

  // Start and end dates
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [startIdx, setStartIdx] = useState(-1)
  const [endIdx, setEndIdx] = useState(-1)

  useEffect(() => {
    if (start !== null && !isEqual(start, startDate)) {
      setStartDate(start)
    }

    if (end !== null && !isEqual(end, endDate)) {
      setEndDate(end)
    }
  }, [start, end])

  useEffect(() => {
    if (startDate === null || endDate === null) {
      return
    }

    const startsBeforeFilter = filterization.startsBeforeFilter ?? false
    const endsAfterFilter = filterization.endsAfterFilter ?? false
    const disabled = startsBeforeFilter || endsAfterFilter

    const rowCoords = findCoords(startDate, endDate, {
      mode,
      daysMap: ganttState.days._map,
      monthsMap: ganttState.months._map
    })

    if (!disabled) {
      setStartIdx(rowCoords?.x1 ?? -1)
      setEndIdx(rowCoords?.x2 ?? -1)

      return
    }

    if (mode === GanttMode.Monthly) {
      setStartIdx(startsBeforeFilter ? 0 : rowCoords?.x1 ?? -1)
      setEndIdx(endsAfterFilter ? ganttState.months.sortedMondays.length - 1 : rowCoords?.x2 ?? -1)

      return
    }

    if (mode === GanttMode.Daily) {
      setStartIdx(startsBeforeFilter ? 0 : rowCoords?.x1 ?? -1)
      setEndIdx(endsAfterFilter ? ganttState.days.sorted.length - 1 : rowCoords?.x2 ?? -1)

      return
    }

    setStartIdx(-1)
    setEndIdx(-1)
  }, [
    mode,
    startDate,
    endDate,
    ganttState.days._map,
    ganttState.months._map,
    ganttState.days.sorted,
    ganttState.months.sortedMondays,
    filterization.startsBeforeFilter,
    filterization.endsAfterFilter
  ])

  useEffect(() => {
    forceRerender()
  }, [ganttState.days._map, ganttState.months._map, ganttState.days.sorted, ganttState.months.sortedMondays])

  useEffect(() => {
    switch (type) {
      case rowFrontendType.BUSINESS_UNIT:
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS:
      case rowFrontendType.PHASE:
      case rowFrontendType.STEP: {
        setCannotDrag(true)
        break
      }
      default: {
        setCannotDrag(false)
        break
      }
    }
  }, [type])

  // noinspection DuplicatedCode
  const handleOnDrag = useCallback(
    (jumps = 0) => {
      const startDateNeedle = jumps - 1
      const diff = endIdx - startIdx
      const endDateNeedle = startDateNeedle + diff
      let selectedStartDate, selectedEndDate

      switch (mode) {
        case GanttMode.Daily: {
          selectedStartDate = ganttState.days.sorted[startDateNeedle]
          selectedEndDate = ganttState.days.sorted[endDateNeedle]
          break
        }

        case GanttMode.Monthly: {
          selectedStartDate = ganttState.months.sortedMondays[startDateNeedle]
          selectedEndDate = ganttState.months.sortedMondays[endDateNeedle]
          break
        }

        default: {
          return
        }
      }

      return onDrag(
        {
          startDate: formatFunc(selectedStartDate, "yyyy-MM-dd"),
          endDate: formatFunc(selectedEndDate, "yyyy-MM-dd"),
          group
        },
        itemId,
        dispatch
      )
    },
    [mode, startIdx, endIdx, ganttState.days.sorted, ganttState.months.sortedMondays, group]
  )

  // noinspection DuplicatedCode
  const handleOnResize = useCallback(
    (startJumps = 0, endJumps = 0) => {
      const startDateNeedle = startJumps - 1
      const endDateNeedle = endJumps - 1

      let selectedStartDate, selectedEndDate

      switch (mode) {
        case GanttMode.Daily: {
          selectedStartDate = ganttState.days.sorted[startDateNeedle]
          selectedEndDate = ganttState.days.sorted[endDateNeedle]
          break
        }

        case GanttMode.Monthly: {
          selectedStartDate = ganttState.months.sortedMondays[startDateNeedle]
          selectedEndDate =
            startDateNeedle === endDateNeedle
              ? addDays(ganttState.months.sortedMondays[endDateNeedle], 6)
              : ganttState.months.sortedMondays[endDateNeedle]
          break
        }

        default: {
          return
        }
      }

      return onDrag(
        {
          startDate: formatFunc(selectedStartDate, "yyyy-MM-dd"),
          endDate: formatFunc(selectedEndDate, "yyyy-MM-dd"),
          group
        },
        itemId,
        dispatch
      )
    },
    [mode, startIdx, endIdx, ganttState.days.sorted, ganttState.months.sortedMondays, group]
  )

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const onToggleCallback = useCallback(() => {
    if (typeof onToggle === "undefined" || onToggle === null || !isFunction(onToggle)) {
      setIsOpen(!isOpen)

      return
    }

    const reversed = !isOpen
    onToggle(reversed)
    setIsOpen(reversed)
  }, [isOpen, onToggle])

  const makeOnLabelClick = () => {
    if (!hasChildren) {
      return undefined
    }

    return (event) => {
      event.preventDefault()
      event.stopPropagation()

      onToggleCallback()
    }
  }

  const saveEditChanges = useCallback(
    (
      changes = {
        startDate: "",
        endDate: ""
      }
    ) => {
      onRowUpdate({ changes, group }, itemId, dispatch)
    },
    [onRowUpdate, group]
  )

  const openEditModal = useCallback(() => {
    if (!supportsEditModal) {
      return
    }

    if (openEdit) {
      return
    }

    setOpenEdit(true)
  }, [openEdit, supportsEditModal])

  const finishEditChanges = useCallback(() => {
    if (!openEdit) {
      return
    }

    setOpenEdit(false)
  }, [openEdit])

  const renderLabel = useCallback(() => {
    const emphasisClassName = emphasis ? ` ${styles.emphasis}` : ""
    const importantClassName = important ? ` ${styles.important}` : ""
    const hasScrolledClassName = scrolled.hasScrolled ? ` ${styles.rowTitleSticky}` : ""
    const rowTitleClassName = `${styles.rowTitle}${emphasisClassName}${importantClassName}${hasScrolledClassName}`

    return (
      <div
        onClick={makeOnLabelClick()}
        className={rowTitleClassName}
        style={{
          left: scrolled.hasScrolled ? scrolled.scrolledX : "auto"
        }}
      >
        <div className={styles.titleContainer}>
          {hasChildren ? <GanttRowDropdownIcon className={styles.titleDropdownIcon} /> : null}
          <div className={styles.titleText}>{label}</div>
        </div>
      </div>
    )
  }, [emphasis, important, label, hasChildren, scrolled, isOpen])

  const renderColumns = useCallback(() => {
    if (!Array.isArray(columns) || columns.length === 0) {
      return null
    }

    const renderColumn = (column = {}, key = "") => {
      return (
        <div
          className={styles.extraColumn}
          style={{
            width: calculateWidth(column.span ?? 1),
            color: column.color ?? "#DDDDDD",
            left: scrolled.hasScrolled ? scrolled.scrolledX : "auto"
          }}
          key={key}
        >
          {column.selector(row)}
        </div>
      )
    }

    return columns.map((column, columnIdx) => renderColumn(column, `extra-column-${columnIdx}`))
  }, [columns, scrolled, mode, row])

  const barWidth = useMemo(() => {
    return calculateBarWidth(startIdx, endIdx)
  }, [startIdx, endIdx])

  const barTooltip = useMemo(() => {
    if ((tooltip ?? null) === null || Object.keys(tooltip).length === 0) {
      return {
        title: "",
        width: null,
        entries: []
      }
    }

    return {
      title: tooltip.title ?? "",
      entries: tooltip.entries ?? [],
      width: tooltip.width ?? null
    }
  }, [tooltip])

  const barBoundaries = useMemo(() => {
    switch (mode) {
      case GanttMode.Daily: {
        return [0, calculateWidth(ganttState.days.sorted.length)]
      }

      case GanttMode.Monthly: {
        return [0, calculateWidth(ganttState.months.sortedMondays.length)]
      }

      default: {
        return [0, 0]
      }
    }
  }, [mode, ganttState.days.sorted, ganttState.months.sortedMondays])

  const barSnapJumps = useMemo(() => {
    switch (mode) {
      case GanttMode.Daily: {
        return startIdx + 1
      }

      case GanttMode.Monthly: {
        return startIdx + 1
      }

      default: {
        return 0
      }
    }
  }, [mode, startIdx])

  const renderBar = useCallback(() => {
    if (startIdx === -1 || endIdx === -1) {
      return null
    }

    const startsBeforeFilter = filterization?.startsBeforeFilter ?? false
    const endsAfterFilter = filterization?.endsAfterFilter ?? false
    const disabled = startsBeforeFilter || endsAfterFilter

    return (
      <GanttRowBar
        disableDrag={cannotDrag || disableDrag}
        width={barWidth}
        boundaries={barBoundaries}
        color={barColour}
        disabled={disabled}
        tooltip={barTooltip}
        progress={progress}
        status={statusLabel}
        user={responsible}
        snapJumps={barSnapJumps}
        onClick={openEditModal}
        onDrag={handleOnDrag}
        onResize={handleOnResize}
        icon={rowIcon}
        snap={BAR_COLUMN_WIDTH}
      />
    )
  }, [
    mode,
    barSnapJumps,
    barWidth,
    barBoundaries,
    barColour,
    barTooltip,
    rowIcon,
    progress,
    statusLabel,
    responsible,
    handleOnDrag,
    disableDrag,
    filterization
  ])

  const renderBackground = useCallback(() => {
    let bgWidth = 0

    switch (mode) {
      case GanttMode.Daily: {
        bgWidth = calculateWidth(ganttState.days.sorted.length) + 300 + regionPadding
        break
      }

      case GanttMode.Monthly: {
        bgWidth = calculateWidth(ganttState.months.sortedMondays.length) + 300 + regionPadding
        break
      }
    }

    return <div style={{ width: bgWidth }} className={styles.rowBackground} />
  }, [mode, regionPadding, ganttState.days.sorted, ganttState.months.sortedMondays])

  const renderDays = useCallback(() => {
    switch (mode) {
      case GanttMode.Daily: {
        return (
          <div
            className={styles.rowDay}
            key={`gantt-row-${label}-${ganttState.days.sorted.length}-days`}
            style={{
              width: calculateWidth(ganttState.days.sorted.length)
            }}
          />
        )
      }

      case GanttMode.Monthly: {
        return (
          <div
            className={styles.rowDay}
            key={`gantt-row-${label}-${ganttState.months.sortedMondays.length}-days`}
            style={{
              width: calculateWidth(ganttState.months.sortedMondays.length)
            }}
          />
        )
      }

      default: {
        return null
      }
    }
  }, [mode, ganttState.days.sorted, ganttState.months.sortedMondays])

  const renderChildren = useCallback(() => {
    if (!hasChildren) {
      return null
    }

    const levelClassName = level > 0 ? ` ${styles[`level${level}`]}` : ""

    const newChildren =
      typeof children === "undefined" || children === null
        ? null
        : Array.isArray(children)
        ? map(children, (c) =>
            typeof c === "undefined" || c === null
              ? null
              : cloneElement(c, {
                  level: level + 1,
                  className: levelClassName
                })
          )
        : cloneElement(children, { level: level + 1, className: levelClassName })

    return isOpen ? newChildren : null
  }, [children, hasChildren, level, isOpen])

  const renderEditModal = useCallback(() => {
    if (start === null || end === null || !supportsEditModal) {
      return null
    }

    return (
      <GanttModal
        data={{
          label,
          responsible,
          startDate: formatFunc(start, "yyyy-MM-dd"),
          endDate: formatFunc(end, "yyyy-MM-dd"),
          plannedDuration: duration
        }}
        readOnly={!hasPermission(Permissions.EDITAR_AUDITORIAS)}
        responsibles={responsibles}
        open={openEdit}
        onSave={saveEditChanges}
        onFinish={finishEditChanges}
        type={type}
        typeTree={typeTree}
      />
    )
  }, [responsibles, type, openEdit, responsible, start, end, saveEditChanges, finishEditChanges, supportsEditModal])

  const additionalClassName = typeof className !== "undefined" && className !== null ? ` ${className}` : ""
  const hasChildrenClassName = hasChildren ? ` ${styles.hasChildren}` : ""
  const rowClassName = `${styles.rowContainer}${hasChildrenClassName}${additionalClassName}`

  return (
    <>
      <div className={rowClassName}>
        {renderBackground()}

        {renderLabel()}

        {renderColumns()}

        <div className={styles.daysContainer}>
          {renderDays()}
          {renderBar()}
        </div>
      </div>

      {renderChildren()}

      {renderEditModal()}
    </>
  )
}
