import cx from "classnames"
import { useEffect, useState } from "react"
import EmpresaFilters from "src/components/Filter/EmpresaFilters"
import FormField from "src/components/FormField"
import Select from "src/components/FormField/Select"
import SelectYear from "src/components/FormField/Select/Impl/Year"
import Tooltip from "src/components/Tooltip"
import { listAllProjects, listUserProjects } from "src/services/projects.service"
import styles from "./SelectProjectForm.module.scss"

const SelectProjectForm = ({
  projectType,
  value,
  labelPosition = "left",
  width = 400,
  labels = {},
  onSelect,
  filters = {},
  onChange = null,
  beforeForm = null,
  afterForm = null,
  fields = null
}) => {
  const [selectedYear, setSelectedYear] = useState(
    value?.ano
      ? value.ano
      : () => {
          const v = localStorage.getItem("selectedYear")
          return v ? v : new Date().getFullYear()
        }
  )
  const [selectedCompanyId, setSelectedCompanyId] = useState(value ? value.empresa : null)
  const [selectedUnidadNegocioId, setSelectedUnidadNegocioId] = useState(value ? value.un : null)
  const [listProjects, setListProjects] = useState(null)
  const [projectId, setProjectId] = useState(value ? value.id : null)

  const isFieldIncluded = (field) => {
    if (fields === null) {
      return true
    }

    return fields.includes(field)
  }

  useEffect(() => {
    const getInitData = async () => {
      setListProjects(null)

      if (selectedUnidadNegocioId) {
        const { data } = await listAllProjects({
          type: projectType,
          year: selectedYear,
          unidad_negocio: selectedUnidadNegocioId,
          ...filters
        })
        const formatted = formatProjects(data.records)
        setListProjects(formatted)
        if (!formatted.find((x) => x.selected)) {
          setProjectId(null)
          onSelect(null)
        }
      } else {
        setProjectId(null)
        onSelect(null)
      }
    }

    getInitData()
  }, [selectedYear, selectedUnidadNegocioId])

  useEffect(() => {
    if (value?.empresa) {
      triggerOnChange({ empresa: value?.empresa })
    }
  }, [value?.empresa])

  const triggerOnChange = (v) => {
    if (onChange) {
      onChange({ ...v, ano: selectedYear })
    }
  }

  const onChangeEmpresa = (v) => {
    setSelectedCompanyId(v)
    setListProjects(null)
    setSelectedUnidadNegocioId(null)
    setProjectId(null)
    onSelect(null)
    triggerOnChange({ empresa: v })
  }

  const handleOnChangeUn = (v) => {
    setSelectedUnidadNegocioId(v)
    if (selectedCompanyId) {
      triggerOnChange({ un: v, empresa: selectedCompanyId })
    }
  }

  const formatProjects = (data) => {
    return data.map((el) => {
      return {
        id: el.id,
        label: el.codigo ? `${el.codigo}-${el.nombre}` : el.nombre,
        selected: projectId ? projectId == el.id : false
      }
    })
  }

  return (
    <div className={cx("ui form", `label-${labelPosition}`, styles.form)} style={{ width }}>
      {beforeForm}
      {(isFieldIncluded("year") || isFieldIncluded("project")) && (
        <FormField label={labels.year ?? "Año"} positionLabel={labelPosition}>
          <SelectYear defaultYear={selectedYear} onSelect={(v) => setSelectedYear(v)} />
        </FormField>
      )}

      <EmpresaFilters
        emptyLabel={false}
        empresaValue={selectedCompanyId}
        unValue={selectedUnidadNegocioId}
        onChangeEmpresa={onChangeEmpresa}
        onChangeUN={handleOnChangeUn}
        positionLabel={labelPosition}
        includeUN={isFieldIncluded("unidad_negocio") || isFieldIncluded("project")}
      />

      {isFieldIncluded("project") && (
        <FormField label={labels.project ?? "Proyecto"} positionLabel={labelPosition}>
          <Tooltip content={"Seleccione una Unidad de Negocio."} disabled={selectedUnidadNegocioId}>
            <Select
              selectionChange={({ id }) => {
                setProjectId(id)
                onSelect({ id, un: selectedUnidadNegocioId, empresa: selectedCompanyId, ano: selectedYear })
                triggerOnChange({ id, un: selectedUnidadNegocioId, empresa: selectedCompanyId })
              }}
              disabled={!selectedUnidadNegocioId}
              initialOptions={!selectedUnidadNegocioId ? [] : listProjects}
            />
          </Tooltip>
        </FormField>
      )}
      {afterForm}
    </div>
  )
}

export const SelectProjectSimplifiedForm = ({
  projectType,
  userId,
  defaultValue = null,
  labelPosition = "left",
  label = null,
  onChange,
  filters = {},
  disabled = false,
  multiple = false
}) => {
  const [value, setValue] = useState(() => {
    return multiple
      ? defaultValue?.map((x) => (typeof x === "object" && typeof x?.id !== "undefined" ? x.id : x))
      : defaultValue
  })
  const [listProjects, setListProjects] = useState(null)

  useEffect(() => {
    setListProjects(null)
    listUserProjects({ type: projectType, user_id: userId, ...filters }).then(({ data }) => {
      const formatted = formatProjects(data.records)
      setListProjects(formatted)

      setValue(null)
      onChange(null)
    })
  }, [projectType, userId])

  const formatProjects = (data) => {
    const selected = value === null ? [] : Array.isArray(value) ? value : [value]
    return data.map((el) => {
      return {
        id: el.id,
        label: el.codigo ? `${el.codigo}-${el.nombre} (${el.ano_auditoria})` : el.nombre,
        subtext: el.codigo ? `${el.empresa__nombre} > ${el.unidad_negocio__nombre}` : null,
        selected: selected.includes(el.id)
      }
    })
  }

  return (
    <FormField label={label ?? "Proyecto"} positionLabel={labelPosition}>
      <Select
        selectionChange={(selected) => {
          if (multiple) {
            setValue(selected?.length > 0 ? selected?.map((x) => x.id) : null)
            onChange(selected?.length > 0 ? selected : null)
          } else {
            setValue(selected.id)
            onChange({ id: selected.id, label: selected.label })
          }
        }}
        disabled={disabled}
        initialOptions={listProjects}
        multiple={multiple}
      />
    </FormField>
  )
}

export default SelectProjectForm
