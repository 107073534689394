import React, { useEffect, useState } from "react"
import { treeMode } from "src/constants/tree"
import Button from "../../Button"
import FormField from "../../FormField"
import Input from "../../FormField/Input"
import Select from "../../FormField/Select"
import SelectOption from "../../FormField/Select/SelectOption"
import IndependentSideModal from "../../IndependentSideModal"
import SingleDatePicker from "../../SingleDatePicker"
import styles from "./style.module.scss"

export default function GanttModal(props) {
  const {
    data = {
      label: "",
      responsible: "",
      startDate: null,
      endDate: null,
      plannedDuration: 0
    },
    responsibles = [],
    open = false,
    readOnly = false,
    onSave = null,
    onFinish = null,
    type = null,
    typeTree = treeMode.AUDITPLAN
  } = props
  const [availableResponsibles, setAvailableResponsibles] = useState([])
  const [isOpen, setIsOpen] = useState(open)
  const [form, setForm] = useState(data)

  useEffect(() => {
    setAvailableResponsibles(
      responsibles.map((responsible) => {
        const responsibleId = responsible.id
        const responsibleName = (
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "min-content 1fr min-content",
              columnGap: 10
            }}
          >
            <span style={{ color: "#0a858d", minWidth: 25, maxWidth: "max-content" }}>{responsible.sigla}</span>
            <span>
              {responsible.first_name} {responsible.last_name}
            </span>
          </div>
        )
        return {
          id: responsibleId,
          label: responsibleName,
          selected: form.responsible?.id === responsibleId
        }
      })
    )
  }, [responsibles])

  let isDateChangingDisabled

  switch (type) {
    case "unit":
    case "macroprocess":
    case "process":
    case "subprocess":
      isDateChangingDisabled = true
      break
    default:
      isDateChangingDisabled = false
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    setForm(data)
  }, [data])

  const makeOnFinish = (handler = null) => {
    if (handler === null) {
      return () => {
        setIsOpen(false)

        setForm({
          responsible: "",
          startDate: null,
          endDate: null,
          plannedDuration: 0
        })
      }
    }

    return () => {
      setIsOpen(false)

      setForm({
        responsible: "",
        startDate: "",
        endDate: "",
        plannedDuration: 0
      })

      handler()
    }
  }

  const handleOnFinish = makeOnFinish(onFinish)

  const makeOnSave = (handler = null) => {
    if (handler === null) {
      return (event) => {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    return (event) => {
      event.preventDefault()
      event.stopPropagation()

      Promise.resolve()
        .then(() => handler({ ...form }))
        .then(() => {
          handleOnFinish()
        })
    }
  }

  const handleOnSave = makeOnSave(onSave)

  const labelAudit = treeMode.AUDITPLAN === typeTree ? "Líder de auditoría" : "Responsable de la evaluación"

  return (
    <IndependentSideModal open={isOpen} className={styles.dialog} onFinish={handleOnFinish}>
      <div className={styles.title}>{data.label}</div>

      <div className={styles.form}>
        <div className={styles.inputGroup}>
          <FormField label={labelAudit} positionLabel='up' disabled={readOnly}>
            <Select
              selectionChange={({ id: selectedResponsibleId }) => {
                setForm({
                  ...form,
                  responsible: {
                    id: selectedResponsibleId
                  }
                })
              }}
              disabled={readOnly}
              initialOptions={availableResponsibles}
            >
              {availableResponsibles.map((responsible, responsibleIdx) => (
                <SelectOption value={responsible.id} key={`responsible-${responsible.id}-${responsibleIdx}`}>
                  {responsible.label}
                </SelectOption>
              ))}
            </Select>
          </FormField>
        </div>
        <div className={`${styles.inputGroup} ${styles.date}`}>
          <SingleDatePicker
            label='Fecha de Inicio'
            value={form.startDate}
            onChange={([date, dateStr]) => {
              setForm({
                ...form,
                startDate: dateStr
              })
            }}
            disabled={isDateChangingDisabled || readOnly}
          />
        </div>
        <div className={`${styles.inputGroup} ${styles.date}`}>
          <SingleDatePicker
            label='Fecha Final'
            value={form.endDate}
            onChange={([date, dateStr]) => {
              setForm({
                ...form,
                endDate: dateStr
              })
            }}
            disabled={isDateChangingDisabled || readOnly}
          />
        </div>
        <div className={styles.inputGroup}>
          <FormField label='Horas Presupuestadas' positionLabel='up' disabled={readOnly}>
            <Input
              placeholder=''
              type='number'
              min={0}
              max={99999}
              disabled={readOnly}
              initialValue={form.plannedDuration}
              onChange={(value) => {
                setForm({
                  ...form,
                  plannedDuration: value
                })
              }}
            />
          </FormField>
        </div>
        <div className={`${styles.inputGroup} ${styles.buttons}`}>
          <Button
            handleClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              handleOnFinish()
            }}
            text='Cancelar'
            name='warning'
            type='big'
          />
          {!readOnly && <Button handleClick={handleOnSave} text='Guardar' name='normal' type='big' />}
        </div>
      </div>
    </IndependentSideModal>
  )
}
