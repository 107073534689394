import { useState } from "react"
import { FormModal } from "src/components/FormModal"
import Tooltip from "src/components/Tooltip"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function DuplicateTreeItemAction({ item, indexes, onSubmit }) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const showConfirm = async (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    setShowModal(true)
  }

  const onClose = () => {
    setShowModal(false)
  }

  const handleSave = (values) => {
    onSubmit(item, indexes, values)
  }

  const generateNewName = () => {
    const str = item?.nombre
    if (str) {
      const regex = /(\d+)$/
      const matches = regex.exec(str.trim())
      if (matches !== null) {
        const prev = new RegExp(matches[0] + "$")
        return str.trim().replace(prev, parseInt(matches[0]) + 1)
      }
    }

    return t("main_ui.general.lb_copy_text_template", { text: item?.nombre })
  }

  const fields = [{ label: "frontend.default.name_new", name: "new_name", required: true }]

  return (
    <div>
      <Tooltip containerStyle={{ cursor: "pointer" }} content={t("main_ui.general.btn_duplicate_record")}>
        <button className={styles.buttonTreeitemAction} onClick={showConfirm}>
          <i className={"ui icon copy"} />
        </button>
      </Tooltip>
      {showModal && (
        <FormModal
          title={t("main_ui.general.btn_duplicate_record") + " - " + item?.nombre}
          initialValues={{ new_name: generateNewName(item?.nombre) }}
          fields={fields}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </div>
  )
}
