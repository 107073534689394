import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import Draggable, { DraggableCore } from "react-draggable"
import { toast } from "react-toastify"
import Tooltip from "src/components/Tooltip"
import { calculateColumnsSize } from "src/helpers/gantt"
import useDispatch from "src/store"
import Icon from "../../Icon"
import GanttTooltip from "../GanttTooltip"
import styles from "./style.module.scss"

function BarResizer({
  side = "left",
  width = 0,
  disabled = false,

  children = null,

  snap = 35,

  minWidth = 0,
  maxWidth = 0,

  onStart = () => {},
  onStop = () => {},
  onResize = () => {},

  location = "left", // values: left, right

  ...draggableProps
}) {
  const slackRef = useRef(0)
  const lastHandleRectRef = useRef(null)

  const resizerTrigger = useMemo(() => {
    return React.Children.only(children)
  }, [children])

  // Functions start

  const resetData = useCallback(() => {
    slackRef.current = 0
    lastHandleRectRef.current = null
  }, [slackRef.current, lastHandleRectRef.current])

  const runConstraints = useCallback(
    (unconstrainedWidth = 0) => {
      if (typeof minWidth !== "number" || typeof maxWidth !== "number" || minWidth < 0 || maxWidth < 0) {
        return unconstrainedWidth
      }

      const [min, max] = [minWidth, maxWidth]

      let _slack = slackRef.current
      let _width = unconstrainedWidth + _slack

      if (min > 0) {
        _width = Math.max(min, _width)
      }

      if (max > 0) {
        _width = Math.min(max, _width)
      }

      slackRef.current = _slack + unconstrainedWidth - _width

      return _width
    },
    [minWidth, maxWidth, slackRef.current]
  )

  // Functions end

  // Handlers start

  const resizeHandlerFactory = useCallback(
    (handlerType = "", triggerLocation = "") => {
      let cb

      switch (handlerType) {
        case "onStart": {
          cb = onStart
          break
        }

        case "onStop": {
          cb = onStop
          break
        }

        case "onResize": {
          cb = onResize
          break
        }

        default: {
          return () => {}
        }
      }

      const sharedEventHandler = (event, { node, deltaX }) => {
        let delta = deltaX

        const canDragX = !disabled

        if (!canDragX) {
          return
        }

        const handleRect = node.getBoundingClientRect()

        if (handleRect.x < 0) {
          return
        }

        if (lastHandleRectRef.current !== null && triggerLocation === "left") {
          const lastLeft = lastHandleRectRef.current?.left ?? 0
          const deltaLeftSinceLast = handleRect.left - lastLeft

          delta += deltaLeftSinceLast
        }

        lastHandleRectRef.current = handleRect

        if (triggerLocation === "left") {
          delta = -delta
        }

        const _width = runConstraints(width + delta)

        const dimensionsChanged = _width !== width

        const shouldSkipCb = handlerType === "onResize" && !dimensionsChanged

        if (typeof cb === "function" && !shouldSkipCb) {
          if (typeof event.persist === "function") {
            event.persist()
          }

          cb(event, { node, width: _width })
        }
      }

      switch (handlerType) {
        case "onStart": {
          return (event, data) => {
            resetData()
            sharedEventHandler(event, data)
          }
        }

        case "onStop": {
          return (event, data) => {
            sharedEventHandler(event, data)
            resetData()
          }
        }

        case "onResize": {
          return (event, data) => {
            sharedEventHandler(event, data)
          }
        }
      }
    },
    [disabled, width, onStart, onStop, onResize, lastHandleRectRef.current, resetData]
  )

  const handleOnStart = useMemo(() => {
    return resizeHandlerFactory("onStart", location)
  }, [resizeHandlerFactory, location])

  const handleOnStop = useMemo(() => {
    return resizeHandlerFactory("onStop", location)
  }, [resizeHandlerFactory, location])

  const handleOnDrag = useMemo(() => {
    return resizeHandlerFactory("onResize", location)
  }, [resizeHandlerFactory, location])

  // Handlers end

  return (
    <DraggableCore
      axis='x'
      grid={[snap, snap]}
      onStart={handleOnStart}
      onStop={handleOnStop}
      onDrag={handleOnDrag}
      {...draggableProps}
    >
      {resizerTrigger}
    </DraggableCore>
  )
}

function makeGanttRowBarState(initState = { jumps: 0, width: 0, x: 0 }) {
  const { jumps, width, x } = initState

  return {
    jumps,
    width,
    x
  }
}

function ganttRowBarReducer(
  state = makeGanttRowBarState(),
  action = {
    type: "",
    data: {}
  }
) {
  const { type, data } = action

  switch (type) {
    case "SET_JUMPS": {
      if (!("jumps" in data) || typeof data.jumps !== "number") {
        return { ...state }
      }

      return {
        ...state,
        jumps: data.jumps
      }
    }

    case "SET_WIDTH": {
      if (!("width" in data) || typeof data.width !== "number") {
        return { ...state }
      }

      return {
        ...state,
        width: data.width
      }
    }

    case "UPDATE": {
      const width = "width" in data && typeof data.width === "number" ? data.width : state.width
      const jumps = "jumps" in data && typeof data.jumps === "number" ? data.jumps : state.jumps
      const x = "x" in data && typeof data.x === "number" ? data.x : state.x

      return {
        x,
        width,
        jumps
      }
    }

    case "UPDATE_POSITION": {
      const { x: currentX, width: oldWidth } = state
      const width = "width" in data && typeof data.width === "number" ? data.width : oldWidth

      if (width === oldWidth) {
        return { ...state }
      }

      const value = width - oldWidth
      const newX = currentX - value

      if (newX === currentX) {
        return { ...state }
      }

      const minX = "minX" in data && typeof data.minX === "number" ? data.minX : -1

      if (minX > -1 && newX < minX) {
        return { ...state }
      }

      return {
        ...state,
        x: newX,
        width
      }
    }

    default: {
      return { ...state }
    }
  }
}

export default function GanttRowBar({
  color = "#000000",

  snap = 35,
  snapJumps = 0,

  width = 0,
  tooltip = {
    title: "Detalle de la actividad",
    width: null,
    entries: []
  },

  boundaries = [0, 0],

  progress = "",
  progressLabel = "Avance",
  status = "",
  statusLabel = "Estado",
  user = "",
  userLabel = "Responsable",

  disableDrag = false,
  disabled = false,
  icon = null,

  onClick = () => {},
  onDrag = () => {
    return Promise.resolve()
  },
  onResize = () => {
    return Promise.resolve()
  }
}) {
  const dispatch = useDispatch()
  const barRef = useRef(null)
  const [ticks, setTicks] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [barState, dispatchBarState] = useReducer(
    ganttRowBarReducer,
    makeGanttRowBarState({
      jumps: snapJumps,
      width,
      x: (snapJumps - 1) * snap
    })
  )

  useEffect(() => {
    dispatchBarState({
      type: "UPDATE",
      data: {
        jumps: snapJumps,
        x: (snapJumps - 1) * snap
      }
    })
  }, [snapJumps])

  useEffect(() => {
    dispatchBarState({
      type: "SET_WIDTH",
      data: {
        width
      }
    })
  }, [width])

  // Handlers start

  const handleOnClick = useCallback(() => {
    if (isUpdating) {
      return
    }

    onClick()
  }, [isUpdating, onClick])

  const handleOnDrag = useCallback(() => {
    if (typeof onDrag !== "function") {
      return
    }

    setIsUpdating(true)

    onDrag(barState.jumps)
      ?.catch((error) => {
        if (typeof error === "string") {
          toast.error(error ?? "Error inesperado sucedió con el componente Gantt...")

          return
        }

        throw error
      })
      .finally(() => {
        setIsUpdating(false)
        setIsDragging(false)
      })
  }, [barState, onDrag])

  const findJumpFromCoordinate = useCallback(
    (value = 0) => {
      return Math.floor(value / snap) + 1
    },
    [snap]
  )

  const handleOnResize = useCallback(() => {
    if (typeof onResize !== "function") {
      return
    }

    setIsUpdating(true)

    const startJumps = findJumpFromCoordinate(barState.x)
    const endJumps = findJumpFromCoordinate(barState.x + barState.width)

    onResize(startJumps, endJumps)
      ?.catch((error) => {
        if (typeof error === "string") {
          toast.error(error ?? "Error inesperado sucedió con el componente Gantt...")

          return
        }

        throw error
      })
      .finally(() => {
        setIsUpdating(false)
        setIsResizing(false)
      })
  }, [barState.x, barState.width, onResize, findJumpFromCoordinate, snap])

  // Handlers end

  const barPosition = useMemo(() => {
    return {
      x: barState.x,
      y: 12
    }
  }, [barState.x])

  const barClassName = useMemo(() => {
    const isResizingClassName = isResizing ? ` ${styles.barIsResizing}` : ""
    const isDraggingClassName = isDragging ? ` ${styles.barIsDragging}` : ""
    const isUpdatingClassName = isUpdating ? ` ${styles.barIsUpdating}` : ""

    return `${styles.bar}${isDraggingClassName}${isResizingClassName}${isUpdatingClassName}`
  }, [isDragging, isResizing, isUpdating])

  const barBoundaries = useMemo(() => {
    const [x1, x2] = boundaries

    return {
      left: x1,
      right: x2 - barState.width
    }
  }, [boundaries, barState.width])

  const barWidthBoundaries = useMemo(() => {
    const [x1, x2] = boundaries

    return {
      minWidth: 20.5,
      maxWidth: x2 - x1
    }
  }, [boundaries])

  const draggableCoreProps = useMemo(() => {
    return {
      axis: "x",
      cancel: `div.${styles.barResizer}`,
      grid: [snap, snap],
      disabled: disabled || disableDrag,
      onStart: () => {},
      onStop: () => {
        if (isDragging) {
          setIsDragging(false)
        }

        if (ticks === 0) {
          handleOnClick()
          return
        }

        setTicks(0)

        handleOnDrag()
      },
      onDrag: (event, { x }) => {
        const draggedJumps = calculateColumnsSize(x) + 1

        if (barState.jumps === draggedJumps) {
          return
        }

        setTicks(ticks + 1)

        dispatchBarState({
          type: "UPDATE",
          data: {
            jumps: draggedJumps,
            x: (draggedJumps - 1) * snap
          }
        })

        if (!isDragging) {
          setIsDragging(true)
        }
      }
    }
  }, [snap, ticks, isDragging, handleOnClick, handleOnDrag, disableDrag, disabled, barState.jumps])

  const handleResizeStop = useCallback(() => {
    if (isResizing) {
      setIsResizing(false)
    }

    if (ticks === 0) {
      handleOnClick()
      return
    }

    setTicks(0)

    handleOnResize()
  }, [isResizing, handleOnResize, handleOnClick])

  const resizeHandlerFactory = useCallback(
    (location = "left") => {
      switch (location) {
        case "left": {
          return (_, { width: _width }) => {
            if (barState.x < barBoundaries.left) {
              return
            }

            setTicks(ticks + 1)

            dispatchBarState({
              type: "UPDATE_POSITION",
              data: {
                width: _width,
                minX: barBoundaries.left
              }
            })

            if (!isResizing) {
              setIsResizing(true)
            }
          }
        }

        case "right": {
          return (_, { width: _width }) => {
            if (barState.x > barBoundaries.right) {
              return
            }

            setTicks(ticks + 1)

            dispatchBarState({
              type: "SET_WIDTH",
              data: {
                width: _width
              }
            })

            if (!isResizing) {
              setIsResizing(true)
            }
          }
        }

        default: {
          return () => {}
        }
      }
    },
    [barState.width, barState.x, barBoundaries.right, barBoundaries.left, ticks]
  )

  const handleLeftResizer = useMemo(() => {
    return resizeHandlerFactory("left")
  }, [resizeHandlerFactory])

  const handleRightResizer = useMemo(() => {
    return resizeHandlerFactory("right")
  }, [resizeHandlerFactory])

  const tooltipTitle = useMemo(() => {
    return tooltip?.title ?? ""
  }, [tooltip])

  const tooltipWidth = useMemo(() => {
    const value = tooltip?.width ?? null

    if (value === null || typeof value !== "string") {
      return null
    }

    if (value === "") {
      return null
    }

    return value
  }, [tooltip])

  const tooltipEntries = useMemo(() => {
    let _entries = tooltip?.entries ?? []

    _entries =
      _entries.length > 0
        ? _entries
        : [
            {
              label: progressLabel,
              value: progress,
              hasUserIcon: false
            },
            {
              label: statusLabel,
              value: status,
              hasUserIcon: false
            },
            {
              label: userLabel,
              value: user.sigla,
              hasUserIcon: true
            }
          ]

    if (disabled && _entries[_entries.length - 1]?.label !== "Aviso") {
      _entries.push({
        label: "Aviso",
        value: "Deshabilitado porque filtro no cubre fecha de inicio o fecha fin",
        hasUserIcon: false
      })
    }

    return _entries
  }, [tooltip, disabled])

  const activeBarStyle = useMemo(() => {
    return {
      width: progress,
      backgroundColor: color
    }
  }, [progress, color])

  const wholeBarStyle = useMemo(() => {
    return {
      width: barState.width
    }
  }, [barState])

  // Render functions start
  const renderIcon = useCallback(() => {
    if (icon === null) {
      return null
    }

    const iconVisibleClassName = isResizing || isUpdating || isDragging ? "" : ` ${styles.barIconIsVisible}`
    const iconClassName = `${styles.barIcon}${iconVisibleClassName}`

    const positionStyle = {
      left: barState.x - 25
    }

    return (
      <div className={iconClassName} style={positionStyle}>
        {icon}
      </div>
    )
  }, [icon, isResizing, isUpdating, isDragging, barState.x])

  const renderUser = useCallback(() => {
    if (typeof user === "undefined" || user === "") {
      return null
    }

    const responsibleVisibleClassName = isResizing || isUpdating || isDragging ? "" : ` ${styles.responsibleIsVisible}`
    const responsibleClassName = `${styles.responsible}${responsibleVisibleClassName}`

    const positionStyle = {
      left: barState.x + barState.width
    }

    return (
      <div className={responsibleClassName} style={positionStyle}>
        <Tooltip content={user.name} containerStyle={{ display: "flex" }}>
          <Icon size={18} color='#666' name='font-awesome/user_circle' />
          <div className={styles.responsibleFullName}>{user.sigla}</div>
        </Tooltip>
      </div>
    )
  }, [isResizing, isUpdating, isDragging, user, barState.x, barState.width])

  const renderLeftCorner = useCallback(() => {
    if (progress === "0%") {
      return null
    }

    return (
      <div
        className={`${styles.barCorner} ${styles.barCornerStart}`}
        style={{
          borderLeftColor: color
        }}
      />
    )
  }, [progress, color])

  const renderRightCorner = useCallback(() => {
    if (progress !== "100%") {
      return null
    }

    return (
      <div
        className={`${styles.barCorner} ${styles.barCornerEnd}`}
        style={{
          borderRightColor: color
        }}
      />
    )
  }, [progress, color])

  const renderActiveBarDraggableLeftCorner = useCallback(() => {
    if (progress === "0%") {
      return null
    }

    const disabledClassName = disableDrag ? ` ${styles.barResizerDisabled}` : ""
    const _className = `${styles.barResizer} ${styles.barResizerLeft}${disabledClassName}`

    return (
      <BarResizer
        location='left'
        bounds={barBoundaries}
        disabled={disabled || disableDrag}
        width={barState.width}
        onResize={handleLeftResizer}
        onStop={handleResizeStop}
        {...barWidthBoundaries}
      >
        <div className={_className} />
      </BarResizer>
    )
  }, [progress, barState.width, handleLeftResizer, disabled, disableDrag])

  const renderActiveBarDraggableRightCorner = useCallback(() => {
    if (progress !== "100%") {
      return null
    }

    const disabledClassName = disabled || disableDrag ? ` ${styles.barResizerDisabled}` : ""
    const _className = `${styles.barResizer} ${styles.barResizerRight}${disabledClassName}`

    return (
      <BarResizer
        location='right'
        disabled={disabled || disableDrag}
        width={barState.width}
        onResize={handleRightResizer}
        onStop={handleResizeStop}
        {...barWidthBoundaries}
      >
        <div className={_className} />
      </BarResizer>
    )
  }, [progress, barState.width, handleRightResizer, disabled, disableDrag])

  const renderBackgroundBarDraggableLeftCorner = useCallback(() => {
    if (progress !== "0%") {
      return null
    }

    const disabledClassName = disabled || disableDrag ? ` ${styles.barResizerDisabled}` : ""
    const _className = `${styles.barResizer} ${styles.barResizerLeft}${disabledClassName}`

    return (
      <BarResizer
        location='left'
        disabled={disabled || disableDrag}
        width={barState.width}
        onResize={handleLeftResizer}
        onStop={handleResizeStop}
        {...barWidthBoundaries}
      >
        <div className={_className} />
      </BarResizer>
    )
  }, [progress, barState.width, handleLeftResizer, disabled, disableDrag])

  const renderBackgroundBarDraggableRightCorner = useCallback(() => {
    if (progress === "100%") {
      return null
    }

    const disabledClassName = disabled || disableDrag ? ` ${styles.barResizerDisabled}` : ""
    const _className = `${styles.barResizer} ${styles.barResizerRight}${disabledClassName}`

    return (
      <BarResizer
        location='right'
        disabled={disabled || disableDrag}
        width={barState.width}
        onResize={handleRightResizer}
        onStop={handleResizeStop}
        {...barWidthBoundaries}
      >
        <div className={_className} />
      </BarResizer>
    )
  }, [progress, barState.width, handleRightResizer, disabled, disableDrag])

  return (
    <div className={styles.barContainer}>
      {renderIcon()}

      <Draggable position={barPosition} bounds={barBoundaries} {...draggableCoreProps}>
        <div className={barClassName} ref={barRef} style={wholeBarStyle}>
          {renderBackgroundBarDraggableLeftCorner()}
          {renderBackgroundBarDraggableRightCorner()}

          <div className={`${styles.barCorner} ${styles.barCornerStart}`} />
          <div className={`${styles.barCorner} ${styles.barCornerEnd}`} />

          <div style={activeBarStyle} className={styles.activeBar}>
            {renderActiveBarDraggableLeftCorner()}
            {renderActiveBarDraggableRightCorner()}

            {renderLeftCorner()}
            {renderRightCorner()}
          </div>

          <GanttTooltip
            ref={barRef}
            outerTrigger={true}
            width={tooltipWidth}
            title={tooltipTitle}
            entries={tooltipEntries}
          />
        </div>
      </Draggable>

      {renderUser()}
    </div>
  )
}
