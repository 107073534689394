export const BAR_COLUMN_WIDTH = 35

export function calculateColumnsSize(width = 0) {
  return Math.floor(width / 35.0)
}

export function calculateWidth(x = 0) {
  return x * BAR_COLUMN_WIDTH
}

export function calculateBarWidth(x1 = 0, x2 = 0) {
  if (x2 - x1 < 1) {
    return BAR_COLUMN_WIDTH
  }

  return (x2 - x1 + 1) * BAR_COLUMN_WIDTH
}
