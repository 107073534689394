import { useState } from "react"

export default function useWizardStep(initialStep = 1) {
  const [currentStep, setCurrentStep] = useState(initialStep)

  const gotoStep = (step, validate = false) => {
    if (typeof step === "function") {
      step = step(currentStep)
    }

    if (!validate || currentStep > step) {
      setCurrentStep(step)
    }
  }

  return { currentStep, setCurrentStep, gotoStep }
}
