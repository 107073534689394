import { useRouter } from "next/router"
import { useCallback, useState } from "react"
import { batch, useSelector } from "react-redux"
import { debounce } from "../helpers/debounce"
import { getWorksheetKey } from "../helpers/getWorksheetKey"
import { logoutCallback } from "../services/authValidationMiddleware"
import { updateTreeItem } from "../services/tree.service"
import { updateTreeEvaluationItem } from "../services/treeEvaluation.service"
import useDispatch from "../store"
import * as sidenavContainerActions from "../store/sidenavContainer/actions"
import * as treeActions from "../store/tree/actions"
import { ArrayPropertyUpdateType } from "../store/tree/actions"
import { formatAndParse } from "../store/tree/helpers"

export default function useDataUpdater({
  itemId = "",
  indexes = [],
  delay = 3000,
  auditId,
  form = {
    debounced: [
      {
        name: "",
        isArrayProp: false,
        options: {}
      }
    ],
    instant: [
      {
        name: "",
        isArrayProp: false,
        options: {}
      }
    ]
  },
  typeTree = "auditPlan"
}) {
  const router = useRouter()
  const dispatch = useDispatch()
  const treeConfigs = useSelector((state) => state.tree?.configs)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const updateData = useCallback(
    (payload, onSuccess = () => {}) => {
      setIsLoading(true)

      let promise = null

      switch (typeTree) {
        case "auditPlan":
          promise = updateTreeItem({
            auditId,
            itemId,
            dataToUpdate: payload
          })

          break

        case "riskEvaluation":
          promise = updateTreeEvaluationItem({
            evaluationId: auditId,
            itemId,
            dataToUpdate: payload
          })

          break
      }

      if (promise === null) {
        setIsLoading(false)
        return
      }

      return promise
        .then((response) => {
          const _result = response.data

            if(itemId.endsWith("-EV")){
              _result._id = _result.id
              _result.id = itemId
              _result.item_tipo = "HT_EVALUACION"
              _result.nombre = "Evaluación Plan de Acción " + _result.codigo 
              _result.type = "evaluation"
              _result._type = "evaluation"
              

            }

            if(itemId.endsWith("-PA")){
              _result._id = _result.id
              _result.id = itemId
              _result.item_tipo = "HT_PLAN_ACCION"
              _result.nombre ="Plan de Acción " +  _result.codigo,
              _result.name ="Plan de Acción " +  _result.codigo,
              _result.type = "acctionPlan"
              _result._type = "acctionPlan"

            }
            return (formatAndParse(_result, typeTree, false, treeConfigs).then((result) => [response, result]))
        }
        )
        .then(([response, result]) => {
          batch(() => {

           

            dispatch(
              treeActions.updateTreeRow({
                indexes,
                data: result,
                overwrite: true
              })
            )

            dispatch(
              sidenavContainerActions.updateWorksheetData({
                dataToUpdate: result,
                worksheetKey: getWorksheetKey(itemId.endsWith("-PA") ? "acctionPlan" :
                itemId.endsWith("-EV") ? "evaluation" :result.item_tipo), 
                overwrite: false
              })
            )
          })

          onSuccess(response)
        })
        .catch((error) => {
          console.error("useDataUpdater", error)

          setError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [typeTree, indexes, auditId, itemId]
  )

  const updateArrayPropData = useCallback(
    ({
      payload,
      serviceFn = async () => {},
      selectorFn = () => [],
      onSuccess = () => {},
      onError = () => {},
      finderFn = (item = {}, needle = "") => item.id === needle,
      finderNeedle = "",
      worksheetKey = "",
      actionType = ArrayPropertyUpdateType.NONE
    }) => {
      setIsLoading(true)

      const promise = serviceFn({ ...payload, itemId, auditId }, logoutCallback(dispatch, router))

      return promise
        .then(({ data }) => {
          batch(() => {
            dispatch(
              treeActions.manageTreeRowPropArray({
                indexes,
                data,
                selectorFn,
                finderFn,
                finderNeedle,
                actionType
              })
            )

            dispatch(
              sidenavContainerActions.manageWorksheetPropArray({
                indexes,
                data,
                selectorFn,
                finderFn,
                finderNeedle,
                actionType,
                worksheetKey
              })
            )
          })

          onSuccess(data)
        })
        .catch((error) => {
          console.error("useDataUpdater", error)

          onError(error)
          setError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [itemId, auditId]
  )

  const makeDataUpdaterFn = useCallback(
    ({ debounced = true, isArrayProp = false, options = {}, onSuccess }) => {
      if (isArrayProp) {
        if (debounced) {
          return debounce(
            (data) =>
              updateArrayPropData({
                payload: data,
                ...options
              }),
            delay
          )
        }

        return (data) =>
          updateArrayPropData({
            payload: data,
            ...options
          })
      }

      if (debounced) {
        return debounce((data) => updateData(data, onSuccess), delay)
      }

      return (data) => updateData(data, onSuccess)
    },
    [itemId, auditId, updateData, updateArrayPropData]
  )

  return {
    isLoading,
    error,
    updaters: {
      instant:
        form.instant?.reduce((agg, value) => {
          agg[value.name] = makeDataUpdaterFn({
            debounced: false,
            isArrayProp: value.isArrayProp,
            options: value.options,
            onSuccess: value.onSuccess
          })

          return agg
        }, {}) ?? {},
      debounced:
        form.debounced?.reduce((agg, value) => {
          agg[value.name] = makeDataUpdaterFn({
            debounced: true,
            isArrayProp: value.isArrayProp,
            options: value.options,
            onSuccess: value.onSuccess
          })

          return agg
        }, {}) ?? {}
    }
  }
}
