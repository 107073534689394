import isPast from "date-fns/isPast"
import parseISO from "date-fns/parseISO"
import startOfDay from "date-fns/startOfDay"
import { actionPlan, actionPlanActivity, impact, modelStatus } from "../constants/find"

export const mapImpact = (value) => {
  return Object.values(impact).find((el) => el.value === value)
}

export const mapImpact2 = (impactValue, value) => {
  if (!impactValue) return null
  return Object.values(impactValue).find((el) => el.value === value)
}

export const mapActionPlan = (value) => {
  return Object.values(actionPlan).find((el) => el.value === value)
}

export const mapActionPlanActivity = (value) => {
  return Object.values(actionPlanActivity).find((el) => el.value === value)
}

export const mapModelStatus = (value) => {
  return Object.values(modelStatus).find((el) => el.value === value)
}

export const calculateLevelOfCompliance = (activities) => {
  const formula = `${activities.filter((el) => el.implementado === "SI").length / activities.length}`
  const convertPercentage = `${Math.floor(formula * 100 * 100) / 100}%`
  const result = activities.length > 0 ? convertPercentage : "0%"
  return result
}

export const isOverduePlanAction = (plan_action) => {
  if (plan_action.fecha_cierre) {
    return startOfDay(parseISO(plan_action.fecha_cierre)) > parseISO(plan_action.implementacion_fecha_fin)
  }
  return isPast(parseISO(plan_action.implementacion_fecha_fin))
}

export const getActionPlanResponsable = (plan_action, role = "default") => {
  return plan_action.responsables.find((x) => x?.rol == role)
}

export const calcActionPlanStatus = (actionplan) => {
  if (!actionplan) {
    return null
  }

  if (actionplan.tratamiento_plan_accion == "NO_EVALUAR" && actionplan.status == "IMPLEMENTADO") {
    return "_IMPLEMENTADO"
  }

  return actionplan.status
}

export const getActionPlanStatuses = () => {
  return [
    {
      value: "NUEVO",
      color: "#ed4138"
    },
    {
      value: "ASIGNADO",
      color: "#FFE55C"
    },
    {
      value: "EN_PROCESO",
      color: "#ffae39"
    },
    {
      value: "IMPLEMENTADO",
      color: "#00a750"
    },
    {
      value: "EVALUADO",
      color: "#56aaff"
    },
    {
      value: "RECHAZADO",
      color: "#662e93"
    }
  ]
}
