import cx from "classnames"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function ExpandAllBox({
  onClickExpandAll,
  className = null,
  labelExpand = null,
  labelCollapse = null,
  style = {}
}) {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.collapsingMenu, className)} style={style}>
      <button
        className={styles.option}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onClickExpandAll(true)
        }}
      >
        {t(labelExpand ?? "main_ui.general.btn_expandall")}
      </button>
      <button
        className={styles.option}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onClickExpandAll(false)
        }}
      >
        {t(labelCollapse ?? "main_ui.general.btn_collapseall")}
      </button>
    </div>
  )
}
